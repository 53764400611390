import React, { Component } from 'react';
import { Stack, Typography } from '@mui/material';
import Alert from '@mui/material/Alert';
import CreateIcon from '@mui/icons-material/Create';

import FlexFooter from 'components/UI/FlexFooter';
import Modal from 'components/UI/Modal';
import { CreateButton, SaveButton } from './FicheInterventionStyle';
import TableEmailIntervenant from '../TableEmailIntervenant/TableEmailIntervenant';
import CreateCorrective from './CreateFicheIntervention/CreateCorrective';
import CreatePreventive from './CreateFicheIntervention/CreatePreventive';
import Header from 'components/UI/Header/Header';
import Mailbox from 'views/Mail/Mail';

class FicheIntervention extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.fileFicheInterventionIntervenant,
            waitFiles: [],
            fileFicheIntervention: this.props.fileFicheInterventionIntervenant,
            removeFicheIntervention: [],
            sendMailBool: false,
            Attachment: [],
            idAttachment: null,
            objectMail: "Fiche d'Intervention",
            attribut: "Fiche d'Intervention",
            alert: null,
            openCreateCorrective: false,
            openCreatePreventive: false,
            openEmail: false,
            linkFicheIntervention: "",
            hasChanges: false, // New state variable to track changes
        };
    }

    componentDidUpdate(prevProps) {
        if (prevProps.fileFicheInterventionIntervenant !== this.props.fileFicheInterventionIntervenant) {
            this.setState({
                fileFicheIntervention: this.props.fileFicheInterventionIntervenant
            });
        }
    }

    handleClickOpenEmail = () => {
        this.setState({ openEmail: !this.state.openEmail });
    };

    removeFile = (_, file) => {
        let newState = { ...this.state };
        newState.fileFicheIntervention = newState.fileFicheIntervention.filter(item => item !== file);
        newState.removeFicheIntervention = newState.removeFicheIntervention.concat(file);
        newState.hasChanges = true; // Mark changes when files are removed
        this.setState(newState);
    }

    stateModalSendMail = (event, row) => {
        if (row.file) {
            this.setState({
                alert: <Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: '50%' }} severity="warning">Sauvegarder la fiche d'intervention et l'intervention pour pouvoir envoyer le mail !</Alert>
            });
        } else {
            if (row.Name.includes("Fiche maintenance")) {
                this.setState({
                    Attachment: [
                        { file: row.FicheIntervention, name: row.Name, id: row.ID }
                    ],
                    sendMailBool: true,
                    alert: null,
                    objectMail: `Fiche maintenance Mastore - Enseigne: ${this.props.infoIntervention.enseigne} N°${this.props.infoIntervention.numero_magasin} - DI Mastore ${this.props.infoIntervention.id_mastore} - DI Client ${this.props.infoIntervention.id_client} - ${this.props.infoIntervention.ville} [${this.props.infoIntervention.code_postal}] - ${this.props.infoIntervention.specialite}`,
                    attribut: "Fiche Maintenance",
                    hasChanges: true, // Mark changes when preparing to send an email
                });
            } else {
                this.setState({
                    Attachment: [
                        { file: row.FicheIntervention, name: row.Name, id: row.ID }
                    ],
                    sendMailBool: true,
                    alert: null,
                    objectMail: `Demande d'intervention Mastore - Enseigne: ${this.props.infoIntervention.enseigne} N°${this.props.infoIntervention.numero_magasin} - DI Mastore ${this.props.infoIntervention.id_mastore} - DI Client ${this.props.infoIntervention.id_client} - ${this.props.infoIntervention.ville} [${this.props.infoIntervention.code_postal}] - ${this.props.infoIntervention.specialite}`,
                    attribut: "Fiche d'Intervention",
                    link: row.Link,
                    hasChanges: true, // Mark changes when preparing to send an email
                });
            }
        }
    }

    modalClosed = () => {
        this.setState({ sendMailBool: false });
    }

    closeCreateFicheIntervention = () => {
        this.setState({ ...this.state, openCreateCorrective: false, openCreatePreventive: false })
    }

    createFicheIntervention = (response) => {
        this.props.createFicheIntervention(response);
        this.setState({ hasChanges: true }); // Mark changes when new file is added
    }

    handleLinkFicheIntervention = (link) => {
        this.setState({ linkFicheIntervention: link });
    }

    saveFicheInter = () => {
        this.props.saveFicheInter(this.state.fileFicheIntervention, this.state.removeFicheIntervention);
        this.props.modalClosed();
        this.setState({ hasChanges: false }); // Reset changes after saving
    }

    render() {

        return (
            <React.Fragment>
                <Header>Fiche d'intervention</Header>
                {this.state.alert}
                <Stack sx={{ px: 3 }} spacing={1}>
                    <div style={{ display: "flex" }}>
                        {this.props.infoIntervention.type === "corrective" ?
                            <CreateButton variant="outlined" size='small' onClick={() => { this.setState({ openCreateCorrective: true }) }} startIcon={<CreateIcon />}>
                                Corrective
                            </CreateButton> : null
                        }
                        {this.props.infoIntervention.type === "préventive" ?
                            <CreateButton variant="outlined" size='small' onClick={() => { this.setState({ openCreatePreventive: true }) }} startIcon={<CreateIcon />}>
                                Préventive
                            </CreateButton> : null
                        }
                    </div>
                    <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {this.state.fileFicheIntervention.length > 0 ? (
                            <TableEmailIntervenant nameFile="Fiche" data={this.state.fileFicheIntervention} removeFile={this.removeFile} modalSendMail={this.stateModalSendMail} />
                        ) : (
                            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
                                Aucune fiche d'intervention
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <FlexFooter >
                    <SaveButton variant="outlined" size='small' onClick={this.saveFicheInter} disabled={!this.state.hasChanges}>
                        Sauvegarder
                    </SaveButton>
                </FlexFooter>
                {/* MODAL */}
                <Modal open={this.state.sendMailBool} close={this.modalClosed}>
                    <Mailbox
                        objectMail={this.state.objectMail}
                        Attachment={this.state.Attachment}
                        modalClosed={this.modalClosed}
                        destinataire={this.props.actions}
                        mailuser={this.props.pilote_mail}
                        infoIntervention={this.props.infoIntervention} $
                        link={this.state.link}
                        attribut={this.props.infoIntervention.type === "corrective" ? this.state.attribut : "Fiche Maintenance"}
                        sendto="intervenant"
                    />
                </Modal>
                <Modal open={this.state.openCreateCorrective} close={this.closeCreateFicheIntervention} sx={{ width: { md: "80vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
                    <CreateCorrective
                        modalClosed={this.closeCreateFicheIntervention}
                        createFicheInter={this.createFicheIntervention}
                        infoIntervention={this.props.infoIntervention}
                        firstname={this.props.user.firstname}
                        lastname={this.props.user.lastname}
                        telephone={this.props.user.telephone}
                        numberfile={this.props.fileFicheInterventionIntervenant.length}
                        linkFicheIntervention={this.handleLinkFicheIntervention}
                    />
                </Modal >
                <Modal open={this.state.openCreatePreventive} close={this.closeCreateFicheIntervention} sx={{ width: { md: "80vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
                    <CreatePreventive
                        modalClosed={this.closeCreateFicheIntervention}
                        createFicheInter={this.createFicheIntervention}
                        infoIntervention={this.props.infoIntervention}
                        firstname={this.props.user.firstname}
                        lastname={this.props.user.lastname}
                        telephone={this.props.user.telephone}
                        numberfile={this.props.fileFicheInterventionIntervenant.length}
                    />
                </Modal>
            </React.Fragment >
        );
    }
}

export default FicheIntervention;
