import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
// import { useStyles } from './InventaireStyle';
import {
    Button,
    Box,
    Stack,
} from '@mui/material';

import Table from './Table';
import ModalInfosFiles from '../ModalInfosFiles/ModalInfosFiles';
import ModalEditInfosFiles from '../ModalInfosFiles/ModalEditInfosFiles';
import AddIcon from '@mui/icons-material/Add';
import Alert from '@mui/material/Alert';
import Header from 'components/UI/Header/Header';

function Inventaire(props) {
    const { classes } = props;
    const [files, setFiles] = useState(props.filesPlan);
    const [waitFiles, setWaitFiles] = useState([]);
    const [dataFiles, setDataFiles] = useState(props.filesPlan);
    const [openDataFile, setOpenDataFile] = useState(false);
    const [openEditDataFile, setOpenEditDataFile] = useState(false);
    const [editFile, setEditFile] = useState([]);
    const [alert, setAlert] = useState(null);

    const onDrop = (files) => {
        let SizeOk = true;
        files.forEach(file => {
            if (file.size > 10000000) { //10Mo
                SizeOk = false;
            }
        });
        if (SizeOk) {
            let newWaitFiles = waitFiles.concat(files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            setWaitFiles(newWaitFiles);
            setOpenDataFile(true);
            setAlert(null);
        } else {
            setAlert(<Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: 'fit-content' }} severity="warning">Limite de taille de fichier atteinte! Taille maximale 10Mo. Aucun des fichiers n'a été ajouté.</Alert>);
        }
    };

    const addPlan = () => {
        props.addPlan(files);
        props.modalClosed();
    };

    const removeFile = (_, file) => {
        let filteredFiles = files.filter(item => item !== file);
        setFiles(filteredFiles);
        setDataFiles(filteredFiles);
    };

    const saveOrCancel = (att, data) => {
        if (att === "save") {
            setFiles(files.concat(data));
            setWaitFiles([]);
            setDataFiles(dataFiles.concat(data));
            setOpenDataFile(false);
        } else if (att === "cancel") {
            setWaitFiles([]);
            setOpenDataFile(false);
        }
    };

    const editFileHandler = (_, file) => {
        setEditFile(editFile.concat(file));
        setOpenEditDataFile(true);
    };

    const saveOrCancelEditFile = (att, newFile, oldFile) => {
        if (att === "save") {
            let index = files.indexOf(oldFile);
            let newFiles = [...files];
            newFiles[index] = newFile[0];
            setFiles(newFiles);
            setEditFile([]);
            setDataFiles(newFiles);
            setOpenEditDataFile(false);
        } else if (att === "cancel") {
            setEditFile([]);
            setOpenEditDataFile(false);
        }
    };

    return (
        <Box sx={{ width: { md: "70vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
            <Header>Inventaire</Header>
            <Stack sx={{ px: 3, maxHeight: "550px", overflow: 'auto' }} spacing={1}>
                {openDataFile && <ModalInfosFiles openDataFile={openDataFile} dataFiles={waitFiles} saveOrCancel={saveOrCancel} />}
                {openEditDataFile && <ModalEditInfosFiles openDataFile={openEditDataFile} dataFiles={editFile} saveOrCancel={saveOrCancelEditFile} type="Photos" />}
                <Dropzone accept="image/*, application/pdf" onDrop={onDrop}>
                    {({ getRootProps, getInputProps }) => (
                        <section className="container" style={{ paddingLeft: "0px" }}>
                            <div style={{ width: "fit-content" }} {...getRootProps({ className: 'dropzone' })}>
                                <input {...getInputProps()} />
                                <Button className={classes.ButtonAdd} variant="outlined" startIcon={<AddIcon className={classes.IconButton} />}>
                                    Ajouter à l'inventaire
                                </Button>
                            </div>
                        </section>
                    )}
                </Dropzone>
                <div style={{ display: "flex", flexWrap: "wrap" }}>
                    {alert}
                    <Table data={dataFiles} removeFile={removeFile} NumMag={props.data.NumeroMagasin} Enseigne={props.data.Enseigne} editFile={editFileHandler} />
                </div>
            </Stack>
            <Stack sx={{ pb: 3 }} direction="row" justifyContent="center" alignItems="center">
                <div className={classes.DivButton}>
                    <Button className={classes.Button} variant="outlined" onClick={addPlan}>
                        Sauvegarder l'inventaire'
                    </Button>
                </div>
            </Stack>
        </Box>
    );
};

export default (Inventaire);
