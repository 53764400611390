import React from 'react';
import { Stack, Select, InputAdornment, FormControl, Button, Autocomplete, TextField, Grid, Box } from '@mui/material';
import Rating from '@mui/material/Rating';
import InputLabel from '@mui/material/InputLabel';
import { MuiTelInput } from 'mui-tel-input'
import { useSnackbar } from 'notistack';
import axios from 'axios';

import FlexFooter from 'components/UI/FlexFooter';
import Header from 'components/UI/Header/Header';
import Specialite from 'components/UI/Specialite/Specialite';

const MAPBOX_API_KEY = 'pk.eyJ1IjoibWFzdG9yZSIsImEiOiJja2JsenVvYjYxZHR2MnpxdjF3aHNnMjRxIn0.4I7PF5stLtrvXPG7mddHPA';

const ModifyIntervenant = ({ selectedIntervenant, modifyIntervenant, close }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [form, setForm] = React.useState({ ...selectedIntervenant })
    const [adresses, setAdresses] = React.useState([])

    React.useEffect(() => {
        Promise.all([
            getAddress()
        ]).then(([address]) => {
            if (address) {
                setAdresses(address.data.features)
            }
        })
    }, [form.adresse])

    const getAddress = () => {
        axios.defaults.withCredentials = false
        if (form.adresse.length > 5) {
            return axios.get('https://api.mapbox.com/geocoding/v5/mapbox.places/' + form.adresse + '.json?&autocomplete=true&types=district%2Cpostcode%2Clocality%2Cplace%2Cneighborhood%2Caddress%2Cpoi&language=fr&routing=true&limit=10&country=' + form.pays + '&access_token=' + MAPBOX_API_KEY)
        }
    }

    const submitModifyIntervenant = (form) => {
        if (form.specialite === "Sélectionner") {
            enqueueSnackbar('Vous devez sélectionner une spécialité !', {
                variant: 'warning',
            });
        } else if (form.adresse.length <= 0) {
            enqueueSnackbar('Adresse introuvable !', {
                variant: 'warning',
            });
        } else {
            modifyIntervenant(form)
            close()
        }
    }

    const handleAutoCompleteChange = (event, value) => {
        if (value) {
            let codepostale = ""
            let ville = ""
            if (value.context) {
                value.context.map(item => {
                    if (item.id.split('.').shift() === "postcode")
                        codepostale = item.text
                    if (item.id.split('.').shift() === "place")
                        ville = item.text
                })
            }
            setForm({ ...form, "adresse": value.text_fr, "postal": codepostale, "ville": ville, "longitude": value.geometry.coordinates[0].toString(), "latitude": value.geometry.coordinates[1].toString() })
        }
    };

    const handleTextFieldChange = (event, key) => {
        if (key === "prenom" || key === "ville") {
            let text = event.target.value.toLowerCase()
            text = text.charAt(0).toUpperCase() + text.slice(1)
            setForm({ ...form, [key]: text })
        } else if (key === "nom") {
            let text = event.target.value.toUpperCase()
            setForm({ ...form, [key]: text })
        } else if (key === "societe" || key === "adresse_number") {
            let text = event.target.value.toUpperCase()
            setForm({ ...form, [key]: text })
        } else if (key === "specialite" || key === "adresse" || key === "commentaire") {
            setForm({ ...form, [key]: event.target.value })
        } else if (key === "fiabilite") {
            setForm({ ...form, [key]: parseInt(event.target.value) })
        } else if (key === "mail") {
            let mail = form.mail
            mail[0] = event.target.value
            setForm({ ...form, [key]: mail })
        } else if (key === "mail2") {
            let mail = form.mail
            mail[1] = event.target.value
            setForm({ ...form, [key]: mail })
        } else {
            let text = event.target.value.toLowerCase()
            setForm({ ...form, [key]: text })
        }
    };

    const handlePhoneChange = (event, key) => {
        setForm({ ...form, [key]: event })
    };

    const handleChangePays = (event) => {
        setForm({ ...form, pays: event.target.value });
    };

    return (
        <React.Fragment>

            <Header>
                <Stack direction="row" justifyContent="center" alignItems="center" spacing={1}>
                    <Box>{form.societe}</Box>
                    <Rating
                        value={form.fiabilite}
                        onChange={(event) => handleTextFieldChange(event, "fiabilite")}
                    />
                </Stack>
            </Header>
            <Grid container spacing={1} sx={{ px: 2 }}>
                <Grid container item xs={6} spacing={1}>
                    <Grid item xs={12}>
                        <TextField
                            label="Société" variant="outlined"
                            value={form.societe}
                            type="text"
                            placeholder="MASTORE"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "societe")}
                            name="Socitété"
                            InputLabelProps={{ shrink: true }}
                            size="small"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Autocomplete
                            id="Adresse"
                            value={form.adresse}
                            onChange={(event, value) => handleAutoCompleteChange(event, value)}
                            size="small"
                            options={adresses}
                            fullWidth={true}
                            getOptionLabel={(option) => {
                                if (option.place_name_fr)
                                    return (option.place_name_fr)
                                else
                                    return (option)
                            }}
                            disabled={form.pays === "Sélectionner" ? true : false}
                            isOptionEqualToValue={(option, value) => { return true }}
                            style={{ width: "100%", marginRight: "16px" }}
                            renderInput={(params) =>
                                <TextField
                                    {...params}
                                    inputProps={{
                                        ...params.inputProps,
                                        autoComplete: 'new-password',
                                    }}
                                    onChange={(event) => handleTextFieldChange(event, "adresse")}
                                    InputLabelProps={{ shrink: true }}
                                    style={{ background: "white" }}
                                    label="Adresse"
                                    variant="outlined"
                                />
                            }
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Nom" variant="outlined"
                            value={form.nom}
                            type="text"
                            placeholder="NOM"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "nom")}
                            name="Nom"
                            size="small"
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Prénom" variant="outlined"
                            value={form.prenom}
                            type="text"
                            placeholder="Prénom"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "prenom")}
                            name="Prénom"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mail 1" variant="outlined"
                            value={form.mail[0]}
                            type="email"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "mail")}
                            name="Mail"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label="Main d'œuvre" variant="outlined"
                                value={form.mo}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "mo")}
                                name="Main d'œuvre"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                size='small'
                            />
                            <TextField
                                label="Déplacement" variant="outlined"
                                value={form.deplacement}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "deplacement")}
                                name="Deplacement"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                InputLabelProps={{ shrink: true }}
                                size='small'
                            />
                            <TextField
                                label="Forfait" variant="outlined"
                                value={form.forfait}
                                type="number"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "forfait")}
                                name="Forfait"
                                InputProps={{
                                    endAdornment: <InputAdornment position="end">€</InputAdornment>,
                                }}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                </Grid>
                <Grid item container xs={6} spacing={1}>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                            <InputLabel >Pays</InputLabel>
                            <Select
                                native
                                value={form.pays}
                                onChange={handleChangePays}
                                label="Pays"
                                style={{ background: "white" }}
                                size='small'
                            >
                                <option value="Sélectionner">Sélectionner un Pays</option>
                                <option value="be">BELGIQUE</option>
                                <option value="fr">FRANCE</option>
                                <option value="de">ALLEMAGNE</option>
                                <option value="it">ITALIE</option>
                                <option value="lu">LUXEMBOURG</option>
                                <option value="pt">PORTUGAL</option>
                                <option value="es">ESPAGNE</option>
                                <option value="ch">SUISSE</option>
                                <option value="nl">PAYS-BAS</option>
                            </Select>
                        </FormControl>
                    </Grid>
                    <Grid item xs={12}>
                        <Stack direction="row" spacing={1}>
                            <TextField
                                label="N°" variant="outlined"
                                value={form.adresse_number}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "adresse_number")}
                                style={{ width: "20%", background: "white" }}
                                name="Numéro rue"
                                disabled={form.pays === "Sélectionner" ? true : false}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Code Postal" variant="outlined"
                                value={form.postal}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "postal")}
                                style={{ width: "25%", background: "white" }}
                                name="Code Postal"
                                size='small'
                                disabled={form.pays === "Sélectionner" ? true : false}
                                InputLabelProps={{ shrink: true }}
                            />
                            <TextField
                                label="Ville" variant="outlined"
                                value={form.ville}
                                type="text"
                                autoComplete="new-password"
                                onChange={(event) => handleTextFieldChange(event, "ville")}
                                style={{ width: "50%", background: "white" }}
                                name="ville"
                                disabled={form.pays === "Sélectionner" ? true : false}
                                size='small'
                                InputLabelProps={{ shrink: true }}
                            />
                        </Stack>
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTelInput
                            defaultCountry={'FR'}
                            value={form.telephone}
                            onChange={(event) => handlePhoneChange(event, "telephone")}
                            onlyCountries={['FR', 'ES', 'CH', 'BE', 'IT', 'DE', 'PT', 'LU', 'MC', 'NL']}
                            size="small"
                            label="Téléphone"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <MuiTelInput
                            defaultCountry={'FR'}
                            value={form.telephone_fixe}
                            onChange={(event) => handlePhoneChange(event, "telephone_fixe")}
                            onlyCountries={['FR', 'ES', 'CH', 'BE', 'IT', 'DE', 'PT', 'LU', 'MC', 'NL']}
                            size="small"
                            label="Téléphone Fixe"
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <TextField
                            label="Mail 2"
                            variant="outlined"
                            value={form.mail[1]}
                            type="email"
                            autoComplete="new-password"
                            onChange={(event) => handleTextFieldChange(event, "mail2")}
                            name="Mail"
                            size='small'
                            InputLabelProps={{ shrink: true }}
                            fullWidth
                        />
                    </Grid>
                    <Grid item xs={12}>
                        <FormControl variant="outlined" style={{ width: "100%" }}>
                            <InputLabel>Spécialité</InputLabel>
                            <Specialite value={form.specialite} changeSpecialite={(event) => handleTextFieldChange(event, "specialite")} />
                        </FormControl>
                    </Grid>
                </Grid>
                <Grid item xs={12}>
                    <TextField
                        label="Commentaire"
                        multiline
                        minRows={2}
                        maxRows={5}
                        variant="outlined"
                        value={form.commentaire}
                        type="text"
                        autoComplete="new-password"
                        onChange={(event) => handleTextFieldChange(event, "commentaire")}
                        name="commentaire"
                        size='small'
                        InputLabelProps={{ shrink: true }}
                        fullWidth
                    />
                </Grid>
            </Grid>
            <FlexFooter>
                {/* <Button variant="outlined">
                    Archiver l'intervenant
                </Button> */}
                <Button
                    onClick={() => submitModifyIntervenant(form)}
                    variant="outlined"
                >
                    Modifier l'intervenant
                </Button>
            </FlexFooter>
        </React.Fragment >
    );
}

export default ModifyIntervenant;