import React, { useEffect } from 'react';
// mui
import { styled } from '@mui/material/styles';
import KeyboardArrowRightIcon from '@mui/icons-material/KeyboardArrowRight';
import { Menu, MenuItem, Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';
import FormControlLabel from '@mui/material/FormControlLabel';
// services
import {
    GetPilote,
} from "services";
//lodash
import { groupBy } from "lodash"

// ----------------------------------------------------------------------

const MenuItemStyle = styled(MenuItem)(({ theme }) => ({
    paddingLeft: theme.spacing(1.5),
    fontWeight: "bold",
}));

// ----------------------------------------------------------------------

const PiloteFilter = ({ updatePiloteFilter, piloteFilter }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [pilotes, setPilotes] = React.useState([]);

    useEffect(() => {
        GetPilote().then(response => {
            setPilotes(groupBy(response.pilotes, item => item.entreprise))
        })
    }, [])

    const openPilote = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const closePilote = () => {
        setAnchorEl(null);
    };

    const checkPilote = (id) => {
        let index = piloteFilter.map(item => item).indexOf(id);
        let newPiloteFilter = [...piloteFilter];
        if (index === -1)
            newPiloteFilter.push(id)
        else
            newPiloteFilter.splice(index, 1)
        updatePiloteFilter(newPiloteFilter)
    }

    const checkOrUncheckAll = (societe) => {
        let newPiloteFilter = [...piloteFilter];
        let pilotesSociete = pilotes[societe];
        let pilotesSocieteIds = pilotesSociete.map(pilote => pilote.ID);

        if (piloteFilter.some(id => pilotesSocieteIds.includes(id))) {
            newPiloteFilter = piloteFilter.filter(id => !pilotesSocieteIds.includes(id));
        } else {
            newPiloteFilter = [...piloteFilter, ...pilotesSocieteIds];
        }
        updatePiloteFilter(newPiloteFilter);
    }

    return (
        <React.Fragment>
            <Button
                variant="outlined"
                onClick={openPilote}
                endIcon={<KeyboardArrowRightIcon />}
                size="small"
            >
                Pilote(s)
            </Button>
            <Menu
                anchorEl={anchorEl}
                getcontentanchorel={null}
                keepMounted
                anchorOrigin={{ vertical: "center", horizontal: "right" }}
                transformOrigin={{ vertical: "bottom", horizontal: "left" }}
                open={Boolean(anchorEl)}
                onClose={closePilote}
            >
                <MenuItemStyle onClick={() => checkOrUncheckAll("Mastore Ouest")}>MASTORE OUEST</MenuItemStyle>
                {pilotes["Mastore Ouest"]?.map(pilote => (
                    <MenuItem key={pilote.ID}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={piloteFilter.includes(pilote.ID)}
                                    onChange={() => checkPilote(pilote.ID)}
                                    name={pilote.Lastname}
                                    color="primary"
                                    sx={{ pr: "5px" }}
                                />
                            }
                            label={`${pilote.firstname} ${pilote.lastname}`}
                        />
                    </MenuItem>
                ))}
                <MenuItemStyle onClick={() => checkOrUncheckAll("Mastore")}>MASTORE</MenuItemStyle>
                {pilotes["Mastore"]?.map(pilote => (
                    <MenuItem key={pilote.ID}>
                        <FormControlLabel
                            control={
                                <Checkbox
                                    checked={piloteFilter.includes(pilote.ID)}
                                    onChange={() => checkPilote(pilote.ID)}
                                    name={pilote.Lastname}
                                    color="primary"
                                    sx={{ pr: "5px" }}
                                />
                            }
                            label={`${pilote.firstname} ${pilote.lastname}`}
                        />
                    </MenuItem>
                ))}
            </Menu>
        </React.Fragment>
    )
}

export default PiloteFilter;