import React, { useState, useEffect } from "react";
import { Box, TextField, Button, InputLabel, Stack } from "@mui/material";
import { formatISO, parseISO, isValid } from "date-fns";
import SelectStatusFiche from "components/UI/SelectStatusFiche"; // Assurez-vous que le chemin est correct

export default function ModalEditFicheCommande({
  row,
  close,
  handleUpdateFicheCommande,
}) {
  // États locaux pour stocker les valeurs du formulaire
  const [name, setName] = useState(row?.name || "");
  const [status, setStatus] = useState(row?.etat || ""); // Utilisation de 'etat' pour correspondre aux données
  const [dateReception, setDateReception] = useState(
    row?.date_reception ? formatISO(parseISO(row.date_reception)) : ""
  );

  // Fonction pour valider et préparer les données avant l'envoi
  const handleUpdate = () => {
    const parsedDate = parseISO(dateReception);
    if (!isValid(parsedDate)) {
      console.error("Date invalide :", dateReception);
      return;
    }

    const updatedFicheCommande = {
      ...row,
      name,
      etat: status,
      date_reception: formatISO(parsedDate),
    };

    console.log("Données à envoyer :", updatedFicheCommande);

    // Appel de la fonction de mise à jour passée en prop
    handleUpdateFicheCommande(updatedFicheCommande)
      .then(() => {
        console.log("Mise à jour réussie");
      })
      .catch((error) => {
        console.error("Erreur lors de la mise à jour :", error);
      });
  };

  useEffect(() => {
    // Réinitialiser les champs lorsque la row change
    setName(row?.name || "");
    setStatus(row?.etat || ""); // Utilisation de 'etat' pour correspondre aux données
    setDateReception(
      row?.date_reception ? formatISO(parseISO(row.date_reception)) : ""
    );
  }, [row]);

  return (
    <Box
      sx={{
        p: 4,
        backgroundColor: "white",
        borderRadius: 1,
        boxShadow: 3,
        maxWidth: "600px",
        margin: "auto",
        // Ajout de marges pour le positionnement. La modal doit etre au centre
        mt: "20%",
        mb: "10%",
      }}
    >
      <Stack spacing={2}>
        <InputLabel sx={{ ml: 1 }}>Nom</InputLabel>
        <TextField value={name} onChange={(e) => setName(e.target.value)} />

        <InputLabel sx={{ ml: 1 }}>Etat</InputLabel>
        <SelectStatusFiche
          value={status}
          clickHandler={(newStatus) => setStatus(newStatus)}
        />

        <InputLabel sx={{ ml: 1 }}>Date de réception</InputLabel>
        <TextField
          value={dateReception}
          onChange={(e) => setDateReception(e.target.value)}
          type="date"
          InputLabelProps={{ shrink: true }}
        />

        <Stack direction="row" justifyContent="flex-end" spacing={2}>
          <Button variant="contained" color="error" onClick={close}>
            Annuler
          </Button>
          <Button variant="contained" color="primary" onClick={handleUpdate}>
            Modifier
          </Button>
        </Stack>
      </Stack>
    </Box>
  );
}
