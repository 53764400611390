import axios from 'axios';
import React from 'react';
import {
    List,
    ListItemIcon,
    ListItem,
    ListItemText,
    Collapse,
    Stack,
    Box,
} from '@mui/material';

import Header from '../Header/Header';
import FlexFooter from '../FlexFooter';
import Button from '@mui/material/Button';
import Checkbox from '@mui/material/Checkbox';
import ImageIcon from '@mui/icons-material/Image';
import ExpandLess from '@mui/icons-material/ExpandLess';
import ExpandMore from '@mui/icons-material/ExpandMore';
import FormControlLabel from '@mui/material/FormControlLabel';
import { ImagePreview, Photo, CustomIconCheckBox } from './ModalPhotoStyle'

export default function ModalPhoto(props) {
    const [photo, setPhoto] = React.useState([])
    const [selected, setSelected] = React.useState(props.attachment)
    const [openAvant, setOpenAvant] = React.useState(false)
    const [openApres, setOpenApres] = React.useState(false)
    const [openPendant, setOpenPendant] = React.useState(false)

    React.useEffect(() => {
        axios.defaults.withCredentials = true
        axios.get('/legacy/images?IDMastore=' + props.IDMastore)
            .then(response => {
                setPhoto(response.data.photos)
            }).catch(error => {
                setPhoto([])
            });
    }, [])

    const selectPhoto = (filePhoto) => {
        let index = selected.map(item => item).indexOf(filePhoto);
        let select = [...selected]
        if (index === -1) {
            select = select.concat(filePhoto)
        } else if (select.length > 1) {
            select = select.filter(function (item) {
                return item !== filePhoto
            })
        } else {
            select = []
        }
        setSelected(select)
    }

    const selectAll = (event, periode) => {
        let select = [...selected]
        if (event.target.checked === true) {
            photo.forEach((photo) => {
                if (photo.periode === periode && !isSelected(photo)) {
                    select = select.concat(photo)
                }
            })
        } else {
            photo.forEach((photo) => {
                if (photo.periode === periode) {
                    let index = select.map(item => item).indexOf(photo);
                    if (index !== -1) {
                        select.splice(index, 1)
                    }
                }
            })
        }
        setSelected(select)
    }

    const isSelected = (photo) => selected.indexOf(photo) !== -1;

    const isAllSelected = (periode) => {
        return photo.some((photo) => {
            if (photo.periode === periode) {
                if (!isSelected(photo))
                    return true
            }
        })
    }

    const displayPhoto = (periode) => {
        let displayPhoto = photo.map((filePhoto, i) => {
            if (filePhoto.periode === periode) {
                const bool = isSelected(filePhoto)
                return (
                    <ImagePreview key={filePhoto.file_name + i} onClick={() => selectPhoto(filePhoto)} style={bool ? { border: "3px solid #316690" } : null}>
                        {bool ?
                            <CustomIconCheckBox
                                style={{ fontSize: "30px" }}
                            /> : null
                        }
                        <Photo src={"data:image/*;base64, " + filePhoto.file} alt="Preview" />
                    </ImagePreview>
                )
            }
        })
        return displayPhoto
    }

    const addPhotos = () => {
        props.setAttachment(selected)
        props.close()
    }

    return (
        <Box sx={{ width: { lg: "55vw", md: "70vw", sm: "80vw", xs: "90vw" }, maxHeight: "90vh" }}>
            <Header>Sélectionnez les photos</Header>
            <Stack sx={{ px: 3 }} spacing={1}>
                <List
                    component="div"
                    aria-labelledby="nested-list-subheader"
                    style={{ maxHeight: "70vh", overflow: "auto" }}
                >
                    {/* PHOTO  AVANT*/}
                    <ListItem button onClick={() => { setOpenAvant(!openAvant) }} style={{ paddingLeft: "0px" }}>
                        <ListItemIcon>
                            <ImageIcon style={{ color: '#316690', }} />
                        </ListItemIcon>
                        <ListItemText primary="Avant" />
                        {openAvant ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openAvant} timeout="auto" unmountOnExit >
                        <FormControlLabel
                            control={<Checkbox name="avant" style={{ color: "#316690" }} checked={!isAllSelected("avant")} onChange={(event) => selectAll(event, "avant")} />}
                            style={{ padding: "0px 10px" }}
                            label="Tout sélectionner"
                        />
                        <div style={{ padding: "0px 10px", display: "flex", marginBottom: "10px", flexWrap: "wrap" }}>
                            {displayPhoto("avant")}
                        </div>
                    </Collapse>

                    {/* PHOTO  PENDANT*/}
                    <ListItem button onClick={() => { setOpenPendant(!openPendant) }} style={{ paddingLeft: "0px" }}>
                        <ListItemIcon>
                            <ImageIcon style={{ color: '#316690', }} />
                        </ListItemIcon>
                        <ListItemText primary="Pendant" />
                        {openPendant ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openPendant} timeout="auto" unmountOnExit >
                        <FormControlLabel
                            control={<Checkbox checked={!isAllSelected("pendant")} name="pendant" style={{ color: "#316690" }} onChange={(event) => selectAll(event, "pendant")} />}
                            style={{ padding: "0px 10px" }}
                            label="Tout sélectionner"
                        />
                        <div style={{ padding: "0px 10px", display: "flex", marginBottom: "10px", flexWrap: "wrap" }}>
                            {displayPhoto("pendant")}
                        </div>
                    </Collapse>

                    {/* PHOTO  APRES*/}
                    <ListItem button onClick={() => { setOpenApres(!openApres) }} style={{ paddingLeft: "0px" }}>
                        <ListItemIcon>
                            <ImageIcon style={{ color: '#316690', }} />
                        </ListItemIcon>
                        <ListItemText primary="Après" />
                        {openApres ? <ExpandLess /> : <ExpandMore />}
                    </ListItem>
                    <Collapse in={openApres} timeout="auto" unmountOnExit >
                        <FormControlLabel
                            control={<Checkbox checked={!isAllSelected("après")} name="après" style={{ color: "#316690" }} onChange={(event) => selectAll(event, "après")} />}
                            style={{ padding: "0px 10px" }}
                            label="Tout sélectionner"
                        />
                        <div style={{ padding: "0px 10px", display: "flex", marginBottom: "10px", flexWrap: "wrap" }}>
                            {displayPhoto("après")}
                        </div>
                    </Collapse>
                </List>
                <FlexFooter>
                    <Button
                        onClick={() => addPhotos()}
                        variant="outlined"
                        sx={{ width: '30%' }}
                    >
                        Valider
                    </Button>
                </FlexFooter>
            </Stack>
        </Box>
    )
}
