
export default function Tab() {
    return {
        MuiTabs: {
            styleOverrides: {
                root: {
                    minHeight: 40
                }
            }
        },
        MuiTab: {
            styleOverrides: {
                root: {
                    minHeight: 40
                }
            }
        },
        MuiTabPanel: {
            styleOverrides: {
                root: {
                    padding: 0,
                },
            },
        },
    };
}
