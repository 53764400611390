import React from 'react';
import axios from 'axios';
import {
    Select,
    MenuItem
} from '@mui/material';

const Specialite = ({ disabled, value, name, changeSpecialite }) => {
    const [specialites, setSpecialite] = React.useState([])

    React.useEffect(() => {
        axios.defaults.withCredentials = true
        axios.get('/legacy/parametre?attribut=Spécialité').then(response => {
            setSpecialite(response.data.parametres)
        })
    }, [])

    return (
        <Select
            disabled={disabled}
            value={value === "" ? "Sélectionner" : value}
            onChange={changeSpecialite}
            variant="outlined"
            label="Spécialité"
            size="small"
            margin="dense"
            name={name}
            fullWidth
        >
            <MenuItem value="Sélectionner">Sélectionnez une spécialité</MenuItem>
            {specialites.map((specialite, index) => (
                <MenuItem key={index} value={specialite.parametre1}>{specialite.parametre1}</MenuItem>
            ))}
        </Select>
    )
}

export default Specialite