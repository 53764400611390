import React, { useRef, useState, useEffect, useContext } from 'react';
import axios from 'axios';
import { connect } from 'react-redux';

// Material
import FitScreenIcon from '@mui/icons-material/FitScreen';
import IconButton from '@mui/material/IconButton';
import { styled } from '@mui/material/styles';
import CloseIcon from '@mui/icons-material/Close';
import CheckIcon from '@mui/icons-material/Check';
// Moment.js
import moment from 'moment';
import 'moment/locale/fr';
// Components
import { isToday } from 'utils/date'
import { MentionsInput, Mention } from 'react-mentions'
import { GetAllUsers } from 'services';
import { Stack } from '@mui/material';
import RelanceContext from 'contexts/RelanceContext';

const defaultStyle = {
    control: {
        backgroundColor: "#fff",
        fontSize: 12,
        fontWeight: "normal",
        padding: 4,
    },
    highlighter: {
        overflow: "hidden"
    },
    input: {
        margin: 0,
        padding: 10,
        overflow: "auto",
        height: 25,
        border: '1px solid rgb(196 196 196)',
        borderRadius: "0px",
        borderLeft: "0px solid rgb(196 196 196)",
        borderRight: "0px solid rgb(196 196 196)",
    },
    "&multiLine": {
        control: {
            fontFamily: "monospace",
            border: "1px solid silver"
        },
        highlighter: {
            padding: 9
        },
        input: {
            padding: 9,
            minHeight: 3,
            outline: 0,
            border: 0
        }
    },
    suggestions: {
        zIndex: 2,
        bottom: "0",
        top: "unset",
        list: {
            backgroundColor: "white",
            border: "1px solid rgba(0,0,0,0.15)",
            fontSize: 14
        },
        item: {
            padding: "5px 15px",
            borderBottom: "1px solid rgba(0,0,0,0.15)",
            "&focused": {
                backgroundColor: "#cee4e5"
            }
        }
    }
};

const defaultMentionStyle = {
    backgroundColor: "#cee4e5",
    textAlign: "center",
    padding: "1px",
};

moment.updateLocale('fr', {
    longDateFormat: {
        LT: "H[h]mm",
    },
    calendar: {
        lastDay: '[Hier à] LT',
        sameDay: '[Aujourd\'hui à] LT',
        lastWeek: 'dddd [dernier à] LT',
        sameElse: 'L [à] LT'
    }
});

// ----------------------------------------------------------------------

const BoxCommentaire = styled("div", { shouldForwardProp: (prop) => prop !== 'expand' })(
    ({ expand }) => ({
        borderRadius: "5px",
        position: 'relative',
        border: '1px solid rgb(196 196 196)',
        background: "white",
        height: '140px',
        width: "100%",
        zIndex: "2",
        ...(expand && {
            height: '100%',
            position: 'absolute',
            bottom: 0,
        }),
    })
);

const CommentsWindow = styled("div")(
    () => ({
        padding: '5px',
        overflow: "auto",
        height: "calc(100% - 25px)",
    })
);

const Title = styled("div")(
    () => ({
        fontWeight: "bold",
    })
);

const Bubble = styled("div", { shouldForwardProp: (prop) => prop !== 'color' })(
    ({ theme, color }) => ({
        padding: "5px 25px 5px 10px",
        borderRadius: "5px",
        position: "relative",
        width: "fit-content",
        margin: "5px 0px",
        background: "rgb(218 216 216 / 60%)",
        ...(color === '1' && {
            background: "none",
            margin: "auto",
            textAlign: "center",
            color: "#009d09 !important",
        }),
        ...(color === '2' && {
            background: "none",
            margin: "auto",
            textAlign: "center",
            color: '#b40000',
        }),
        ...(color === '3' && {
            padding: "5px 25px 5px 10px",
            borderRadius: "5px",
            color: 'white',
            position: "relative",
            width: "fit-content",
            margin: "5px 0px",
            background: theme.palette.error.light,
        }), // 4 allready use for default
        ...(color === '5' && {
            padding: "5px 25px 5px 10px",
            borderRadius: "5px",
            color: 'black',
            position: "relative",
            width: "fit-content",
            margin: "5px 0px",
            background: theme.palette.info.light,
        }),
    })
);
// ----------------------------------------------------------------------

const Commentaire = (props) => {
    const { IDMastore, enqueueSnackbar } = props
    const [commentaire, setCommentaire] = useState([]);
    const [inputCommentaire, setInputCommentaire] = useState("");
    const [expand, setExpand] = useState(false)
    const [users, setUsers] = useState([]);
    const [destinataire, setDestinataire] = useState(-1);
    const [beRelanced, setBeRelanced] = useState(false)
    const [colorCommentaire, setColorCommentaire] = useState("4")
    let messagesEndRef = useRef(null)
    const ctx = useContext(RelanceContext)

    useEffect(() => {
        Promise.all([
            axios.get('/legacy/getComments?id_mastore=' + IDMastore),
            GetAllUsers(),
        ]).then(([
            responseCommentaires,
            responseGetUsers,
        ]) => {
            setCommentaire(responseCommentaires.data.comments)
            setUsers(responseGetUsers.users)
        }).catch(err => {
            enqueueSnackbar("Erreur lors de la récupération des commentaires", { variant: 'error' });
            console.log(err);
        });
    }, [])


    useEffect(() => {
        scrollToBottom()
    }, [commentaire])

    const scrollToBottom = () => {
        messagesEndRef.scrollIntoView({ behavior: "instant" });
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (inputCommentaire.trim() !== "") {
                saveComments()
                setInputCommentaire("")
            }
        }
    }

    const handleClick = () => {
        setExpand(!expand)
    }

    const saveComments = () => {
        axios.defaults.withCredentials = true
        axios.post('/legacy/postComment', { IDMastore: IDMastore, commentaire: inputCommentaire, color: colorCommentaire, time: new Date(), recipient_id: destinataire, relance: beRelanced })
            .then(response => {
                let newCommentaire = [...commentaire]
                newCommentaire.push(response.data.comment)
                setCommentaire(newCommentaire)
                ctx.onAddRelance()
                setColorCommentaire("4")
                setBeRelanced(false)
                setDestinataire(-1)
            }).catch(error => {
                enqueueSnackbar("Erreur lors de l'envoi du commentaire", { variant: 'error' });
            });
    }

    const deleteComment = (id) => {
        axios.defaults.withCredentials = true
        axios.delete(`/legacy/deleteComment?id=${id}`)
            .then(response => {
                let newComments = [...commentaire]
                let index = commentaire.map(item => item.ID).indexOf(id);
                newComments.splice(index, 1)
                setCommentaire(newComments)
                ctx.onTreatRelance()
            }).catch(error => {
                enqueueSnackbar('suppression du commentaire impossible', { variant: 'error' });
            });
    }

    const TreatRelance = (rowId) => {
        axios.defaults.withCredentials = true
        axios.put('/legacy/putMention', { ID: rowId, relance_treat: true })
            .then(response => {
                axios.get('/legacy/getComments?id_mastore=' + IDMastore).then(response => {
                    setCommentaire(response.data.comments)
                    ctx.onTreatRelance()
                }).catch(error => {
                    enqueueSnackbar('Erreur lors de la récupération des commentaires', { variant: 'error' });
                });
            }).catch(error => {
                enqueueSnackbar("Erreur lors de la modification du commentaire", { variant: 'error' });
            });
    }

    function DisplayComments(comments) {
        return (
            <React.Fragment>
                {comments.map((item) => {
                    if (item.relance && item.relance_treat) {
                        return (
                            <Bubble key={item.ID} color={"5"} >
                                {
                                    item.user_id === props.id && isToday(item.time) ?
                                        <IconButton
                                            style={{ position: 'absolute', right: '0', top: '0', zIndex: '4' }}
                                            onClick={() => deleteComment(item.ID)}
                                            size="small">
                                            <CloseIcon />
                                        </IconButton> :
                                        null
                                }
                                <Title >{`${item.author} - ${moment(item.time).calendar().toLocaleLowerCase()}`}  </Title>
                                <div>{item.commentaire}</div>
                            </Bubble>
                        )
                    }
                    if (item.relance && !item.relance_treat) {
                        return (
                            <Bubble key={item.ID} color={item.color} >
                                {
                                    item.user_id === props.id && isToday(item.time) ?
                                        <Stack>
                                            <IconButton
                                                style={{ position: 'absolute', right: '0', top: '0', zIndex: '4' }}
                                                onClick={() => deleteComment(item.ID)}
                                                size="small">
                                                <CloseIcon />
                                            </IconButton>
                                        </Stack>
                                        :
                                        null
                                }
                                <IconButton
                                    style={{ position: 'absolute', right: '0', bottom: '0', zIndex: '4' }}
                                    onClick={() => TreatRelance(item.ID, item.relance_treat)}
                                    size="small">
                                    <CheckIcon sx={{ color: "white" }} />
                                </IconButton>
                                <Title >{`${item.author} - ${moment(item.time).calendar().toLocaleLowerCase()}`}  </Title>
                                <div>{item.commentaire}</div>
                            </Bubble>
                        )
                    } else {
                        return (
                            <Bubble key={item.ID} color={item.color} >
                                {
                                    item.user_id === props.id && isToday(item.time) ?
                                        <IconButton
                                            style={{ position: 'absolute', right: '0', top: '0', zIndex: '4' }}
                                            onClick={() => deleteComment(item.ID)}
                                            size="small">
                                            <CloseIcon />
                                        </IconButton> :
                                        null
                                }
                                <Title >{`${item.author} - ${moment(item.time).calendar().toLocaleLowerCase()}`}  </Title>
                                <div>{item.commentaire}</div>
                            </Bubble>
                        )
                    }
                })}
            </React.Fragment>
        );
    }

    const newData = users.map(user => {
        return { id: user.ID, display: user.firstname + user.lastname, }
    })

    return (
        <React.Fragment>
            <BoxCommentaire expand={expand}>
                <IconButton color={expand ? "primary" : "secondary"} component="span" style={{ position: 'absolute', top: 0, right: 0 }} onClick={handleClick}>
                    <FitScreenIcon />
                </IconButton>
                <CommentsWindow>
                    {DisplayComments(commentaire, props.id)}
                    <div style={{ float: "left", clear: "both" }}
                        ref={(el) => { messagesEndRef = el; }}>
                    </div>
                </CommentsWindow>
                <MentionsInput
                    placeholder="Ajouter un commentaire"
                    value={inputCommentaire}
                    style={defaultStyle}
                    onKeyDown={keyPress}
                    singleLine
                    onChange={(event, newValue, newPlainTextValue, mentions) => {
                        if (mentions.length === 0 && destinataire !== "") {
                            setDestinataire(-1);
                        }
                        setInputCommentaire(event.target.value)
                    }}
                >
                    <Mention
                        trigger={destinataire === -1 ? "@" : "ª&é32"}
                        appendSpaceOnAdd
                        markup={"@[__display__]"}
                        data={newData}
                        style={defaultMentionStyle}
                        onAdd={(id) => { setBeRelanced(true); setDestinataire(id); setColorCommentaire("3") }}
                    />
                </MentionsInput>
            </BoxCommentaire>
        </React.Fragment>
    );
}

const mapStateToProps = state => {
    return {
        id: state.id
    };
};

export default connect(mapStateToProps, null)(Commentaire)