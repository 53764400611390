// material
import { styled } from '@mui/material/styles';
import {
    Grid,
    Typography,
    Stack,
} from '@mui/material';
import PhoneIcon from '@mui/icons-material/Phone';
import PersonIcon from '@mui/icons-material/Person';
import MailIcon from '@mui/icons-material/Mail';
// components
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";


// ----------------------------------------------------------------------

const MailDiv = styled('a')(({ theme }) => ({
    textDecoration: "none",
    color: theme.palette.primary.main,
    whiteSpace: "nowrap",
}));

// ----------------------------------------------------------------------

export default function Contact({ contact, infoIntervention }) {
    const indexContact = contact.map(item => `${item.prenom} ${item.nom}`).indexOf(infoIntervention.contact_client)
    var settings = {
        dots: true,
        infinite: true,
        speed: 500,
        draggable: false,
        initialSlide: indexContact !== -1 ? indexContact : 0,
        swipe: false,
        slidesToShow: 1,
        slidesToScroll: 1,
        appendDots: dots => (
            <div
                style={{
                    position: "initial",
                    bottom: "0px",
                }}
            >
                <ul style={{ margin: "0px" }}> {dots} </ul>
            </div>
        ),
    };

    return (
        <Grid item xs={7}>
            <Slider {...settings} arrows={false} style={{ userSelect: "text" }}>
                {contact.map((item, index) => (
                    <div key={index}>
                        <Stack spacing={1} direction="row" alignItems="center">
                            <PersonIcon color="primary" />
                            <Typography variant="subtitle1"> {item.prenom} {item.nom}</Typography>
                        </Stack>
                        <Grid container spacing={"auto"}>
                            <Grid item sm={12} >
                                <span>{item.fonction}</span>
                            </Grid>
                            <Grid item sm={12} >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    < MailIcon color="primary" />
                                    <MailDiv href={`mailto:${item.mails}`}>{item.mails}</MailDiv>
                                </Stack>
                            </Grid>
                            <Grid item sm={6} >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <PhoneIcon color="primary" />
                                    <span>{item.telephone_portable}</span>
                                </Stack>
                            </Grid>
                            <Grid item sm={6} >
                                <Stack spacing={1} direction="row" alignItems="center">
                                    <PhoneIcon color="primary" />
                                    <span>{item.telephone_fixe}</span>
                                </Stack>
                            </Grid>
                        </Grid>
                    </div>
                ))}
            </Slider>
        </Grid >
    )
}