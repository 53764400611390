import React from 'react'
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const AstreinteButton = ({ infoIntervention, updateInfoIntervention }) => {
    return (
        <Button
            onClick={() => updateInfoIntervention(!infoIntervention.astreinte, 'astreinte')}
            startIcon={<Checkbox checked={infoIntervention.astreinte} />}
            color={'primary'}
            variant="outlined"
            size='small'
        >
            Astreinte
        </Button>
    )
}

export default AstreinteButton