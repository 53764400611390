import axios from 'axios'
import {
    GET_SPECIALITE,
    SAVE_PARAMETRES,
    DELETE_PARAMETRES,
    GET_ZONE,
    GET_ALL_ADRESSE_FACTURATION,
    GET_ADRESSE_FACTURATION_BY_LOCATION
} from "./CONSTANTS";

export const GetSpecialite = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_SPECIALITE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetSpecialite > axios err=", err);
                    reject("Error in GetSpecialite axios!");
                });
        } catch (error) {
            console.error("in parameteServices > GetSpecialite, Err===", error);
        }
    });
};

export const SaveParametres = (parametres) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(SAVE_PARAMETRES(), parametres)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("SaveParametres > axios err=", err);
                    reject("Error in SaveParametres axios!");
                });
        } catch (error) {
            console.error("in parameteServices > SaveParametres, Err===", error);
        }
    });
};

export const DeleteParametres = (parametres) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.delete(DELETE_PARAMETRES(parametres))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("DeleteParametres > axios err=", err);
                    reject("Error in DeleteParametres axios!");
                });
        } catch (error) {
            console.error("in parameteServices > DeleteParametres, Err===", error);
        }
    });
};

export const GetZoneName = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ZONE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetZoneName > axios err=", err);
                    reject("Error in GetZoneName axios!");
                });
        } catch (error) {
            console.error("in parameteServices > GetZoneName, Err===", error);
        }
    });
}

export const GetAllAdresseFacturation = () => {
    return new Promise((resolve, reject) => {
        axios.defaults.withCredentials = true;
        axios.get(GET_ALL_ADRESSE_FACTURATION())
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                console.error("Error in GetAllAdresseFacturation:", err);
                reject(err.response ? err.response.data : "Error in GetAllAdresseFacturation axios request!");
            });
    });
};

export const GetAdresseFacturationByLocation = (location) => {
    return new Promise((resolve, reject) => {
        axios.defaults.withCredentials = true;
        axios.get(GET_ADRESSE_FACTURATION_BY_LOCATION(location))
            .then((res) => {
                resolve(res.data);
            })
            .catch((err) => {
                console.error("Error in GetAllAdresseFacturation:", err);
                reject(err.response ? err.response.data : "Error in GetAllAdresseFacturation axios request!");
            });
    });
};