import React from 'react'
import { Button } from '@mui/material';
import Checkbox from '@mui/material/Checkbox';

const SAVButton = ({ infoIntervention, updateInfoIntervention }) => {
    return (
        <Button
            onClick={() => updateInfoIntervention(!infoIntervention.sav, 'sav')}
            startIcon={<Checkbox checked={infoIntervention.sav} />}
            color={'primary'}
            variant="outlined"
            size='small'
        >
            SAV MO
        </Button>
    )
}

export default SAVButton