import imageCompression from 'browser-image-compression'

export async function FileToB64(file) {
    const base64 = await imageCompression.getDataUrlFromFile(file)
    return base64.replace('data:', '').replace(/^.+,/, '')
}

export async function ResizeImage(image) {

    const imageFile = image;

    const options = {
        maxSizeMB: 0.5,
        // onProgress: test,
        maxWidthOrHeight: 1920,
        useWebWorker: true

    }
    try {
        const compressedFile = await imageCompression(imageFile, options);
        const base64 = await FileToB64(compressedFile)
        return (base64)
    } catch (error) {
        console.log(error);
    }

}