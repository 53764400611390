import React from 'react';
import { createRoot } from 'react-dom/client';
import { Provider } from 'react-redux';
import { createStore, applyMiddleware, compose } from 'redux';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import thunk from 'redux-thunk';
import axios from 'axios'
import { CollapseDrawerProvider } from './contexts/CollapseDrawerContext';
import { RelanceContextProvider } from 'contexts/RelanceContext';
import { ChatContextProvider } from 'contexts/ChatContext';
import { HelmetProvider } from 'react-helmet-async';
import App from './App';
import authReducer from './store/reducers/auth'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query';

// scroll bar
import 'simplebar/dist/simplebar.min.css';

axios.defaults.baseURL = process.env.REACT_APP_API_BASE_URL;

const store = createStore(authReducer, compose(applyMiddleware(thunk)));

const queryClient = new QueryClient();

const app = (
    <QueryClientProvider client={queryClient}>
        <HelmetProvider>
            <Provider store={store}>
                <RelanceContextProvider>
                    <ChatContextProvider>
                        <CollapseDrawerProvider>
                            <BrowserRouter>
                                <App />
                            </BrowserRouter>
                        </CollapseDrawerProvider>
                    </ChatContextProvider>
                </RelanceContextProvider>
            </Provider>
        </HelmetProvider>
    </QueryClientProvider>
);

const container = document.getElementById('root');
const root = createRoot(container);
root.render(app);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
