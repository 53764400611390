import PropTypes from 'prop-types';
// material
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import { styled } from '@mui/material/styles';
import { OutlinedInput } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Box, TableRow, TableCell, TableHead, TableSortLabel } from '@mui/material';
import React from 'react';
// ----------------------------------------------------------------------

const SearchFilter = styled(OutlinedInput)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

const SelectFilter = styled(Select)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    '& fieldset': {
        borderWidth: `1px !important`,
        borderColor: `${theme.palette.grey[500_32]} !important`
    }
}));

const TableCellSearch = styled(TableCell)(() => ({
    background: "white",
    padding: "5px",
}));

const StyledTableCell = styled(TableCell)(() => ({
    height: "40px",
    fontWeight: "bold",
    padding: "5px 10px",
}));

// ----------------------------------------------------------------------

DisplayHeader.propTypes = {
    order: PropTypes.oneOf(['asc', 'desc']),
    orderBy: PropTypes.string,
    headLabel: PropTypes.array,
    tableFilter: PropTypes.object,
    onRequestSort: PropTypes.func,
    handleChangeTableFilter: PropTypes.func,
};

export default function DisplayHeader({
    order,
    orderBy,
    headLabel,
    tableFilter,
    onRequestSort,
    handleChangeTableFilter,
}) {
    const createSortHandler = (property) => {
        onRequestSort(property);
    };

    return (
        <TableHead sx={{ whiteSpace: "nowrap" }}>
            <TableRow>
                {headLabel.map((headCell) => (
                    <StyledTableCell
                        key={headCell.id}
                        sx={{
                            ...(headCell.id === "détails") && {
                                width: "80px",
                            },
                            ...(headCell.id === "status") && {
                                width: "120px",
                            },
                        }}
                        align={"center"}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            hideSortIcon
                            active={orderBy === headCell.id}
                            direction={orderBy === headCell.id ? order : 'asc'}
                            onClick={() => createSortHandler(headCell.id)}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box sx={{ ...visuallyHidden }}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </StyledTableCell>
                ))}
            </TableRow>
            <TableRow>
                {headLabel.map((headCell) => {
                    if (headCell.id === "action" || headCell.id === "corbeille") {
                        return (
                            null
                        )
                    }
                    if (headCell.id === "status") {
                        return (
                            <TableCellSearch key={headCell.id}>
                                <SelectFilter
                                    size="small"
                                    value={tableFilter[headCell.id]}
                                    name={headCell.id}
                                    onChange={handleChangeTableFilter}
                                >
                                    <MenuItem value={"Tout"}>Tout</MenuItem>
                                    <MenuItem value={"Demande de devis"}>Demande de devis</MenuItem>
                                    <MenuItem value={"Demande intervention"}>Demande intervention</MenuItem>
                                    <MenuItem value={"Attente de validation"}>Attente de validation</MenuItem>
                                </SelectFilter>
                            </TableCellSearch>
                        )
                    } else if (headCell.id === "type") {
                        return (
                            <TableCellSearch key={headCell.id}>
                                <SelectFilter
                                    size="small"
                                    value={tableFilter[headCell.id]}
                                    name={headCell.id}
                                    onChange={handleChangeTableFilter}
                                >
                                    <MenuItem value={"Tout"}>Tout</MenuItem>
                                    <MenuItem value={"Corrective"}>Corrective</MenuItem>
                                    <MenuItem value={"Préventive"}>Préventive</MenuItem>
                                </SelectFilter>
                            </TableCellSearch>
                        )

                    } else {
                        return (
                            <TableCellSearch key={headCell.id}>
                                <SearchFilter
                                    size="small"
                                    value={tableFilter[headCell.id]}
                                    name={headCell.id}
                                    onChange={handleChangeTableFilter}
                                    placeholder={headCell.label}
                                    autoComplete="new-password"
                                />
                            </TableCellSearch>
                        )
                    }
                })}
            </TableRow>
        </TableHead >
    );
}
