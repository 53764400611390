import PropTypes from 'prop-types';
import { m, AnimatePresence } from 'framer-motion';
// @mui
import { alpha } from '@mui/material/styles';
import { List, IconButton, ListItemText, ListItem } from '@mui/material';
import { b64toBlob } from 'utils/handleFiles';


// utils
import { fData } from '../../../utils/formatNumber';
import getFileData from '../../../utils/getFileData';
//
import Image from './Image';
import Iconify from '../Iconify';

// ----------------------------------------------------------------------

MultiFilePreview.propTypes = {
    files: PropTypes.array.isRequired,
    onRemove: PropTypes.func,
    showPreview: PropTypes.array,
};

export default function MultiFilePreview({ showPreview, files, onRemove }) {
    const hasFile = files.length > 0;
    return (
        <List disablePadding sx={{ ...(hasFile && { my: 0, p: 1 }) }}>
            <AnimatePresence>
                {files.map((file, index) => {
                    const { key, name, size } = getFileData(file, index);
                    let blob = b64toBlob(file.file, "image/*")
                    if (showPreview) {
                        return (
                            <ListItem
                                key={key}
                                component={m.div}
                                sx={{
                                    p: 0,
                                    m: 0,
                                    width: 80,
                                    height: 80,
                                    borderRadius: 1.25,
                                    overflow: 'hidden',
                                    position: 'relative',
                                    display: 'inline-flex',
                                    border: (theme) => `solid 1px ${theme.palette.divider}`,
                                }}
                            >
                                <Image alt="preview" src={URL.createObjectURL(blob)} ratio="1/1" />

                                {onRemove && (
                                    <IconButton
                                        size="small"
                                        onClick={() => onRemove(file)}
                                        sx={{
                                            top: 6,
                                            p: '2px',
                                            right: 6,
                                            position: 'absolute',
                                            color: 'common.white',
                                            bgcolor: (theme) => alpha(theme.palette.grey[900], 0.72),
                                            '&:hover': {
                                                bgcolor: (theme) => alpha(theme.palette.grey[900], 0.48),
                                            },
                                        }}
                                    >
                                        <Iconify icon={'eva:close-fill'} />
                                    </IconButton>
                                )}
                            </ListItem>
                        );
                    }

                    return (
                        <ListItem
                            key={key}
                            component={m.div}
                            sx={{
                                my: 1,
                                px: 2,
                                py: 0.75,
                                borderRadius: 0.75,
                                border: (theme) => `solid 1px ${theme.palette.divider}`,
                            }}
                        >
                            <Iconify icon={'eva:file-fill'} sx={{ width: 28, height: 28, color: 'text.secondary', mr: 2 }} />

                            <ListItemText
                                primary={typeof file === 'string' ? file : name}
                                secondary={typeof file === 'string' ? '' : fData(size || 0)}
                                primaryTypographyProps={{ variant: 'subtitle2' }}
                                secondaryTypographyProps={{ variant: 'caption' }}
                            />

                            {onRemove && (
                                <IconButton edge="end" size="small" onClick={() => onRemove(file)}>
                                    <Iconify icon={'eva:close-fill'} />
                                </IconButton>
                            )}
                        </ListItem>
                    );
                })}
            </AnimatePresence>
        </List>
    );
}
