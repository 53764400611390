import HttpService from './HTTPService';
import { FileDevis } from '../views/Intervention/Client/modal/DevisClient/types';

interface CUDDevis {
    toDeleteDevis: FileDevis[]
    toCreateDevis: FileDevis[]
    toUpdateDevis: FileDevis[]
}

interface CUDDevisResponse {
    Devis: FileDevis[]
}

interface getDevisResponse {
    Devis: FileDevis[]
}

interface getAllDevisResponse {
    devis: FileDevis[];
}
interface getAllDevisParams {
    pilote?: string[];
    status?: string[];
    year?: number;
}

export default class DocumentService {
    private httpService: HttpService;
    private readonly PATH: string = "/documentsService";

    constructor() {
        this.httpService = new HttpService();
    }

    async cudDevis(Devis: CUDDevis): Promise<CUDDevisResponse> {
        try {
            const response = await this.httpService.post<CUDDevisResponse>(`${this.PATH}/cudDevis`, Devis);
            return response;
        } catch (error) {
            return { Devis: [] };
        }
    }

    async getDevis(idmastore: string): Promise<any> {
        const response = await this.httpService.get(`${this.PATH}/getDevis`, { params: { idmastore } });
        return response as getDevisResponse
    }

    async getAllDevis(params: getAllDevisParams = {}): Promise<getAllDevisResponse> {
        const queryParams: any = {};

        if (params.pilote && params.pilote.length > 0) {
            queryParams.pilote = params.pilote;
        }

        if (params.status && params.status.length > 0) {
            queryParams.status = params.status.join(',');
        }

        if (params.year === 0) {
            queryParams.year = params.year;
        }

        const queryString = new URLSearchParams();
        Object.keys(queryParams).forEach(key => {
            if (Array.isArray(queryParams[key])) {
                queryParams[key].forEach((value: string) => queryString.append(key, value));
            } else {
                queryString.append(key, queryParams[key]);
            }
        });

        const response = await this.httpService.get(`${this.PATH}/allDevis?${queryString.toString()}`);
        return response as getAllDevisResponse;
    }
}
