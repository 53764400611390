import axios from 'axios'
import {
    GET_INTERVENANT,
} from "./CONSTANTS";

export const GetIntervenants = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_INTERVENANT())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("Get intervenant > axios err=", err);
                    reject("Error in Get intervenant axios!");
                });
        } catch (error) {
            console.error("in intervenant > Get intervenant, Err===", error);
        }
    });
};
