import axios from 'axios'
import {
    DELETE_TASK,
    GET_TASK_BY_USER,
    POST_TASK
} from "./CONSTANTS";

export const GetTaskByUser = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_TASK_BY_USER())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("Get Task > axios err=", err);
                    reject("Error in Get task axios!");
                });
        } catch (error) {
            console.error("in taskService > Get task, Err===", error);
        }
    });
};

export const PostTask = (task) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(POST_TASK(), task)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("task > axios err=", err);
                    reject("Error in task axios!");
                });
        } catch (error) {
            console.error("in taskService > task, Err===", error);
        }
    });
}

export const DeleteTask = (id) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.delete(DELETE_TASK(id))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("task > axios err=", err);
                    reject("Error in task axios!");
                });
        } catch (error) {
            console.error("in taskService > task, Err===", error);
        }
    });
}