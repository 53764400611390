import React, { useEffect } from 'react';
import { Box, Grid } from '@mui/material';
import { useSnackbar } from 'notistack';
import axios from 'axios';
//components
import MapBox from 'components/UI/MapBox/Mapbox'
import Modal from "components/UI/Modal"
import NavBarTop from './components/NavBarTop';
import DisplayIntervenant from './components/Displayintervenant';
import CreateIntervenant from './components/CreateIntervenant/CreateIntervenant'
import ModifyIntervenant from './components/ModifyIntervenant/modifyIntervenant'

var turf = require('@turf/turf');

const BddIntervenant = () => {
    const { enqueueSnackbar } = useSnackbar();
    const [intervenants, setIntervenants] = React.useState([])
    const [selectedIntervenant, setSelectedIntervenant] = React.useState({})
    const [intervenantLocation, setIntervenantLocation] = React.useState([])
    const [filteredIntervenant, setFilteredIntervenant] = React.useState([])
    const [modalModifyIntervenant, setModalModifyIntervenant] = React.useState(false)
    const [modalCreateIntervenant, setModalCreateIntervenant] = React.useState(false)
    const [filter, setFilter] = React.useState({ societe: null, specialite: null, ville: null, distance: 50 })

    const openModalModifyIntervenant = (intervenant) => {
        setModalModifyIntervenant(true)
        setSelectedIntervenant(intervenant)
    }

    useEffect(() => {
        axios.defaults.withCredentials = true
        axios.get('/legacy/GetIntervenants').then(response => {
            setIntervenants(response.data.artisan)
            setFilteredIntervenant(response.data.artisan)
        })
    }, [])

    useEffect(() => {
        let newfilteredIntervenant = intervenants.filter(i => {
            if (filter.societe && filter.specialite) {
                return i.societe === filter.societe && i.specialite === filter.specialite;
            } else if (filter.societe) {
                return i.societe === filter.societe;
            } else if (filter.specialite) {
                return i.specialite === filter.specialite;
            } else {
                return i;
            }
        });
        if (filter.ville) {
            let newIntervenantLocation = []
            newfilteredIntervenant = newfilteredIntervenant.filter(intervenant => {
                let point = turf.distance(filter.ville.geometry.coordinates, [parseFloat(intervenant.longitude), parseFloat(intervenant.latitude)])
                if (Math.round(point) <= filter.distance) {
                    newIntervenantLocation.push(
                        {
                            type: 'Feature',
                            properties: {
                                description: `<div><strong style="font-size: 15px; margin-right: 10px">${intervenant.societe}</strong><p style="font-size: 15px; margin-right: 10px; margin-top: 0; margin-bottom: 0">${intervenant.ville}</p></div>`,
                            },
                            geometry: {
                                type: 'Point',
                                coordinates: [intervenant.longitude, intervenant.latitude]
                            },
                        }
                    )
                    return intervenant
                }
            })
            setIntervenantLocation(newIntervenantLocation)
        } else {
            setIntervenantLocation([])
        }

        setFilteredIntervenant(newfilteredIntervenant)
    }, [filter, intervenants])

    const onChangeFilter = (value, key) => {
        setFilter({ ...filter, [key]: value })
    };

    const modifyIntervenant = (intervenant) => {
        const header = {
            ID: intervenant.ID,
            longitude: intervenant.longitude,
            latitude: intervenant.latitude,
            nom: intervenant.nom,
            prenom: intervenant.prenom,
            mail: intervenant.mail,
            telephone: intervenant.telephone,
            telephone_fixe: intervenant.telephone_fixe,
            adresse_number: intervenant.adresse_number,
            adresse: intervenant.adresse,
            societe: intervenant.societe,
            specialite: intervenant.specialite,
            deplacement: intervenant.deplacement,
            mo: intervenant.mo,
            forfait: intervenant.forfait,
            pays: intervenant.pays,
            postal: intervenant.postal,
            ville: intervenant.ville,
            commentaire: intervenant.commentaire,
            fiabilite: intervenant.fiabilite,
        }
        axios.defaults.withCredentials = true
        axios.put("/legacy/modifyIntervenant", header)
            .then(response => {
                let newStateIntervenant = [...intervenants]
                let indexIntervenant = intervenants.map(item => item.ID).indexOf(response.data.intervenant.ID);
                newStateIntervenant[indexIntervenant] = response.data.intervenant
                enqueueSnackbar('Intervenant modifié', {
                    variant: 'success',
                });
                setIntervenants(newStateIntervenant)
            }).catch(error => {
                enqueueSnackbar('Modification de l\'intervenant impossible', {
                    variant: 'error',
                });
            });
    }

    const localisateIntervenant = (intervenant) => {
        let newIntervenantLocation = []
        newIntervenantLocation.push(
            {
                type: 'Feature',
                properties: {
                    description: `<div><strong style="font-size: 15px; margin-right: 10px">${intervenant.societe}</strong><p style="font-size: 15px; margin-right: 10px; margin-top: 0; margin-bottom: 0">${intervenant.ville}</p></div>`,
                },
                geometry: {
                    type: 'Point',
                    coordinates: [intervenant.longitude, intervenant.latitude]
                },
            }
        )
        setIntervenantLocation(newIntervenantLocation)
    }

    return (
        <Box sx={{ display: 'flex', height: "100vh" }}>
            <Grid container>
                <NavBarTop
                    setModalCreateIntervenant={setModalCreateIntervenant}
                    filter={filter}
                    onChangeFilter={onChangeFilter}
                    filteredIntervenant={filteredIntervenant}
                />
                <DisplayIntervenant
                    localisateIntervenant={localisateIntervenant}
                    intervenantionView={false}
                    filteredIntervenant={filteredIntervenant}
                    openModalModifyIntervenant={openModalModifyIntervenant}
                />
                <Grid item xs={8} style={{ height: "calc(100% - 50px)", position: "relative" }}>
                    <MapBox intervenantLocation={intervenantLocation} selectedVille={filter.ville?.geometry.coordinates} />
                </Grid>

            </Grid>

            <Modal open={modalModifyIntervenant} close={() => setModalModifyIntervenant(false)} sx={{ width: { xl: "40vw", md: "50vw", sm: "70vw", xs: "80vw" } }}>
                <ModifyIntervenant
                    modifyIntervenant={modifyIntervenant}
                    selectedIntervenant={selectedIntervenant}
                    close={() => setModalModifyIntervenant(false)}
                />
            </Modal>
            <Modal open={modalCreateIntervenant} close={() => setModalCreateIntervenant(false)} sx={{ width: { xl: "40vw", md: "50vw", sm: "70vw", xs: "80vw" } }}>
                <CreateIntervenant setIntervenants={setIntervenants} close={() => setModalCreateIntervenant(false)} />
            </Modal>
        </Box >
    )
}
export default BddIntervenant
