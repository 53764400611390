import React from 'react'
import {
    MenuItem,
    FormControl,
    Select,
} from '@mui/material';
import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const SelectStyled = styled(Select)(() => ({
    ".MuiSelect-select": {
        padding: "5px 10px",
    }
}));

// ----------------------------------------------------------------------

const SelectMonth = ({ action, onChange }) => {

    const returnDate = (event) => {
        onChange(new Date(new Date().getFullYear(), event.target.value, 10))
    }

    return (
        <FormControl size="small" fullWidth>
            <SelectStyled
                value={new Date(action.date_action).getMonth()}
                onChange={returnDate}
                displayEmpty
            >
                <MenuItem value={0}>Janvier</MenuItem>
                <MenuItem value={1}>Février</MenuItem>
                <MenuItem value={2}>Mars</MenuItem>
                <MenuItem value={3}>Avril</MenuItem>
                <MenuItem value={4}>Mai</MenuItem>
                <MenuItem value={5}>Juin</MenuItem>
                <MenuItem value={6}>Juillet</MenuItem>
                <MenuItem value={7}>Août</MenuItem>
                <MenuItem value={8}>Septembre</MenuItem>
                <MenuItem value={9}>Octobre</MenuItem>
                <MenuItem value={10}>Novembre</MenuItem>
                <MenuItem value={11}>Décembre</MenuItem>
            </SelectStyled>
        </FormControl>
    )
}

export default React.memo(SelectMonth)
