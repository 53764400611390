import Tab from './Tab';
import Card from './Card';
import Lists from './List';
import Drawer from './Drawer';
import Button from './Button';
import Tables from './Tables';
import { merge } from 'lodash';
import Checkbox from './Checkbox';
import TextField from './TextField'
import Typography from './Typography';
import IconButton from './IconButton';
import CssBaseline from './CssBaseline';

// ----------------------------------------------------------------------

export default function ComponentsOverrides(theme) {
    return merge(
        Card(theme),
        CssBaseline(theme),
        Tables(theme),
        Tab(theme),
        Button(theme),
        Typography(theme),
        IconButton(theme),
        TextField(theme),
        Drawer(theme),
        Lists(theme),
        Checkbox(theme),
    );
}
