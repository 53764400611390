import React, { useState } from 'react'

const TodoListContext = React.createContext({
    isAddedTask: false,
    idDeletedTask : false,
    onAddTask: () => { },
    onTaskDelete: () => { },
});

export const TodoListContextProvider = (props) => {

    const [addedTask, setAddedTask] = useState(false);
    const [deletedTask, setDeletedTask] = useState(false);

    const taskAddedHandler = () => {
        setAddedTask(!addedTask)
    }

    const taskDeletedHandler = () => {
        setDeletedTask(!deletedTask)
    }


    return <TodoListContext.Provider value={{ onAddTask: taskAddedHandler, onTaskDelete: taskDeletedHandler , isAddedTask : addedTask , idDeletedTask : deletedTask }}>
        {props.children}
    </TodoListContext.Provider>
}

export default TodoListContext