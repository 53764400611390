import React, { useState } from 'react';

// Components
import FlexFooter from "components/UI/FlexFooter";

// types
import { Module } from '../types';

// MUI
import { Multiline } from '../ModuleDevisStyle';
import Grid from '@mui/material/Unstable_Grid2';
import MultiSpecialite from 'components/UI/Specialite/MultiSpecialite';
import { Button, Divider, Stack, TextField } from '@mui/material';

const ModalAddModule: React.FC<{ addModule: (module: Module) => void }> = ({ addModule }) => {

    const [module, setModule] = useState<Module>({
        ID: 0,
        name: '',
        unite: 'M',
        quantite: 10,
        specialite: [],
        description: '',
        prixPreventiveDefaut: 100,
        prixCorrectiveDefaut: 200,
    });

    type CustomEvent = { name: string; value: any };

    const handleChange = (event: CustomEvent) => {
        if (event.name === 'prixCorrectiveDefaut' || event.name === 'prixPreventiveDefaut' || event.name === 'quantite') {
            event.value = Number(event.value);
        }

        setModule(prevModule => ({
            ...prevModule,
            [event.name]: event.value,
        }));
    };

    const isValid = module.description && (module.specialite !== undefined && module.specialite.length > 0);

    return (
        <React.Fragment>
            <Stack spacing={1} flexGrow={1} padding={2}>
                <Grid container alignItems="center" padding={2} spacing={1}>
                    <Grid xs={6}>
                        <TextField
                            fullWidth
                            size='small'
                            name='name'
                            autoComplete='off'
                            value={module.name}
                            label="Nom du module"
                            onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
                            InputLabelProps={{ shrink: true }}
                        />
                    </Grid>
                    <Grid xs={6}>
                        <MultiSpecialite
                            disabled={false}
                            name="specialite"
                            value={module.specialite}
                            changeSpecialite={handleChange}
                        />
                    </Grid>
                </Grid>
                <Divider variant='middle' />
                <Grid padding={3} sx={{ height: "100%" }}>
                    <Multiline
                        multiline
                        rows={10}
                        fullWidth
                        size='small'
                        autoComplete='off'
                        name='description'
                        label="Description"
                        sx={{ height: "100%" }}
                        onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })}
                        value={module.description}
                        InputLabelProps={{ shrink: true }}
                    />
                </Grid>
                <Grid container textAlign={"center"}>
                    <Grid xs={3}>
                        <TextField autoComplete='off' size='small' name='unite' label="Unité" value={module.unite} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' size='small' name='quantite' label="Quantité" value={module.quantite} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' type='number' size='small' name='prixCorrectiveDefaut' label="Prix Corrective" value={module.prixCorrectiveDefaut} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                    <Grid xs={3}>
                        <TextField autoComplete='off' type='number' size='small' name='prixPreventiveDefaut' label="Prix Préventive" value={module.prixPreventiveDefaut} onChange={(event) => handleChange({ name: event.target.name, value: event.target.value })} InputLabelProps={{ shrink: true }}></TextField>
                    </Grid>
                </Grid>
            </Stack>
            <FlexFooter custompadding={3}>
                <Button variant="outlined" size="small" onClick={() => addModule(module)} disabled={!isValid}>Créer</Button>
            </FlexFooter>
        </React.Fragment>
    );
};

export default ModalAddModule;
