import React from 'react';
import { Stack } from '@mui/material';
import { styled } from '@mui/material/styles';

export const FlexStack = styled(Stack)(({ theme, custompadding, justifyContent }) => ({
    flex: "0 1 auto",
    padding: theme.spacing(custompadding || 3),
    justifyContent: justifyContent,
}))

export default function FlexFooter({ children, custompadding, justifyContent = "space-evenly" }) {
    return (
        <FlexStack direction="row" justifyContent={justifyContent} custompadding={custompadding}>
            {children}
        </FlexStack>
    );
}