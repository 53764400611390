import React from 'react';

import DashboardLayout from 'navigation/index';
import { Box } from '@mui/material';

const layout = (props) => (
    <Box>
        <DashboardLayout>
            {props.children}
        </DashboardLayout>
    </Box>

);

export default layout;