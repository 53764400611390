import React from 'react'
import DatePicker from "react-datepicker";
import fr from 'date-fns/locale/fr';
import axios from 'axios';
import "react-datepicker/dist/react-datepicker.css";

import "./InterventionCalendar.css";

const InterventionCalendar = (props) => {
    const [date] = React.useState(new Date());
    const [closedDay, setclosedDay] = React.useState({});

    const highlightWithRanges = [
        {
            "react-datepicker__day--highlighted-custom-1": []
        },
        {
            "react-datepicker__day--highlighted-custom-2": []
        },
        {
            "react-datepicker__day--highlighted-custom-3": []
        },
        {
            "react-datepicker__day--Date-Max": props.dateMax !== "Invalid Date" ? [new Date(props.dateMax)] : []
        }
    ];

    React.useEffect(() => {
        const url = "https://calendrier.api.gouv.fr/jours-feries/metropole/" + new Date().getFullYear() + ".json"
        axios.defaults.withCredentials = false
        axios.get(url)
            .then(response => {
                setclosedDay(response.data)
            })
            .catch(error => {
                console.log(error)
            });
    }, []);

    props.actions.map((item) => {
        Object.keys(item.date_intervention).map((key) => {
            if (new Date(item.date_intervention[key].date) > new Date()) {
                highlightWithRanges[1][`react-datepicker__day--highlighted-custom-2`].push(new Date(item.date_intervention[key].date))
            }
            else {
                highlightWithRanges[0][`react-datepicker__day--highlighted-custom-1`].push(new Date(item.date_intervention[key].date))
            }
            return null
        })
        return null
    })

    Object.keys(closedDay).map((item) => {
        highlightWithRanges[2][`react-datepicker__day--highlighted-custom-3`].push(new Date(item))
        return null
    })

    const isWeekday = date => {
        const day = date.getDay();
        return day !== 0 && day !== 6;
    };

    return (
        <DatePicker
            selected={date}
            filterDate={isWeekday}
            style={{ margin: "auto" }}
            highlightDates={highlightWithRanges}
            inline
            locale={fr}
            dateFormat="d MMMM yyyy, h:mm"
            readOnly
        />
    )
}

export default InterventionCalendar;