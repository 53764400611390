import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from 'axios';

export default class HttpService {
    private instance: AxiosInstance;

    constructor() {
        this.instance = axios.create({
            baseURL: process.env.REACT_APP_API_BASE_URL,
            headers: { "Content-Type": "application/json" },
            withCredentials: true,
        });
    }

    private async request<T>(method: Method, url: string, data?: any, config?: AxiosRequestConfig): Promise<T> {
        const source = axios.CancelToken.source();
        const requestConfig: AxiosRequestConfig = {
            method: method,
            url: url,
            cancelToken: source.token,
            data: data,
            withCredentials: true,
            ...config
        };

        try {
            const response: AxiosResponse<T> = await this.instance(requestConfig);
            return response.data;
        } catch (error) {
            throw error;
        }
    }

    public get<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('get', url, null, config);
    }

    public post<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('post', url, data, config);
    }

    public put<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('put', url, data, config);
    }

    public patch<T>(url: string, data: any, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('patch', url, data, config);
    }

    public delete<T>(url: string, config?: AxiosRequestConfig): Promise<T> {
        return this.request<T>('delete', url, null, config);
    }
}
