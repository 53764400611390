import axios from 'axios';
import { logout as authLogout } from "services";

import * as actionTypes from './actionTypes';

export const authStart = () => {
    return {
        type: actionTypes.AUTH_START
    };
};

export const authSuccess = (isAuthentificated, userID, lastname, firstname, mail, attached_to, pilote, id, telephone, username, role, administrateur, entreprise) => {
    return {
        type: actionTypes.AUTH_SUCCESS,
        isAuthentificated: isAuthentificated,
        userID: userID,
        lastname: lastname,
        firstname: firstname,
        mail: mail,
        attached_to: attached_to,
        pilote: pilote,
        id: id,
        role: role,
        administrateur: administrateur,
        entreprise: entreprise,
        telephone: telephone,
        username: username,
    };
};

export const authFail = (error) => {
    return {
        type: actionTypes.AUTH_FAIL,
        error: error
    };
};

export const logout = () => {
    axios.defaults.withCredentials = true
    authLogout()
    return {
        type: actionTypes.AUTH_LOGOUT
    };
};

export const auth = (username, password) => {
    return dispatch => {
        dispatch(authStart());
        const authData = {
            username: username,
            password: password,
        };
        axios.defaults.withCredentials = true
        axios.post('/auth/login', authData)
            .then(response => {
                dispatch(authSuccess(true, response.data.user.UserID, response.data.user.LastName, response.data.user.FirstName, response.data.user.Mail, response.data.user.AttachedTo, response.data.user.Pilote, response.data.user.ID, response.data.user.Telephone, response.data.user.Username, response.data.user.Role, response.data.user.Administrateur, response.data.user.Entreprise));
            }).catch(err => {
                console.log(err);
                return dispatch(authFail(err));
            });
    };
};

export const authCheckState = () => {
    return dispatch => {
        axios.defaults.withCredentials = true
        axios.get('/auth/login')
            .then(response => {
                dispatch(authSuccess(true, response.data.UserID, response.data.LastName, response.data.FirstName, response.data.Mail, response.data.AttachedTo, response.data.Pilote, response.data.ID, response.data.Telephone, response.data.Username, response.data.Role, response.data.Administrateur, response.data.Entreprise));
            }).catch(err => {
                console.log(err);
                dispatch(authFail(err));
            });
    };
};
