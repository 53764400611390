import * as React from 'react';
import Stack from '@mui/material/Stack';
import { BasicPaginationProps } from './types';
import Pagination from '@mui/material/Pagination';

export const BasicPagination: React.FC<BasicPaginationProps> = ({
  total,
  currentPage,
  itemsPerPage,
  onPageChange,
}) => {

  if (total === undefined) {
    return null;
  }
  const pageCount = Math.ceil(total / itemsPerPage);

  return (
    <Stack spacing={2}>
      <Pagination
        color="primary"
        count={pageCount}
        page={currentPage}
        onChange={onPageChange}
      />
    </Stack>
  );
};

