import React from 'react';
import CloseIcon from '@mui/icons-material/Close';
import IconButton from '@mui/material/IconButton';

export default function CloseButton({ close, style = {} }) {
    return (
        <IconButton
            style={{ position: 'absolute', right: '5px', top: '5px', zIndex: '4', ...style }}
            aria-label="close"
            onClick={close}
            size="large">
            <CloseIcon />
        </IconButton>
    );
}
