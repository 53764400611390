import React from 'react';
// mui
import {
    TableRow,
    TextField,
    IconButton,
    Table,
    TableBody,
    TableHead,
    TableCell,
    Divider,
    Stack,
} from '@mui/material';
import DeleteIcon from '@mui/icons-material/Delete';
import Checkbox from '@mui/material/Checkbox';
import { styled } from '@mui/material/styles';
import MoreTimeIcon from '@mui/icons-material/MoreTime';
import NightlightIcon from '@mui/icons-material/Nightlight';
//components
import { STATUTS_COLOR } from 'config/index'
import TableMobileDatePicker from 'components/UI/TableInput/TableMobileDatePicker'
import moment from 'moment';


// ----------------------------------------------------------------------

const StyledTableCellHeader = styled(TableCell)(({ theme }) => ({
    whiteSpace: 'nowrap',
    padding: "5px 10px",
    borderBottom: "none",
    fontWeight: "bold",
    color: "white",
    height: '30px',
}));

const StyledTableCell = styled(TableCell)(() => ({
    padding: "5px 10px",
    maxHeight: "30px !important",
    height: "30px !important",
    borderBottom: "none",
}));

const StyledTextField = styled(TextField)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    "input": {
        padding: "5px 10px",
    }
}));

// ----------------------------------------------------------------------


export default function TableDateIntervention({ action, disabled, newDateIntervention, modifyDateIntervention, deleteDateIntervention }) {

    return (
        <TableRow>
            <TableCell colSpan={9}>
                <Table size="small">
                    <TableHead>
                        {/* Header */}
                        <TableRow>
                            <StyledTableCell colSpan={1} style={{ border: "unset", padding: "0px 10px", background: 'white' }}>
                                <Divider orientation="vertical" style={{ margin: "auto", background: STATUTS_COLOR[action.etat], width: '1px', height: "100%" }} flexItem />
                            </StyledTableCell>
                            <StyledTableCellHeader style={{ background: STATUTS_COLOR[action.etat], width: "150px", borderTopLeftRadius: "5px" }}>Date d'intervention</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "90px" }}>Heure</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "90px" }}>Durée</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "65px" }}>Nuit</StyledTableCellHeader>
                            <StyledTableCellHeader align="center" style={{ background: STATUTS_COLOR[action.etat], width: "60px" }}>IS</StyledTableCellHeader>
                            <StyledTableCell style={{ background: STATUTS_COLOR[action.etat] }}>
                                <IconButton
                                    aria-label="add"
                                    onClick={() => newDateIntervention(action)}
                                    disabled={disabled}
                                    style={{ color: "white" }}
                                >
                                    <MoreTimeIcon />
                                </IconButton>
                            </StyledTableCell>
                        </TableRow>
                    </TableHead>

                    {/* Row Date intervention d'une action*/}
                    <TableBody>
                        {action.date_intervention.map((date_intervention, index) => (
                            <TableRow key={index}>
                                <StyledTableCell style={{ padding: "0px 10px" }}>
                                    <Divider orientation="vertical" style={{ margin: "auto", background: STATUTS_COLOR[action.etat], width: '1px', height: "100%" }} flexItem />
                                </StyledTableCell>
                                <StyledTableCell style={{ width: "130px" }}>
                                    <Stack direction="row" spacing={1} justifyContent="left" alignItems="center">
                                        {date_intervention.nuit ? <NightlightIcon color="primary" /> : null}

                                        <TableMobileDatePicker
                                            inputFormat="dddd DD MMM"
                                            value={moment(date_intervention.date)}
                                            disabled={disabled}
                                            onChange={(value) => modifyDateIntervention('date', value, action.ID, date_intervention.ID)}
                                        />
                                    </Stack>
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "90px" }}>
                                    <StyledTextField
                                        size="small"
                                        variant="outlined"
                                        id="duration"
                                        type="time"
                                        defaultValue={new Date(date_intervention.date).toLocaleTimeString("fr-FR", { hour: "numeric", minute: "numeric" })}
                                        disabled={disabled}
                                        onChange={(event) => {
                                            const strCopy = event.target.value.split(':')
                                            const newDate = new Date(date_intervention.date)
                                            if (strCopy.length === 2) {
                                                newDate.setHours(parseInt(strCopy[0], 10), parseInt(strCopy[1], 10), 0)
                                            }
                                            modifyDateIntervention('date', newDate, action.ID, date_intervention.ID)
                                        }}
                                        inputProps={{
                                            step: 900, // 15 min
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "90px" }}>
                                    <StyledTextField
                                        size="small"
                                        id="duration"
                                        variant="outlined"
                                        type="time"
                                        defaultValue={date_intervention.duration}
                                        disabled={disabled}
                                        onChange={(event) => modifyDateIntervention('duration', event.target.value, action.ID, date_intervention.ID)}
                                        inputProps={{
                                            step: 1800, // 30 min
                                        }}
                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "50px" }}>
                                    <Checkbox
                                        checked={date_intervention.nuit}
                                        onChange={(event) => modifyDateIntervention('nuit', event.target.checked, action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        name="nuit"

                                    />
                                </StyledTableCell>
                                <StyledTableCell align="center" style={{ width: "50px" }}>
                                    <Checkbox
                                        checked={date_intervention.info_site}
                                        onChange={(event) => modifyDateIntervention('info_site', event.target.checked, action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        name="info_site"
                                    />
                                </StyledTableCell>
                                <StyledTableCell colSpan={2} style={{ width: "30px" }}>
                                    <IconButton
                                        aria-label="add"
                                        onClick={() => deleteDateIntervention(action.ID, date_intervention.ID)}
                                        disabled={disabled}
                                        size="small">
                                        <DeleteIcon color='error' />
                                    </IconButton>
                                </StyledTableCell>
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableCell>
        </TableRow >
    )
}

