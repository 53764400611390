import React from 'react';
// import { connect } from 'react-redux';

import PropTypes from 'prop-types';
// date
import { dateConfig } from 'config'
import moment from 'moment';
import 'moment/locale/fr';
// material
import {
    TableRow,
    TableBody,
    TableCell,
} from '@mui/material';
// components
import Status from 'components/UI/Status';
import DetailModal from '../DetailModal';
import IconButton from '@mui/material/IconButton';
import DeleteForeverIcon from '@mui/icons-material/DeleteForever';
import RestoreFromTrashIcon from '@mui/icons-material/RestoreFromTrash';

// ----------------------------------------------------------------------

CorbeilleList.propTypes = {
    rowsPerPage: PropTypes.number,
    filteredTable: PropTypes.array,
};

export function CorbeilleList({ corbeille, rowsPerPage, page, restoreDeletedDemandeIntervention, DeleteTaskHandle }) {
    return (
        <TableBody sx={{
            whiteSpace: { xs: "nowrap", xl: "normal" }
        }}>
            {corbeille
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const { status, enseigne, objet, numero_magasin, code_postal, ville, } = row;
                    const date_demande = moment(row.date_demande).format(dateConfig.format1)
                    return (
                        <TableRow
                            key={index}
                            hover
                            tabIndex={-1}
                        >
                            <TableCell align="center">{
                                <DetailModal intervention={row} />
                            }
                            </TableCell>
                            <TableCell align="center">{enseigne}</TableCell>
                            <TableCell align="center">{ville + " " + code_postal + " (n° " + numero_magasin + ")"}</TableCell>
                            <TableCell align="center">{objet}</TableCell>
                            <TableCell align="center">{date_demande}</TableCell>
                            <TableCell align="center">
                                <Status ownerState={status} />
                            </TableCell>
                            <TableCell align="center">
                                <IconButton aria-label="delete" size="large" onClick={() => restoreDeletedDemandeIntervention(row)}>
                                    <RestoreFromTrashIcon fontSize="large" sx={{ color: 'green' }} />
                                </IconButton>
                            </TableCell>
                            <TableCell align="center">
                                <IconButton aria-label="delete" size="large" onClick={() => DeleteTaskHandle(row)}>
                                    <DeleteForeverIcon fontSize="large" sx={{ color: 'red' }} />
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody >
    );
}
