import { styled } from '@mui/material/styles';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';

const StyledTableRow = styled(TableRow)(() => ({
  backgroundColor: "#fff",
  '&:nth-of-type(odd)': {
    backgroundColor: "#f5f5f5",
  },
  cursor: "pointer",
}));

const StyledTableCellHeader = styled(TableCell)(() => ({
  '&.MuiTableCell-head': {
    height: "40px",
    padding: "5px 10px",
  },
}));

const StyledTableCell = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    padding: "0px 10px",
    maxHeight: "35px !important",
    height: "35px !important",
  },
}));

const StyledTableCellHeader2 = styled(TableCell)(() => ({
  '&.MuiTableCell-root': {
    background: 'white',
    color: "white",
    padding: "5px",
    top: 40,
  },
}));

export { StyledTableRow, StyledTableCellHeader, StyledTableCell, StyledTableCellHeader2 };
