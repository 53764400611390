import { styled } from '@mui/material/styles';
import {Button,} from '@mui/material';

export const Attachment = styled('div')(() => ({
    display: "flex",
    gap: "8px"
}))

export const ButtonAttach = styled(Button)(() => ({
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
}))

export const DivButton = styled('div')(() => ({
    width: "32%",
}))