export default function IconButton(theme) {

    return {
        MuiIconButton: {
            variants: [
                {
                    props: { color: 'default' },
                    style: {
                        '&:hover': { backgroundColor: theme.palette.action.hover }
                    }
                },
                {
                    props: { color: 'inherit' },
                    style: {
                        '&:hover': { backgroundColor: theme.palette.action.hover }
                    }
                }
            ],
            styleOverrides: {
                root: {
                    padding: "3px",
                }
            }
        },
        MuiSvgIcon: {
            styleOverrides: {
                root: {
                    fontSize: "20px",
                    [theme.breakpoints.down('xl')]: {
                        fontSize: "15px !important",
                    },
                },
            },
        },
    };
}
