import React, { useState } from "react";
import lodash from "lodash";
// material
import {
    Paper,
    Table,
    Stack,
    TableContainer,
    TablePagination,
    Box,
} from '@mui/material';
// moment
import { dateConfig } from 'config'
import moment from 'moment';
import 'moment/locale/fr';
// components
import FormDemandeIntervention from 'components/UI/FormDemandeIntervention/FormDemandeIntervention'
import Modal from 'components/UI/Modal'
import Page from 'components/UI/Page';
import DisplayHeader from './components/displayHeader';
import PiloteFilter from 'components/UI/PiloteFilter';
import { DisplayBody } from './components/displayBody';
import Corbeille from "./components/Corbeille/Corbeille";

// ----------------------------------------------------------------------

const TABLE_HEAD = [
    { id: 'détails', label: 'Détails', alignRight: false },
    { id: 'demande_par', label: 'Demandé par', alignRight: false },
    { id: 'enseigne', label: 'Enseigne', alignRight: false },
    { id: 'adresse', label: 'Adresse', alignRight: false },
    { id: 'objet', label: 'Objet', alignRight: false },
    { id: 'initial_request', label: 'Demande Initiale', alignRight: false },
    { id: 'date_demande', label: 'Date demande', alignRight: false },
    { id: 'status', label: 'Statut', alignRight: false },
    { id: 'action', label: 'Action', alignRight: false },
    { id: 'corbeille', label: 'Corbeille', alignRight: false },
];

// ----------------------------------------------------------------------

function descendingComparator(a, b, orderBy) {
    if (orderBy === "date_demande") {
        //Handle undefined date
        if (b[orderBy].length === 0) {
            return 1
        }
        if (a[orderBy].length === 0) {
            return -1
        }
        //Sort defined date
        if (b[orderBy][0].date < a[orderBy][0].date) {
            return 1;
        }
        if (b[orderBy][0].date > a[orderBy][0].date) {
            return -1;
        }
        return 0;
    } else {
        if (b[orderBy] < a[orderBy]) {
            return -1;
        }
        if (b[orderBy] > a[orderBy]) {
            return 1;
        }
        return 0;
    }
}

function getComparator(order, orderBy) {
    return order === 'desc'
        ? (a, b) => descendingComparator(a, b, orderBy)
        : (a, b) => -descendingComparator(a, b, orderBy);
}

function applyTableFilter(array, filter) {
    let parseFilter = { ...filter }
    let filteredArray = [...array]

    if (parseFilter["status"] === 'Tout')
        parseFilter["status"] = ""
    if (parseFilter["type"] === 'Tout')
        parseFilter["type"] = ""
    parseFilter = lodash.omitBy(parseFilter, lodash.isEmpty)

    Object.keys(parseFilter).forEach((filter) => {
        filteredArray = filteredArray.filter(item => {
            if (filter === "date_demande") {
                if (item[filter].length === 0) {
                    return false
                }
                return moment(item[filter][0].date).format(dateConfig.format1).toLowerCase().includes(parseFilter[filter].toLowerCase());
            }
            return item[filter].toLowerCase().includes(parseFilter[filter].toLowerCase());
        })
    })
    return filteredArray
}

function applySortFilter(array, comparator, tableFilter) {
    array = applyTableFilter(array, tableFilter)
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = comparator(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}


export default function InterventionRequestView({
    corbeille,
    interventions,
    handleStatus,
    pilotes,
    parametres,
    setLoading,
    updatePiloteFilter,
    putDemandeInterventionInCorbeille,
    restoreDeletedDemandeIntervention,
    DeleteTaskHandle,
}) {
    const [page, setPage] = useState(0);
    const [order, setOrder] = useState('asc');
    const [orderBy, setOrderBy] = useState('date_demande');
    const [rowsPerPage, setRowsPerPage] = useState(10);
    const [tableFilter, setTableFilter] = useState({
        'date_demande': "", 'enseigne': "", 'objet': "",
        'adresse': "", 'status': "Tout", 'initial_request': "", 'action': "", 'corbeille': ""
    })
    const [openFormDemandeInterventionClient, setOpenFormDemandeInterventionClient] = useState(false);
    const [intervention, setIntervention] = useState([]);

    const handleModal = (row) => {
        row.date_max = new Date()
        setIntervention(row)
        setOpenFormDemandeInterventionClient(!openFormDemandeInterventionClient);
    };

    const handleRequestSort = (property) => {
        const isAsc = orderBy === property && order === 'asc';
        setOrder(isAsc ? 'desc' : 'asc');
        setOrderBy(property);
    };

    const handleChangePage = (event, newPage) => {
        setPage(newPage);
    };

    const handleChangeRowsPerPage = (event) => {
        setRowsPerPage(parseInt(event.target.value, 10));
        setPage(0);
    };

    const handleChangeTableFilter = (event) => {
        let newTableFilter = { ...tableFilter }
        newTableFilter[event.target.name] = event.target.value
        setTableFilter(newTableFilter)
    }

    const filteredTable = applySortFilter(interventions, getComparator(order, orderBy), tableFilter);

    return (
        <Box style={{ width: "100%", height: "100vh" }}>
            <Page title={"Demande d'interventions"}>
                <Stack
                    justifyContent="center"
                    alignItems="center"
                    sx={{ p: 1 }}
                >
                    <Paper style={{ width: '100%', background: "white", height: "calc(100vh - 16px)" }}>
                        <TableContainer sx={{ height: "calc(100% - 5vh)" }}>
                            <Table stickyHeader size="small">
                                <DisplayHeader
                                    order={order}
                                    orderBy={orderBy}
                                    headLabel={TABLE_HEAD}
                                    tableFilter={tableFilter}
                                    onRequestSort={handleRequestSort}
                                    handleChangeTableFilter={handleChangeTableFilter}
                                />
                                <DisplayBody
                                    handleModal={handleModal}
                                    parametres={parametres}
                                    handleStatus={handleStatus}
                                    filteredTable={filteredTable}
                                    rowsPerPage={rowsPerPage}
                                    setLoading={setLoading}
                                    page={page}
                                    putDemandeInterventionInCorbeille={putDemandeInterventionInCorbeille}
                                />
                            </Table>
                        </TableContainer>
                        <TableContainer style={{ display: "flex", bottom: "0px", paddingLeft: "10px" }}>
                            <Stack direction="row" spacing={1} alignItems="center" sx={{ mr: 'auto' }}>
                                <PiloteFilter piloteFilter={pilotes} updatePiloteFilter={updatePiloteFilter} />
                                <Corbeille
                                    handleModal={handleModal}
                                    corbeille={corbeille}
                                    parametres={parametres}
                                    handleStatus={handleStatus}
                                    filteredTable={filteredTable}
                                    rowsPerPage={rowsPerPage}
                                    setLoading={setLoading}
                                    page={page}
                                    DeleteTaskHandle={DeleteTaskHandle}
                                    restoreDeletedDemandeIntervention={restoreDeletedDemandeIntervention}
                                />
                            </Stack>
                            <TablePagination
                                sx={{ height: "5vh", display: "flex", alignItems: "center", justifyContent: "center", overflow: "hidden", }}
                                rowsPerPageOptions={[10, 25, 50]}
                                component="div"
                                count={filteredTable.length}
                                rowsPerPage={rowsPerPage}
                                page={page}
                                onPageChange={handleChangePage}
                                onRowsPerPageChange={handleChangeRowsPerPage}
                            />
                        </TableContainer>
                    </Paper>
                </Stack>
            </Page >
            <Modal open={openFormDemandeInterventionClient} close={handleModal} sx={{ width: { xs: "95vw", sm: "95vw", md: "80vw", md2: "60vw" }, height: "95vh" }}>
                <FormDemandeIntervention
                    pilotes={pilotes}
                    handleStatus={handleStatus}
                    setLoading={setLoading}
                    intervention={intervention}
                    putDemandeInterventionInCorbeille={putDemandeInterventionInCorbeille}
                />
            </Modal>
        </Box>
    );
}

