import React from "react";

// Mui
import { NavBarProps, searchFilters } from "../types";

// Components
import MultiSpecialite from "components/UI/Specialite/MultiSpecialite";

// Mui & Icons
import AddIcon from '@mui/icons-material/Add';
import Grid from '@mui/material/Unstable_Grid2';
import SearchIcon from '@mui/icons-material/Search';
import ViewListIcon from '@mui/icons-material/ViewList';
import ViewModuleIcon from '@mui/icons-material/ViewModule';
import { TextField, Button, Stack, Box, FormControl, ToggleButton, ToggleButtonGroup } from "@mui/material";

interface SpecialiteChangeEvent {
    value: string[];
}

const NavBar: React.FC<NavBarProps> = ({ handleOpenCreateModal, onSearch, filters, setFilters, handleToggleViewMode, viewMode }) => {
    const handleInputChange = (field: keyof searchFilters, value: string | string[]) => {
        setFilters(prev => ({
            ...prev,
            [field]: value,
        }));
    };

    const handleSearch = () => {
        onSearch();
    };

    return (
        <Grid xs={12} sx={{ boxShadow: "rgba(100, 100, 111, 0.15) 0px 5px 15px 0px", zIndex: "2", p: 1 }} alignItems="center">
            <Stack direction={"row"} padding={1} sx={{ justifyContent: 'space-between' }}>
                <Stack direction={"row"} spacing={1} sx={{ width: "100%" }}>
                    <TextField
                        label="Nom"
                        size='small'
                        autoComplete='off'
                        sx={{ width: "18%" }}
                        value={filters.name || ""}
                        InputLabelProps={{ shrink: true }}
                        onChange={(e) => handleInputChange('name', e.target.value)}
                    />
                    <TextField
                        size='small'
                        autoComplete='off'
                        label="Description"
                        sx={{ width: "18%" }}
                        InputLabelProps={{ shrink: true }}
                        value={filters.description || ""}
                        onChange={(e) => handleInputChange('description', e.target.value)}
                    />
                    <FormControl variant="outlined" size="small" sx={{ width: '22%', maxWidth: "22%" }}>
                        <MultiSpecialite
                            disabled={false}
                            name="specialite"
                            value={filters.specialite || "Sélectionner une spécialité"}
                            changeSpecialite={(event: SpecialiteChangeEvent) => handleInputChange('specialite', event.value)}
                        />
                    </FormControl>
                    <Stack alignItems={"center"}>
                        <Button variant="outlined" startIcon={<SearchIcon />} onClick={handleSearch}>Rechercher</Button>
                    </Stack>
                    <Stack alignItems={"center"}>
                        <ToggleButtonGroup
                            exclusive
                            size="small"
                            value={viewMode}
                            aria-label="view mode"
                            onChange={handleToggleViewMode}
                        >
                            <ToggleButton value="card" aria-label="card" size="small" color="primary">
                                <ViewModuleIcon />
                            </ToggleButton>
                            <ToggleButton value="list" aria-label="list" size="small" color="primary">
                                <ViewListIcon />
                            </ToggleButton>
                        </ToggleButtonGroup>
                    </Stack>
                </Stack>
                <Stack alignItems={"center"}>
                    <Button
                        size="small"
                        variant='outlined'
                        startIcon={<AddIcon />}
                        onClick={handleOpenCreateModal}
                    >
                        Module
                    </Button>
                </Stack>
            </Stack>
        </Grid>
    );
}

export default NavBar;
