
export default function Card() {
    return {
        MuiCard: {
            styleOverrides: {
                root: {
                    background: '#fcfcfc',
                    height: "100%",
                    position: 'relative',
                    zIndex: 0, // Fix Safari overflow: hidden with border radius,
                    overflow: 'inherit',
                }
            }
        },
    };
}
