import { styled } from '@mui/material/styles';
import { Button, Table } from '@mui/material';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import AddIcon from '@mui/icons-material/Add';

export const ButtonAdd = styled(Button)(() => ({
    width: 'auto',
    height: '100%',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
}))

export const IconAdd = styled(AddIcon)(({theme}) =>({
    [theme.breakpoints.down('xl')]: {
        fontSize: "20px",
    },
    [theme.breakpoints.down(undefined)]: {
        fontSize: "18px",
    }
}))

export const SaveButton = styled(Button)(() =>({
    marginTop: '12px',
    height: '100%',
    background: 'white',
    justifyContent: "center",

}))

export const DivButton = styled('div')(() => ({
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
}))

export const CustomTable = styled(Table)(() => ({
    minWidth: 650,

}))

export const IconImage = styled(ImageIcon)(() => ({
    color: "#316690",
    cursor: "pointer",
    '&:hover': {
        color: "#316690",
    }
}))

export const IconDelete = styled(DeleteIcon)(() => ({
    color: "#FF0000",
    cursor: "pointer",
    '&:hover': {
        color: "#DD0000",
    }
}))

export const IconEdit = styled(EditIcon)(() => ({
    color: "#316690",
        cursor: "pointer",
        '&:hover': {
            color: "#1e3e56",
        }
}))