import HttpService from "./HTTPService";
//import { Stock } from "views/Stocks/types";

export default class StockService {
    private httpService: HttpService;
    private readonly PATH_GET_ALL_STOCKS = "/legacy/stocks";
    private readonly PATH_GET_STOCKS_BY_ID = "/legacy/stocks";
    private readonly PATH_GET_STOCKS_BY_CLIENT = "/legacy/stockByClient";
    private readonly PATH_GET_STOCK_BY_INTERVENANT_AND_BY_CLIENT = "/legacy/stockByIntervenantAndClient";
    private readonly PATH_GET_STOCKS_BY_PRODUCT = "/legacy/stockByProduct";
    private readonly PATH_POST_STOCK = "/legacy/stocks";
    private readonly PATH_PUT_STOCK = "/legacy/stocks";
    private readonly PATH_GET_ALL_COMMANDES = "/legacy/commandeStocks";
    private readonly PATH_GET_COMMANDE_BY_IDMASTORE = "/legacy/commandeStockIdmastore";
    private readonly PATH_GET_COMMANDE_BY_CLIENT = "/legacy/commandeStockByClient";
    private readonly PATH_GET_COMMANDE_BY_ID = "/legacy/commandeStockById";
    private readonly PATH_POST_COMMANDE = "/legacy/commandeStock";
    private readonly PATH_PUT_COMMANDE = "/legacy/commandeStock";
    private readonly PATH_GET_HISTORIQUE_STOCK = "/legacy/historique-stock";
    private readonly PATH_GET_FICHE_COMMANDE = "/legacy/fichecommande";
    private readonly PATH_CREATE_FICHE_COMMANDE = "/legacy/fichecommande";

    private readonly PATH_GET_PHOTO_STOCK = "/legacy/photo-stocks";

    private readonly PATH_PUT_FICHE_COMMANDE = "/legacy/fichecommande";
    private readonly PATH_DELETE_FICHE_COMMANDE = "/legacy/fichecommande";

    constructor() {
        this.httpService = new HttpService();
    }

    // -- STOCKS --
    async getAllStocks(): Promise<any> {
        return await this.httpService.get(this.PATH_GET_ALL_STOCKS);
    }

    async getStocksById(id: number): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_STOCKS_BY_ID}?id=${id}`);
    }

    async getStocksByClient(client: string): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_STOCKS_BY_CLIENT}?client=${client}`);
    }

    async getStocksByProduct(product: string): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_STOCKS_BY_PRODUCT}?product=${product}`);
    }

    async getStockByIntervenantAndClient(intervenant: string, client: string): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_STOCK_BY_INTERVENANT_AND_BY_CLIENT}?intervenant=${intervenant}&client=${client}`);
    }

    async postStock(stock: any): Promise<any> {
        return await this.httpService.post(this.PATH_POST_STOCK, stock);
    }

    async putStock(stock: any): Promise<any> {
        return await this.httpService.put(this.PATH_PUT_STOCK, stock);
    }

    // -- COMMANDES --
    async getAllCommandes(): Promise<any> {
        return await this.httpService.get(this.PATH_GET_ALL_COMMANDES);
    }

    async getCommandeByIdMastore(id_mastore: number): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_COMMANDE_BY_IDMASTORE}?id_mastore=${id_mastore}`);
    }

    async getCommandeByClient(client: string): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_COMMANDE_BY_CLIENT}?client=${client}`);
    }

    async getCommandeById(id: number): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_COMMANDE_BY_ID}?id=${id}`);
    }

    async postCommande(commande: any): Promise<any> {
        return await this.httpService.post(this.PATH_POST_COMMANDE, commande);
    }

    async putCommande(commande: any): Promise<any> {
        return await this.httpService.put(this.PATH_PUT_COMMANDE, commande);
    }

    // -- HISTORIQUE STOCK --
    async getHistoriqueStock(id_stock: any): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_HISTORIQUE_STOCK}?id_stock=${id_stock}`);
    }

    // -- FICHE COMMANDE --
    async getFicheCommande(id_mastore: number): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_FICHE_COMMANDE}?id_mastore=${id_mastore}`);
    }

    async createFicheCommande(preview: string, req: any): Promise<any> {
        return await this.httpService.post(`${this.PATH_CREATE_FICHE_COMMANDE}?preview=${preview}`, req);
    }

    async putFicheCommande(req: any): Promise<any> {
        return await this.httpService.put(this.PATH_PUT_FICHE_COMMANDE, req);
    }

    async deleteFicheCommande(id_fiche: any): Promise<any> {
        return await this.httpService.delete(`${this.PATH_DELETE_FICHE_COMMANDE}/${id_fiche}`);
    }

    async getPhotoStock(id_stock: any): Promise<any> {
        return await this.httpService.get(`${this.PATH_GET_PHOTO_STOCK}?IDStock=${id_stock}`);
    }

}