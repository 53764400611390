import React from 'react'
import {
    Button,
} from '@mui/material';
import CheckIcon from '@mui/icons-material/Check';
import CloseIcon from '@mui/icons-material/Close';

const FacturableButton = ({ infoIntervention, updateInfoIntervention }) => {
    return (
        <Button
            onClick={() => updateInfoIntervention(!infoIntervention.facturable, 'facturable')}
            startIcon={infoIntervention.facturable ? <CheckIcon /> : <CloseIcon />}
            color={infoIntervention.facturable ? "success" : "error"}
            variant="outlined"
            size='small'
        >
            Chiffrable
        </Button>
    )
}

export default FacturableButton