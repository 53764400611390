import React, { useEffect, useState, memo } from 'react'
//mui
import { Stack, Box, Button, Grid, Typography } from '@mui/material';
import StarIcon from '@mui/icons-material/Star';
import { styled } from '@mui/material/styles';
import CircularProgress from '@mui/material/CircularProgress';
import MailIcon from '@mui/icons-material/Mail';
import BuildIcon from '@mui/icons-material/Build';
import PhoneIcon from '@mui/icons-material/Phone';
import PaymentIcon from '@mui/icons-material/Payment';
import NoteAltIcon from '@mui/icons-material/NoteAlt';
import DirectionsCarIcon from '@mui/icons-material/DirectionsCar';
import InfiniteScroll from "react-infinite-scroller";
import TrackChangesIcon from '@mui/icons-material/TrackChanges';

// ----------------------------------------------------------------------

const IntervenantCardStyle = styled(Stack)(({ theme, fiabilite }) => ({
    "&:nth-of-type(odd)": {
        backgroundColor: "#f5f5f5"
    },
    position: 'relative',
    padding: '10px 20px',
    ...(fiabilite === 2 && {
        backgroundColor: "#ff5f0024 !important",
    }),
    ...(fiabilite === 1 && {
        backgroundColor: "#fd000029 !important",
    }),
    ...(fiabilite === 5 && {
        backgroundColor: "#249b0026 !important",
    }),
}));


const LoaderStyle = styled(Stack)(() => ({
    padding: "5px",
    margin: "5px",
    border: "1px solid #d8d8d8",
    borderRadius: "3px",
    justifyContent: "center",
    alignItems: "center",
}));


// ----------------------------------------------------------------------


const IntervenantCards = memo(function IntervenantCards({ intervenant, selectIntervenant, openModalModifyIntervenant, intervenantionView, localisateIntervenant }) {
    return (
        <IntervenantCardStyle fiabilite={intervenant.fiabilite}>
            <Stack style={{ position: "absolute", top: "10px", right: "20px" }}>
                <Stack direction="row" alignItems="center">
                    <Typography variant='h5'>{intervenant.fiabilite}</Typography>
                    <StarIcon color={intervenant.fiabilite < 2 ? "error" : intervenant.fiabilite > 3 ? 'success' : "warning"} />
                </Stack>
            </Stack>
            <Grid container spacing={1.5}>
                <Grid item xs={12}>
                    <Typography variant="h4">{intervenant.societe}</Typography>
                    <Typography variant="h6">{intervenant.specialite}</Typography>
                </Grid>
                <Grid item xs={10}>
                    <Box style={{ marginBottom: "2px" }}>{intervenant.prenom + " " + intervenant.nom}</Box>
                    <Box style={{ marginBottom: "2px", textTransform: "capitalize" }}>{intervenant.adresse}</Box>
                    <Box>{intervenant.postal + " " + intervenant.ville}</Box>
                </Grid>
                <Grid item xs={2}>
                    <Box style={{ marginBottom: "2px", display: "flex" }}>
                        <Box style={{ margin: "0 10px auto auto" }}>
                            {intervenant.mo + '€'}
                        </Box>
                        <BuildIcon color='primary' />
                    </Box>
                    <Box style={{ marginBottom: "2px", display: "flex" }}>
                        <Box style={{ margin: "0 10px auto auto" }}>
                            {intervenant.deplacement + '€'}
                        </Box>
                        <DirectionsCarIcon color='primary' />
                    </Box>
                    <Box style={{ marginBottom: "2px", display: "flex" }}>
                        <Box style={{ margin: "0 10px auto auto" }}>
                            {intervenant.forfait + '€'}
                        </Box>
                        <PaymentIcon color='primary' />
                    </Box>
                </Grid>
                <Grid item xs={5}>
                    <a href={"mailto:" + intervenant.mail[0]} style={{ color: "#5468AE", textDecoration: "none", display: "flex", width: "fit-content" }}>
                        <Stack direction={"row"} spacing={1}>
                            <MailIcon />
                            <Box>{intervenant.mail[0]}</Box>
                        </Stack>
                    </a>
                    <Stack direction={"row"} spacing={1}>
                        <PhoneIcon color='primary' />
                        <Box>{intervenant.telephone === "+33" ? "" : intervenant.telephone}</Box>
                    </Stack>
                </Grid>
                <Grid item xs={5}>
                    <a href={"mailto:" + intervenant.mail[1]} style={{ color: "#5468AE", textDecoration: "none", display: "flex", width: "fit-content" }}>
                        <Stack direction={"row"} spacing={1}>
                            <MailIcon />
                            <Box>{intervenant.mail[1]}</Box>
                        </Stack>
                    </a>
                    <Stack direction={"row"} spacing={1}>
                        <PhoneIcon color='primary' />
                        <Box>{intervenant.telephone_fixe === "+33" ? "" : intervenant.telephone_fixe}</Box>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction={"row"} spacing={1}>
                        <NoteAltIcon color='primary' />
                        <Typography color='warning' variant="h6">{intervenant.commentaire}</Typography>
                    </Stack>
                </Grid>
                <Grid item xs={12}>
                    <Stack direction="row" spacing={1} justifyContent="space-between">
                        <Stack direction="row" spacing={1}>
                            <Button size="small" variant="outlined" onClick={() => openModalModifyIntervenant(intervenant)}>
                                Modifier
                            </Button>
                            {intervenantionView ?
                                <Button size="small" variant="contained" onClick={() => selectIntervenant(intervenant)}>
                                    Sélectionner
                                </Button> : null
                            }
                        </Stack>
                        <Button size="small" variant="outlined" onClick={() => localisateIntervenant(intervenant)} startIcon={<TrackChangesIcon />}>
                            Localiser
                        </Button>
                    </Stack>
                </Grid>
            </Grid>
        </IntervenantCardStyle>
    )
})

const DisplayIntervenant = ({ filteredIntervenant, selectIntervenant, openModalModifyIntervenant, intervenantionView, localisateIntervenant }) => {
    const [hasMore, setHasMore] = useState(true);
    const [itemsPerPage, setItemsPerPage] = useState(20);

    useEffect(() => {
        if (itemsPerPage >= filteredIntervenant.length) {
            setHasMore(false);
        } else {
            setHasMore(true);
        }
    }, [filteredIntervenant]);

    const loadMore = () => {
        if (itemsPerPage >= filteredIntervenant.length) {
            setHasMore(false);
        } else {
            setTimeout(() => {
                setItemsPerPage(itemsPerPage + 20);
            }, 2000);
        }
    };

    const showIntervenants = () => {
        var intervenants = [];
        for (var i = 0; i < itemsPerPage; i++) {
            if (filteredIntervenant.length > i) {
                intervenants.push(
                    <IntervenantCards
                        key={filteredIntervenant[i].ID}
                        intervenant={filteredIntervenant[i]}
                        selectIntervenant={selectIntervenant}
                        openModalModifyIntervenant={openModalModifyIntervenant}
                        intervenantionView={intervenantionView}
                        localisateIntervenant={localisateIntervenant}
                    />
                );
            }
        }
        return intervenants;
    };

    return (
        <Grid item xs={4} style={{ height: "calc(100% - (5vh + 10px))", overflowY: "auto" }}>
            {filteredIntervenant.length > 0 ?
                <InfiniteScroll
                    pageStart={0}
                    loadMore={loadMore}
                    hasMore={hasMore}
                    loader={<LoaderStyle key={0} variant='h4'><CircularProgress key={0} color="secondary" size={20} /></LoaderStyle>}
                    useWindow={false}
                >
                    {showIntervenants()}
                </InfiniteScroll>
                : null}

        </Grid>
    )
}

export default DisplayIntervenant

