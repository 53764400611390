import React from 'react'
import Popover from '@mui/material/Popover';
import {
    Button,
    Typography,
    Stack,
    Box,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import ContactMailIcon from '@mui/icons-material/ContactMail';
import MailIcon from '@mui/icons-material/Mail';
import PhoneIcon from '@mui/icons-material/Phone';
import BusinessIcon from '@mui/icons-material/Business';
import PersonIcon from '@mui/icons-material/Person';

// ----------------------------------------------------------------------

const StyledButton = styled(Button)(() => ({
    width: '120px',
    textOverflow: "ellipsis",
    whiteSpace: "nowrap",
    overflow: "hidden",
    textAlign: "left",
}));

// ----------------------------------------------------------------------

const IntervenantPopover = ({ intervenant }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);
    const id = open ? 'simple-popover' : undefined;

    return (
        <React.Fragment>
            <StyledButton
                variant="text"
                size='small'
                startIcon={<ContactMailIcon />}
                onClick={handleClick}
                style={{ justifyContent: 'left' }}
            >
                {intervenant.societe}
            </StyledButton>
            <Popover
                id={id}
                open={open}
                anchorEl={anchorEl}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'top',
                    horizontal: 'center',
                }}
                transformOrigin={{
                    vertical: 'bottom',
                    horizontal: 'center',
                }}
            >
                <Stack spacing={1} sx={{ p: 2 }}>
                    <Stack direction="row" spacing={1}>
                        <BusinessIcon color='primary' />
                        <Typography variant="h6">{intervenant.societe}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <PersonIcon color='primary' />
                        <Typography>{`${intervenant.nom} ${intervenant.prenom}`}</Typography>
                    </Stack>
                    <Stack direction="row" spacing={1}>
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <PhoneIcon color='primary' />
                                <Typography>{intervenant.telephone}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <PhoneIcon color='primary' />
                                <Typography>{intervenant.telephone_fixe}</Typography>
                            </Stack>
                        </Box>
                        <Box>
                            <Stack direction="row" spacing={1}>
                                <MailIcon color='primary' />
                                <Typography>{intervenant.mail[0]}</Typography>
                            </Stack>
                            <Stack direction="row" spacing={1}>
                                <MailIcon color='primary' />
                                <Typography>{intervenant.mail[1]}</Typography>
                            </Stack>
                        </Box>
                    </Stack>

                </Stack>

            </Popover>
        </React.Fragment>
    );
}

export default IntervenantPopover