import React from 'react';

// LIB
import { useSnackbar } from 'notistack';

// MUI
import { IconButton } from '@mui/material';

// styles
import { TruncatedText, ButtonOpen } from '../UI/DisplayAttachment/DisplayAttachmentStyle';

interface OpenFileProps {
    icon: React.ReactNode;
    url?: string;
    base64_file?: string;
    attachment?: boolean;
}

const OpenFile: React.FC<OpenFileProps> = ({ icon, url, base64_file, attachment }) => {
    const { enqueueSnackbar } = useSnackbar();

    const onClick = () => {
        if (base64_file) {
            if (base64_file.trim().length > 0) {
                try {
                    const base64String = base64_file.includes(',') ? base64_file.split(',')[1] : base64_file;
                    const binaryString = window.atob(base64String);
                    const bytes = new Uint8Array(binaryString.length);
                    for (let i = 0; i < binaryString.length; i++) {
                        bytes[i] = binaryString.charCodeAt(i);
                    }
                    const blob = new Blob([bytes], { type: 'application/pdf' });
                    const fileURL = URL.createObjectURL(blob);

                    window.open(fileURL, '_blank', 'noopener,noreferrer');
                } catch (error) {
                    console.error("Error decoding base64 string:", error);
                    enqueueSnackbar("Erreur lors de la décodage du fichier base64", { variant: 'error' });
                }
            } else {
                enqueueSnackbar("La chaîne base64 est vide", { variant: 'error' });
            }
        } else if (url) {
            window.open(url, '_blank', 'noopener,noreferrer');
        } else {
            enqueueSnackbar("Erreur lors de la récupération du fichier", { variant: 'error' });
        }
    };

    return attachment ? (
        <ButtonOpen
            size='small'
            onClick={onClick}
            variant="outlined"
        >
            <TruncatedText>{attachment}</TruncatedText>
        </ButtonOpen>
    ) : (
        <IconButton
            aria-label="file"
            size="small"
            onClick={onClick}
            color="primary"
        >
            {icon}
        </IconButton>
    );
};

export default OpenFile;
