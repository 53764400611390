import React from 'react';
import { useNavigate } from 'react-router-dom';

// Types
import { Module } from 'views/ModuleDevis/types';

// Styles
import { CardHover } from 'views/ModuleDevis/ModuleDevisStyle';
import ModuleDevisCard from 'components/UI/ModuleDevis/ModuleDevisCard';
import { CardModuleProps } from './types';

interface ModuleWithStringFields extends Omit<Module, 'quantite' | 'prixCorrectiveDefaut' | 'prixPreventiveDefaut'> {
    quantite: string;
    prixCorrectiveDefaut: string;
    prixPreventiveDefaut: string;
}

const CardModule: React.FC<CardModuleProps> = ({
    mode,
    onAddRow,
    moduleDevis
}) => {

    const navigate = useNavigate();

    const handleClick = (event: React.MouseEvent<HTMLDivElement>) => {
        event.stopPropagation();

        if (mode === 'redirect') {
            navigate(`/module-devis/module-details/${moduleDevis.ID}`, { state: { moduleDevis } });
        } else if (mode === 'addRow' && onAddRow) {
            let prixCorrectiveDefaut = moduleDevis.prixCorrectiveDefaut?.toString() || '0';
            let prixPreventiveDefaut = moduleDevis.prixPreventiveDefaut?.toString() || '0';

            if (moduleDevis.moduleDevisSites && Array.isArray(moduleDevis.moduleDevisSites)) {
                moduleDevis.moduleDevisSites.forEach((site) => {
                    if (site.prixCorrective !== undefined && site.prixPreventive !== undefined) {
                        prixCorrectiveDefaut = site.prixCorrective?.toString() || prixCorrectiveDefaut;
                        prixPreventiveDefaut = site.prixPreventive?.toString() || prixPreventiveDefaut;
                    }
                });
            }

            const moduleDevisWithStrings: ModuleWithStringFields = {
                ...moduleDevis,
                quantite: moduleDevis.quantite?.toString() || '0',
                prixCorrectiveDefaut,
                prixPreventiveDefaut,
            };
            onAddRow(moduleDevisWithStrings as any);
        }
    };

    return (
        <CardHover key={moduleDevis.ID} onClick={handleClick} >
            <ModuleDevisCard moduleDevis={moduleDevis} />
        </CardHover>
    );
};

export default CardModule;
