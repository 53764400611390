import React from 'react';
// import { connect } from 'react-redux';

import PropTypes from 'prop-types';
// date
import { dateConfig } from 'config'
import moment from 'moment';
import 'moment/locale/fr';
// material
import {
    TableRow,
    TableBody,
    TableCell,
    Tooltip,
    Button,
} from '@mui/material';
// components
import Status from 'components/UI/Status';
import DetailModal from './DetailModal';
import IconButton from '@mui/material/IconButton';
import DeleteIcon from '@mui/icons-material/Delete';

// ----------------------------------------------------------------------

DisplayBody.propTypes = {
    rowsPerPage: PropTypes.number,
    filteredTable: PropTypes.array,
};

export function DisplayBody({ filteredTable, rowsPerPage, page, handleModal, putDemandeInterventionInCorbeille }) {
    return (
        <TableBody sx={{
            whiteSpace: { xs: "nowrap", xl: "normal" }
        }}>
            {filteredTable
                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                .map((row, index) => {
                    const { status, enseigne, objet, numero_magasin, code_postal, ville, adresse, initial_request, demande_par } = row;
                    const date_demande = moment(row.date_demande).format(dateConfig.format1)
                    return (
                        <TableRow
                            key={index}
                            hover
                            tabIndex={-1}
                        >
                            <TableCell align="center">{
                                <DetailModal intervention={row} />
                            }
                            </TableCell>
                            <TableCell align="center">{demande_par}</TableCell>
                            <TableCell align="center">{enseigne}</TableCell>
                            <TableCell align="center">{adresse + " " + ville + " " + code_postal + " (n° " + numero_magasin + ")"}</TableCell>
                            <TableCell align="center">{objet}</TableCell>
                            {
                                initial_request.length > 60 ?
                                    <TableCell align="center">
                                        <Tooltip title={initial_request}>
                                            <div>
                                                {initial_request.substring(0, 60) + "..."}
                                            </div>
                                        </Tooltip>
                                    </TableCell>
                                    : <TableCell align="center">{initial_request}</TableCell>
                            }
                            <TableCell align="center">{date_demande}</TableCell>
                            <TableCell align="center">
                                <Status ownerState={status} />
                            </TableCell>
                            <TableCell align="center">
                                <Button onClick={() => handleModal(row)} style={{ backgroundColor: "#1bb31b", color: "white" }} variant="contained" fullWidth>
                                    Accepter
                                </Button>

                            </TableCell>
                            <TableCell align="center">
                                <IconButton aria-label="delete" size="small" onClick={() => putDemandeInterventionInCorbeille(row)}>
                                    <DeleteIcon fontSize="inherit" sx={{ color: 'red' }}/>
                                </IconButton>
                            </TableCell>
                        </TableRow>
                    );
                })}
        </TableBody >
    );
}
