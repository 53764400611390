import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PublishIcon from '@mui/icons-material/Publish';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Spinner from '../../../UI/Spinner/Spinner';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import { styled } from '@mui/material/styles';

import { b64toBlob } from 'utils/handleFiles';

// Fonction alert pour error request
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

const useStyles = styled({
    table: {
        minWidth: 650,
    },
    Icon: {
        color: "#FF0000",
        cursor: "pointer",
        '&:hover': {
            color: "#DD0000",
        }
    },
    IconImage: {
        color: "#316690",
        cursor: "pointer",
        '&:hover': {
            color: "#316690",
        }
    },
    PreviewStyle: {
        display: 'inline',
        width: '200px',
        height: '200px',
        paddingBottom: 10,
        transition: 'transform 2.2s',
        marginLeft: 'auto',
        marginRight: 'auto',
    },
    PreviewStyleDialog: {
        display: 'inline',
        width: '200px',
        height: '200px',
        transition: 'transform 2.2s',
        marginLeft: 'auto',
        marginRight: 'auto',
        border: "1px solid black",
    },
    IconPublish: {
        color: "#349034ed",
        cursor: "pointer",
        '&:hover': {
            color: "#1b5e20",
        }
    },
    IconEdit: {
        color: "#316690",
        cursor: "pointer",
        '&:hover': {
            color: "#1e3e56",
        }
    },
});
const mockData = [
    {
        ID: 1,
        Name: "Éclairage Principal",
        Zone: "Entrée",
        Activite: "Vérification",
        Objet: "Système d'éclairage",
        Photo: "eclairage1.png",
        Marque: "Philips",
        Type: "LED",
        Reference: "LED-001",
        Commentaire: "Fonctionne parfaitement",
        File_id: 101,
        Quantite: 20,
        Etats: 1,
        Conformité: 1,
        Criticité: 2,
        Maintenabilité: 3,
    },
    {
        ID: 2,
        Name: "Climatisation",
        Zone: "Salle de Vente",
        Activite: "Entretien",
        Objet: "Système de climatisation",
        Photo: "climatisation1.png",
        Marque: "Daikin",
        Type: "Split",
        Reference: "DA-CLIM-002",
        Commentaire: "Nettoyage des filtres effectué",
        File_id: 102,
        Quantite: 5,
        Etats: 1,
        Conformité: 1,
        Criticité: 1,
        Maintenabilité: 2,
    },
    {
        ID: 3,
        Name: "Système de Sécurité",
        Zone: "Entrée",
        Activite: "Inspection",
        Objet: "Caméra de sécurité",
        Photo: "camera1.png",
        Marque: "Hikvision",
        Type: "CCTV",
        Reference: "CCTV-003",
        Commentaire: "Aucune anomalie détectée",
        File_id: 103,
        Quantite: 10,
        Etats: 1,
        Conformité: 1,
        Criticité: 3,
        Maintenabilité: 2,
    },
    {
        ID: 4,
        Name: "Rayonnage",
        Zone: "Stock",
        Activite: "Réparation",
        Objet: "Étagères",
        Photo: "rayonnage1.png",
        Marque: "Ikea",
        Type: "Métal",
        Reference: "IK-RAY-004",
        Commentaire: "Réparations mineures nécessaires",
        File_id: 104,
        Quantite: 15,
        Etats: 2,
        Conformité: 1,
        Criticité: 2,
        Maintenabilité: 1,
    },
];

export default function DenseTable(props) {
    const classes = useStyles();
    const [rows, setRows] = React.useState(mockData);
    const [openDialog, setOpenDialog] = React.useState(false);
    const [dataInventaire, setDataInventaire] = React.useState("");
    const [srcImageInv, setSrcImageInv] = React.useState("");
    const [spinner, setSpinner] = React.useState(false);
    // Alert Erreur request
    const [openAlertErrorRequest, setOpenAlertErrorRequest] = React.useState(false);
    const [alertErrorRequest, setAlertErrorRequest] = React.useState(null);

    // <-- Function Alert error Request -->

    const handleCloseAlertErrorRequest = (event, reason) => {
        setOpenAlertErrorRequest(false);
        setAlertErrorRequest(null);
    }

    // <-- End Function Alert error Request -->

    const showImage = (event, row) => {
        let blob;
        if (row.Photo.includes(".png") || row.Photo.includes(".jpg") || row.Photo.includes(".jpeg") ||
            row.Photo.includes(".PNG") || row.Photo.includes(".JPG") || row.Photo.includes(".JPEG")) {
            blob = b64toBlob(row.Photo, 'image/png;base64');
        } else if (row.Photo.toLowerCase().includes(".pdf")) {
            blob = b64toBlob(row.Photo, "application/pdf");
        }
        let url = URL.createObjectURL(blob);
        window.open(url);
    }

    const addToInventaire = (check) => {
        if (check === "cancel") {
            setOpenDialog(false);
            setDataInventaire("");
            setSrcImageInv("");
        } else {
            setSpinner(true);
            let formData = new FormData();
            if (dataInventaire.Photo.preview)
                formData.append('files', dataInventaire.Photo);
            else
                formData.append('imgBase64', dataInventaire.Photo);
            formData.append('nameFiles', dataInventaire.Name);
            formData.append('zone', dataInventaire.Zone);
            formData.append('specialite', dataInventaire.Activite);
            formData.append('objet', dataInventaire.Objet);
            formData.append('NumeroMagasin', props.NumMag);
            formData.append('Enseigne', props.Enseigne);
            formData.append('Attribut', "Inventaires");
            axios.defaults.withCredentials = true;
            axios.post('/legacy/addInventaires', formData)
                .then(_ => {
                    setOpenDialog(false);
                    setDataInventaire("");
                    setSrcImageInv("");
                    setSpinner(false);
                })
                .catch(error => {
                    setOpenAlertErrorRequest(true);
                    setAlertErrorRequest(<Alert onClose={handleCloseAlertErrorRequest} severity="error">
                        Erreur ajout photo dans l'inventaire!
                    </Alert>);
                    setSpinner(false);
                });
        }
    }

    const openDialogInventaire = (_, row) => {
        setDataInventaire(row);
        setOpenDialog(true);
        let srcImg = "data:image/*;base64, " + row.Photo;
        if (row.Photo.preview) {
            setSrcImageInv(<img src={row.Photo} alt="Preview" className={classes.PreviewStyleDialog} />);
        } else {
            setSrcImageInv(<img src={srcImg} alt="Preview" className={classes.PreviewStyleDialog} />);
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <Snackbar open={openAlertErrorRequest} autoHideDuration={6000} onClose={handleCloseAlertErrorRequest}>
                {alertErrorRequest}
            </Snackbar>
            <Dialog open={openDialog} onClose={() => addToInventaire("cancel")} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter à l'inventaire</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        Voulez-vous vraiment ajouter cette photo à l'inventaire du magasin ?
                    </DialogContentText>
                    {spinner ? <Spinner /> : null}
                    {spinner ? null : <div style={{ display: "flex" }}>
                        {srcImageInv}
                    </div>}
                </DialogContent>
                {spinner ? null :
                    <DialogActions>
                        <Button onClick={() => addToInventaire("cancel")} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => addToInventaire("save")} color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <TableContainer component={Paper} style={{ maxHeight: "330px", minHeight: "70px" }}>
                <Table className={classes.table} size="small" aria-label="a dense table">
                    <TableHead >
                        <TableRow>
                            <TableCell>ID</TableCell>
                            <TableCell>Name</TableCell>
                            <TableCell>Zone</TableCell>
                            <TableCell>Activite</TableCell>
                            <TableCell>Objet</TableCell>
                            <TableCell>Photo</TableCell>
                            <TableCell>Marque</TableCell>
                            <TableCell>Type</TableCell>
                            <TableCell>Reference</TableCell>
                            <TableCell>Commentaire</TableCell>
                            <TableCell>File ID</TableCell>
                            <TableCell>Quantite</TableCell>
                            <TableCell>Etats</TableCell>
                            <TableCell>Conformité</TableCell>
                            <TableCell>Criticité</TableCell>
                            <TableCell>Maintenabilité</TableCell>
                            {props.removeFile ? <TableCell align="right" style={{ width: "50px", padding: "5px 5px" }}></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.ID}>
                                <TableCell>{row.ID}</TableCell>
                                <TableCell>{row.Name}</TableCell>
                                <TableCell>{row.Zone}</TableCell>
                                <TableCell>{row.Activite}</TableCell>
                                <TableCell>{row.Objet}</TableCell>
                                <TableCell>
                                    <ImageIcon onClick={(event) => showImage(event, row)} className={classes.IconImage} />
                                </TableCell>
                                <TableCell>{row.Marque}</TableCell>
                                <TableCell>{row.Type}</TableCell>
                                <TableCell>{row.Reference}</TableCell>
                                <TableCell>{row.Commentaire}</TableCell>
                                <TableCell>{row.File_id}</TableCell>
                                <TableCell>{row.Quantite}</TableCell>
                                <TableCell>{row.Etats}</TableCell>
                                <TableCell>{row.Conformité}</TableCell>
                                <TableCell>{row.Criticité}</TableCell>
                                <TableCell>{row.Maintenabilité}</TableCell>
                                {props.removeFile ? (
                                    <>
                                        <TableCell align="center" title="Ajouter à l'inventaire" style={{ padding: "5px 5px" }}>
                                            <PublishIcon onClick={(event) => openDialogInventaire(event, row)} className={classes.IconPublish} />
                                        </TableCell>
                                        <TableCell align="center" title="Supprimer la photo" style={{ padding: "5px 5px" }}>
                                            <DeleteIcon onClick={(event) => props.removeFile(event, row)} className={classes.Icon} />
                                        </TableCell>
                                    </>
                                ) : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </div>
    );
}

