import React from "react";
import { Box, IconButton, TextField } from "@mui/material";
import AddIcon from "@mui/icons-material/Add";
import RemoveIcon from "@mui/icons-material/Remove";

const QuantityInput = ({ value, onChange, max, onMaxValueReached }) => {
  const handleIncrement = () => {
    if (value < max) {
      onChange(value + 1);
    } else {
      console.log("Max value reached, cannot increment further");
    }
  };

  const handleDecrement = () => {
    if (value > 0) {
      onChange(value - 1);
    } else {
      console.log("Value is already at minimum, cannot decrement further");
    }
  };

  return (
    <Box display="flex" alignItems="center">
      <IconButton onClick={handleDecrement}>
        <RemoveIcon />
      </IconButton>
      <TextField
        value={value}
        onChange={(e) => {
          const newValue = parseInt(e.target.value, 10);
          if (!isNaN(newValue) && newValue >= 0 && newValue <= max) {
            onChange(newValue);
          } else {
            onMaxValueReached();
          }
        }}
        type="number"
        inputProps={{ min: 0, max: max, style: { textAlign: "center" } }}
        sx={{ width: "60px", mx: 1 }}
      />
      <IconButton onClick={handleIncrement}>
        <AddIcon />
      </IconButton>
    </Box>
  );
};

export default QuantityInput;
