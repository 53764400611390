
export default function Tables(theme) {
    return {
        MuiTableSortLabel: {
            styleOverrides: {
                root: {
                    "&:hover": {
                        color: theme.palette.primary.dark,
                    },
                },
                icon: {
                    "&:hover": {
                        color: theme.palette.primary.dark,
                    },
                }
            }
        },
        MuiTableCell: {
            styleOverrides: {
                head: {
                    height: "40px",
                    padding: "5px 10px",
                    fontWeight: "bold",
                    background: "rgb(215 229 249)",
                    whiteSpace: "nowrap",
                    color: theme.palette.primary.main
                },
            }
        },
    };
}
