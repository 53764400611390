// @mui
import { Skeleton } from '@mui/material';

// ----------------------------------------------------------------------

export default function SkeletonRectangular({ width, height }) {
    return (
        <Skeleton variant="rectangular" width={width} height={height} animation="wave" sx={{ borderRadius: 2 }} />
    );
}
