import React from 'react';
import { openFile } from "utils/handleFiles";
import IconButton from '@mui/material/IconButton';
import Iconify from './Iconify';
import Divider from '@mui/material/Divider';

import {
    List,
    Button,
    ListItem,
    ListItemIcon,
} from '@mui/material';

const thumbsContainer = {
    display: 'flex',
    flexDirection: 'column',
    overflowX: "scroll",
    maxHeight: "200px",
    width: "100%",
};

export default function PreviewDropzone({ files, type, onRemove }) {
    const thumbs = files.map((file, index) => {
        if (type === "pdf") {
            return (
                <List key={index} sx={{ padding: "0px 10px 0px 10px" }} >
                    <Divider />
                    <ListItem
                        secondaryAction={
                            <IconButton edge="end" aria-label="delete" onClick={() => onRemove(file)}>
                                <Iconify icon={'material-symbols:cancel-rounded'} sx={{ fontSize: "20px", color: "#0f0e0eab" }} />
                            </IconButton>
                        }
                    >
                        <ListItemIcon>
                            <Button variant="text" fullWidth onClick={() => openFile(file.file, 'application/pdf')} startIcon={<Iconify icon={'ant-design:folder-open-twotone'} sx={{ fontSize: "25px" }} />}>
                                {file.name}
                            </Button>
                        </ListItemIcon>
                    </ListItem>
                </List>
            )
        }
    });

    return (
        <div style={thumbsContainer}>
            {thumbs}
        </div>
    );
}
