import axios from 'axios'
import {
    GET_ALL_ENSEIGNE,
    GET_MAGASIN_BY_ENSEIGNE,
    GET_CONTACT_BY_CLIENT,
    GET_ALL_CLIENT,
} from "./CONSTANTS";


export const GetAllEnseigne = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_ENSEIGNE())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllEnseigne > axios err=", err);
                    reject("Error in GetAllEnseigne axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetAllEnseigne, Err===", error);
        }
    });
};

export const GetMagasinByEnseigne = (enseigne) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_MAGASIN_BY_ENSEIGNE(enseigne))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in get magasin by enseigne axios!");
                });
        } catch (error) {
            console.error("Error in get magasin by enseigne axios!", error);
        }
    });
};

export const GetContactByClient = (client) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_CONTACT_BY_CLIENT(client))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in get contact by enseigne axios!");
                });
        } catch (error) {
            console.error("Error in get contact by enseigne axios!", error);
        }
    });
};


export const GetAllClient = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_ALL_CLIENT())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("GetAllClient > axios err=", err);
                    reject("Error in GetAllClient axios!");
                });
        } catch (error) {
            console.error("in siteServices > GetAllClient, Err===", error);
        }
    });
};