import React, { useState } from 'react'


const RelanceContext = React.createContext({
    isAddRelance : false,
    isTreatRelance : false,
    onAddRelance : () => {},
    onTreatRelance : () => {},
});

export const RelanceContextProvider = (props) => {

    const [ addRelance, setAddRelance] = useState(false);
    const [ treatRelance, setTreatRelance] = useState(false);

    const addRelanceHandler = () => {
        setAddRelance(!addRelance)
    }

    const treatRelanceHandler = () => {
        setTreatRelance(!treatRelance)
    }


    return <RelanceContext.Provider value={{ onAddRelance : addRelanceHandler, onTreatRelance : treatRelanceHandler, 
    isAddRelance : addRelance, isTreatRelance : treatRelance}}>
        {props.children}
    </RelanceContext.Provider>
}

export default RelanceContext 