import React, { useState, useCallback, useMemo } from 'react';
import { Box, Table, TableContainer } from '@mui/material';
import { Module } from '../types';
import { useNavigate } from 'react-router-dom';
import ModuleDevisTableHead from './ModuleDevisTableHead';
import ModuleDevisTableBody from './ModuleDevisTableBody';

interface ModuleWithStringFields extends Omit<Module, 'quantite' | 'prixCorrectiveDefaut' | 'prixPreventiveDefaut'> {
    quantite: string;
    prixCorrectiveDefaut: string;
    prixPreventiveDefaut: string;
}

interface ModuleDevisTableProps {
    modules: Module[];
    tableHeight?: string;
    mode: 'redirect' | 'addRow';
    onAddRow?: (moduleDevis: Module) => void;
}

const ModuleDevisTable: React.FC<ModuleDevisTableProps> = ({ mode, modules, onAddRow, tableHeight }) => {
    const navigate = useNavigate();

    const [order, setOrder] = useState<'asc' | 'desc'>('asc');
    const [orderBy, setOrderBy] = useState<keyof Module>('name');

    const tableCells: { id: keyof Module; label: string; width: string; type: string }[] = [
        { id: 'name', label: 'Nom', width: '10%', type: 'string' },
        { id: 'description', label: 'Description', width: '10%', type: 'string' },
        { id: 'specialite', label: 'Spécialité', width: '10%', type: 'string' },
        { id: 'unite', label: 'Unité', width: '5%', type: 'string' },
        { id: 'quantite', label: 'Quantité', width: '5%', type: 'number' },
        { id: 'prixCorrectiveDefaut', label: 'Prix corrective', width: '5%', type: 'number' },
        { id: 'prixPreventiveDefaut', label: 'Prix préventive', width: '5%', type: 'number' },
    ];

    const handleRequestSort = useCallback(
        (event: React.MouseEvent<unknown>, property: keyof Module) => {
            const isAsc = orderBy === property && order === 'asc';
            setOrder(isAsc ? 'desc' : 'asc');
            setOrderBy(property);
        },
        [order, orderBy]
    );

    const filteredAndSortedModules = useMemo(() => {
        let filteredModules = modules;

        return filteredModules.sort((a, b) => {
            const aValue = a[orderBy]?.toString().toLowerCase() || '';
            const bValue = b[orderBy]?.toString().toLowerCase() || '';
            if (order === 'asc') {
                return aValue > bValue ? 1 : -1;
            } else {
                return aValue < bValue ? 1 : -1;
            }
        });
    }, [modules, order, orderBy]);

    const handleRowClick = useCallback(
        (moduleDevis: Module) => {
            if (mode === 'redirect') {
                navigate(`/module-devis/module-details/${moduleDevis.ID}`, { state: { moduleDevis } });
            } else if (mode === 'addRow' && onAddRow) {
                let prixCorrectiveDefaut = moduleDevis.prixCorrectiveDefaut?.toString() || '0';
                let prixPreventiveDefaut = moduleDevis.prixPreventiveDefaut?.toString() || '0';

                if (moduleDevis.moduleDevisSites && Array.isArray(moduleDevis.moduleDevisSites)) {
                    moduleDevis.moduleDevisSites.forEach((site) => {
                        if (site.prixCorrective !== undefined && site.prixPreventive !== undefined) {
                            prixCorrectiveDefaut = site.prixCorrective?.toString() || prixCorrectiveDefaut;
                            prixPreventiveDefaut = site.prixPreventive?.toString() || prixPreventiveDefaut;
                        }
                    });
                }

                const moduleDevisWithStrings: ModuleWithStringFields = {
                    ...moduleDevis,
                    quantite: moduleDevis.quantite?.toString() || '0',
                    prixCorrectiveDefaut,
                    prixPreventiveDefaut,
                };
                onAddRow(moduleDevisWithStrings as any);
            }
        },
        [mode, navigate, onAddRow]
    );

    return (
        <Box padding={1}>
            <TableContainer sx={{ height: tableHeight, boxShadow: "rgba(100, 100, 111, 0.2) 0px 5px 15px 0px", borderRadius: "5px" }}>
                <Table stickyHeader size="medium">
                    <ModuleDevisTableHead
                        order={order}
                        orderBy={orderBy}
                        tableCells={tableCells}
                        onRequestSort={handleRequestSort}
                    />
                    <ModuleDevisTableBody
                        tableCells={tableCells}
                        onRowClick={handleRowClick}
                        modules={filteredAndSortedModules}
                    />
                </Table>
            </TableContainer>
        </Box>
    );
};

export default ModuleDevisTable;
