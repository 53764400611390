export const DEFAULT_LANGUAGE = "fr";
// Moment js date formats as per application requirements.

export const HEADER = {
  MOBILE_HEIGHT: 44,
  MAIN_DESKTOP_HEIGHT: 50,
  DASHBOARD_DESKTOP_HEIGHT: 92,
  DASHBOARD_DESKTOP_OFFSET_HEIGHT: 92 - 32,
};

export const NAVBAR = {
  DASHBOARD_WIDTH: 200,
  DASHBOARD_COLLAPSE_WIDTH: 50,
  //
  DASHBOARD_ITEM_ROOT_HEIGHT: 40,
  DASHBOARD_ITEM_SUB_HEIGHT: 40,
  DASHBOARD_ITEM_HORIZONTAL_HEIGHT: 32,
};

export const ICON = {
  NAVBAR_ITEM: 20,
  NAVBAR_ITEM_HORIZONTAL: 20,
};

export const dateConfig = {
  format1: "Do MMMM YYYY", // 1 Janvier 2022
  format2: "DD/MM", // shows date like 09/06
  format3: "DD/MM/YYYY", // day month year
  format4: "DD/MM/YYYY HH:mm", // day month year
  format5: "DD/MM/YYYY HH:mm:ss", // day month year
  format6: "DD MMM", // shows date like 09/Mai
  format7: "H:mm",
};

export const STATUTS_COLOR = {
  //STATUT INTERVENTION
  "EN COURS": "rgb(255, 152, 0)",
  Validée: "rgb(241, 201, 57)",
  TERMINEE: "rgb(255, 92, 92)",
  //STATUT ACTION
  "A PLANIFIER": "rgb(251, 198, 6)",
  "ATTENTE INTERVENANT": "rgb(205, 184, 255)",
  "ATTENTE CLIENT": "rgb(137, 99, 255, 89%)",
  "A EXPERTISER": "rgb(72, 72, 72)",
  "ATTENTE VALIDATION CLIENT": "rgb(255, 153, 65)",
  "A CHIFFRER": "#ef5350",
  "A SUIVRE": "rgb(33, 150, 243)",
  "A CLORE": "rgb(255, 98, 38)",
  CLOTUREE: "rgb(76, 175, 80)",
  ARCHIVEE: "rgb(137, 137, 137)",

  //STATUS FACTURE
  "A COMPLETER": "rgb(251, 198, 6)",
  "A VALIDER": "rgb(255, 92, 92)",
  "VALIDEE PILOTE": "rgb(255, 98, 38)",
  "A PAYER": "rgb(255, 159, 77)",
  URGENT: "rgb(255,0,0)",
  BLOQUEE: "rgb(128,128,128)",
  "A ENVOYER": "rgb(255, 159, 77)",
  FACTUREE: "rgb(105, 214, 214)",
  PAYEE: "rgb(113, 187, 76)",

    //STATUS DEMANDE DE DEVIS
    "Demande de devis": "#49495A",
    "Demande intervention": "#49495A",
    "Attente de validation": "#49495A",

    "À ENVOYER": "rgb(255, 159, 77)",
    "ENVOYÉ": "rgb(251, 198, 6)",
    "VALIDÉ": "rgb(113, 187, 76)",
    "REFUSÉ": "rgb(255, 92, 92)",
    "ARCHIVÉ": "rgb(137, 137, 137)",
}

export const STATUTS_DEVIS = {
  "À ENVOYER": "rgb(255, 159, 77)",
  ENVOYÉ: "rgb(251, 198, 6)",
  VALIDÉ: "rgb(113, 187, 76)",
  REFUSÉ: "rgb(255, 92, 92)",
  ARCHIVÉ: "rgb(137, 137, 137)",
};
