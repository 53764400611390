import { styled } from '@mui/material/styles';
import { Button } from '@mui/material';

export const CreateButton = styled(Button)(() => ({
    width: 'auto',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
    marginRight: "5px",
}))
 export const SaveButton = styled(Button)(() => ({
    height: '100%',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
 }))