import React from 'react';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogTitle from '@mui/material/DialogTitle';
import Button from '@mui/material/Button';

export default function RecapEmail(props) {
    const [resume] = React.useState(props.resume)

    let showResume = <p>Aucun mail à été envoyé pour le moment</p>
    if (resume.Resume) {
        showResume = resume.Resume.map(resumeMail => {
            return <p key={resumeMail}>{resumeMail}</p>
        })
    }

    return (
        <div style={{width: "120%"}}>
            <Dialog open={true} onClose={() => props.close()} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Toutes les {props.titre} envoyé</DialogTitle>
                <DialogContent>
                    <div style={{textAlign: "center"}}>{showResume}</div>
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.close()} color="primary">
                        Fermer
                    </Button>
                </DialogActions>
            </Dialog>
        </div>
    )
}
