import React, { createContext, useContext, useState } from 'react';
import ConfirmDialog from './ConfirmDialog';

const ConfirmDialogContext = createContext({
  openDialog: (content: string, onConfirm: () => void) => { },
  closeDialog: () => { }
});

export const useConfirmDialog = () => useContext(ConfirmDialogContext);

export const ConfirmDialogProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [dialogConfig, setDialogConfig] = useState({ open: false, dialogContent: "", onConfirm: () => { } });

  const openDialog = (content: string, onConfirm: () => void) => {
    setDialogConfig({ open: true, dialogContent: content, onConfirm });
  };

  const closeDialog = () => {
    setDialogConfig(prevConfig => ({ ...prevConfig, open: false }));
  };

  return (
    <ConfirmDialogContext.Provider value={{ openDialog, closeDialog }}>
      {children}
      <ConfirmDialog
        open={dialogConfig.open}
        onClose={closeDialog}
        onConfirm={() => {
          if (dialogConfig.onConfirm) {
            dialogConfig.onConfirm();
          }
          closeDialog();
        }}
        dialogContent={dialogConfig.dialogContent}
        disableButton={false} />
    </ConfirmDialogContext.Provider>
  );
};




