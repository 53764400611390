import axios from 'axios'
import {
    GET_REQUEST_INTERVENTION_CLIENT,
    GET_NB_REQUEST_INTERVENTION,
    POST_INTERVENTION_CLIENT,
    POST_INTERVENTION,
    PUT_REQUEST_INTERVENTION,
    GET_REQUEST_INTERVENTION_CLIENT_IN_CORBEILLE,
    GET_DATA_FOR_FICHE_INTERVENTION,
    GET_LINK_FICHE_INTERVENTION,
} from "./CONSTANTS";

export const GetResquestInterventionClient = (attachedPilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_REQUEST_INTERVENTION_CLIENT(attachedPilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("interventionByClient > axios err=", err);
                    reject("Error in interventionByClient axios!");
                });
        } catch (error) {
            console.error("in interventionServices > interventionByClient, Err===", error);
        }
    });
};

export const GetDemandeInterventionsInCorbeille = (attachedPilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_REQUEST_INTERVENTION_CLIENT_IN_CORBEILLE(attachedPilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("interventionByClient > axios err=", err);
                    reject("Error in interventionByClient axios!");
                });
        } catch (error) {
            console.error("in interventionServices > interventionByClient, Err===", error);
        }
    });
};

export const PostDemandeInterventionClient = (intervention) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(POST_INTERVENTION_CLIENT(), intervention)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("Demande intervention > axios err=", err);
                    reject("Error in Demande intervention axios!");
                });
        } catch (error) {
            console.error("in interventionService > Demande intervention Err===", error);
        }
    });
}

export const GetNbResquestInterventionClient = (attachedPilote) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_NB_REQUEST_INTERVENTION(attachedPilote))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("interventionByClient > axios err=", err);
                    reject("Error in interventionByClient axios!");
                });
        } catch (error) {
            console.error("in interventionServices > interventionByClient, Err===", error);
        }
    });
};

export const PostDemandeIntervention = (formData) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.post(POST_INTERVENTION(), formData)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("Demande intervention > axios err=", err);
                    reject("Error in Demande intervention axios!");
                });
        } catch (error) {
            console.error("in interventionService > Demande intervention Err===", error);
        }
    });
}

export const PutStatusDemandeIntervention = (status) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.put(PUT_REQUEST_INTERVENTION(), status)
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log("AccountInformation > axios err=", err);
                    reject("Error in AccountInformation axios!");
                });
        } catch (error) {
            console.error("in interventionServices > AccountInformation, Err===", error);
        }
    });
};


export const GetDataForFicheIntervention = (IDMastore) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_DATA_FOR_FICHE_INTERVENTION(IDMastore))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log(" GetDataForFicheIntervention > axios err=", err);
                    reject("Error GetDataForFicheIntervention axios!");
                });
        } catch (error) {
            console.error("in interventionServices > GetDataForFicheIntervention, Err===", error);
        }
    });
};


export const GetLinkFicheIntervention = (IDMastore) => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(GET_LINK_FICHE_INTERVENTION(IDMastore))
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    console.log(" GetLinkFicheIntervention > axios err=", err);
                    reject("Error GetLinkFicheIntervention axios!");
                });
        } catch (error) {
            console.error("in interventionServices > GetLinkFicheIntervention, Err===", error);
        }
    });
};