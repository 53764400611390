
export default function Checkbox(theme) {
    return {
        MuiCheckbox: {
            styleOverrides: {
                root: {
                    padding: theme.spacing(0),
                    '&.Mui-checked.Mui-disabled, &.Mui-disabled': {
                        color: theme.palette.action.disabled,
                    },
                },
            },
        },
        MuiFormControlLabel: {
            styleOverrides: {
                root: {
                    marginLeft: "0px",
                }
            }
        }
    };
}
