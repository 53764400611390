import { styled } from '@mui/material/styles';
import {
    Button,
} from '@mui/material';
import AddIcon from '@mui/icons-material/Add';

export const ButtonAdd = styled(Button)(() => ({
    width: 'auto',
    height: '100%',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
}))

export const CustomAddIcon = styled(AddIcon)(({theme}) => ({
    [theme.breakpoints.down('xl')]: {
        fontSize: "20px",
    },
    [theme.breakpoints.down(undefined)]: {
        fontSize: "18px",
    }
}))

export const DivButton = styled('div')(() =>({
    width: "100%",
    justifyContent: "center",
    alignItems: "center",
    display: "flex",
}))

export const SaveButton = styled(Button)(() => ({
    marginTop: '12px',
    height: '100%',
    background: 'white',
    justifyContent: "center",
    alignItems: "center",
}))