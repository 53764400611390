// @mui
import { Box, Typography, Stack } from '@mui/material';
// assets
import UploadIllustration from '../../../assets/DropZoneIllustration/illustration_upload';

// ----------------------------------------------------------------------

export default function BlockContent({ text }) {
    return (
        <Stack
            spacing={2}
            alignItems="center"
            justifyContent="center"
            direction='row'
            sx={{ width: 1, textAlign: { xs: 'center', md: 'left' } }}
        >
            <UploadIllustration sx={{ width: { md: 100, sm: 75, xs: 75 } }} />

            <Box sx={{ p: 1 }}>
                <Typography gutterBottom variant="h6">
                    {text}
                </Typography>
                <Typography variant="body2" sx={{ color: 'text.secondary' }}>
                    Déposez des fichiers ici ou cliquez pour parcourir votre appareil
                </Typography>
            </Box>
        </Stack>
    );
}
