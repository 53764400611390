import React, { useState, useEffect } from 'react';
import { connect } from 'react-redux';
import Stack from '@mui/material/Stack';
import TableUsers from 'components/BddUsers/TableUsers/TableUsers';
import { useSnackbar } from 'notistack';
import { GetAllUsers } from 'services';
import Page from 'components/UI/Page';

function BddUsers(props) {
    const { enqueueSnackbar } = useSnackbar();
    const [data, setData] = useState([])
    const [dateUpdate, setDataUpdate] = useState(false)

    useEffect(() => {
        Promise.all([
            GetAllUsers(),
        ]).then(([
            responseGetUsers,
        ]) => {
            setData(responseGetUsers.users)
        }).catch(err => {
            enqueueSnackbar("Erreur lors de la récuperation des utilisateurs", { variant: 'error' });
            console.log(err);
        });
        setDataUpdate(false)
    }, [dateUpdate])


    return (
        <Page title={"Compte Utilisateurs"}>
            <Stack sx={{ p: 0.5 }} spacing={0.5}>
                <TableUsers data={data} setDataUpdate={setDataUpdate} administrateur={props.administrateur} />
            </Stack>
        </Page>
    )
}

const mapStateToProps = state => {
    return {
        firstname: state.firstname,
        lastname: state.lastname,
        administrateur: state.administrateur,
    };
};

export default connect(mapStateToProps, null)(BddUsers);
