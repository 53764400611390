import React from 'react';

import Header from 'components/UI/Header/Header';

// mui
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import { Button, Stack, Typography, TextField } from '@mui/material';

export default function ModalInfosFiles(props) {
    const [open] = React.useState(props.openDataFile);
    const [dataInfos] = React.useState(props.dataFiles)
    const [rows, setRows] = React.useState([])

    React.useEffect(() => {
        if (dataInfos) {
            let newRows = []
            newRows = newRows.concat(dataInfos.map(data => {
                const fileName = data.name.replace(/(\.[^.]+)$/, (ext) => ext.toLowerCase());
                return {
                    name: fileName,
                    zone: "",
                    specialite: "",
                    objet: "",
                    periode: "",
                    photo: URL.createObjectURL(data),
                    file: data
                };
            }));
            setRows(newRows)
        }
    }, [dataInfos]);

    const form = rows.map((data, key) => {
        return (
            <Stack key={data + key} style={{ padding: '10px', border: '1px solid #000', borderRadius: '5px', marginBottom: '10px' }}>
                <TextField
                    name="name"
                    size='small'
                    margin="dense"
                    key={"name" + key}
                    variant="outlined"
                    value={rows[key].name}
                    label="Nom du fichier"
                    autoComplete="new-password"
                    InputLabelProps={{shrink : true}}
                    onChange={(event) => handleChange(event, key)}
                />
                <TextField
                    size='small'
                    name="objet"
                    label="Objet"
                    margin="dense"
                    variant="outlined"
                    key={"objet" + key}
                    value={rows[key].objet}
                    autoComplete="new-password"
                    InputLabelProps={{shrink : true}}
                    onChange={(event) => handleChange(event, key)}
                />
            </Stack>
        )
    })

    const handleChange = (event, key) => {
        let newRows = [...rows];
        if (event.target.name === "name") {
            let fileName = event.target.value;
            const lowerCaseFileName = fileName.replace(/(\.[^.]+)$/, (ext) => ext.toLowerCase());
            newRows[key].name = lowerCaseFileName;
        } else if (event.target.name === "objet") {
            newRows[key].objet = event.target.value;
        }
        setRows(newRows);
    }

    return (
        <Stack>
            <Dialog open={open} onClose={() => props.saveOrCancel("cancel", [])} aria-labelledby="form-dialog-title">
                <Header>Information(s) Document(s)</Header>
                <DialogContent>
                    <Typography variant='h6' style={{ marginBottom: "15px"}}>
                        Mettez à jour les informations concernant le(s) document(s) que vous venez d'ajouter.
                    </Typography>
                    {form}
                </DialogContent>
                <DialogActions>
                    <Button onClick={() => props.saveOrCancel("cancel", [])} color="primary">
                        Annuler
                    </Button>
                    <Button onClick={() => props.saveOrCancel("save", rows)} color="primary">
                        Mettre à jour
                    </Button>
                </DialogActions>
            </Dialog>
        </Stack>
    );
}