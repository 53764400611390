export default function TextField(theme) {
    return {
        MuiFormHelperText: {
            styleOverrides: {
                root: {
                    margin: '0px 0px 0px 10px',
                    fontSize: "10px",
                },
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    top: "3px",
                }
            }
        }

    };
}
