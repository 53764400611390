import React, { useCallback } from 'react'
import PropTypes from 'prop-types';
import { useDropzone } from 'react-dropzone'
import { useSnackbar } from 'notistack';
import BlockContent from './upload/BlockContent'
// mui
import { styled } from '@mui/material/styles';
import {
    Box,
} from '@mui/material';

Dropzone.propTypes = {
    children: PropTypes.node,
    icon: PropTypes.node,
    pdf: PropTypes.bool,
    image: PropTypes.bool,
    returnFunction: PropTypes.func,
    error: PropTypes.bool,
};

// ----------------------------------------------------------------------

const DropZoneStyle = styled('div')(({ theme }) => ({
    outline: 'none',
    padding: theme.spacing(1),
    borderRadius: theme.shape.borderRadius,
    backgroundColor: theme.palette.background.neutral,
    border: `1px dashed #DCDCDC`,
    '&:hover': { opacity: 0.72, cursor: 'pointer' },
}));


// ----------------------------------------------------------------------

const FILES = {
    pdf: {
        format: {
            'application/*': []
        },
        size: 2000000
    },
    image: {
        format: {
            'image/*': []
        },
        size: 500000
    },
}

export default function Dropzone({
    pdf,
    image,
    returnFunction,
    error,
    text,
}) {
    const { enqueueSnackbar } = useSnackbar();

    const onDrop = useCallback((acceptedFiles, rejectedFiles) => {
        rejectedFiles.forEach(file => {
            if (file.errors[0].code === "file-invalid-type") {
                enqueueSnackbar(`${file.file.name}: Type de fichier invalid`, { variant: 'warning' });
            } else {
                enqueueSnackbar(`${file.file.name}: Type de fichier invalid`, { variant: 'warning' });
            }
        });

        //Check Files size
        let finalFiles = []

        acceptedFiles.forEach(file => {
            if (file.type === "application/*") {
                if (file.size <= FILES.pdf.size) {
                    finalFiles.push(file)
                } else {
                    enqueueSnackbar(`${file.path}: Taille limite PDF de 2Mo !`, { variant: 'warning' });
                }
            } else {
                finalFiles.push(file)
            }
        })
        returnFunction(finalFiles)
    },[enqueueSnackbar, returnFunction]);

    let formatAccept
    formatAccept = pdf ? { ...formatAccept, ...FILES.pdf.format } : { ...formatAccept }
    formatAccept = image ? { ...formatAccept, ...FILES.image.format } : { ...formatAccept }

    const { getRootProps, getInputProps, isDragActive, isDragReject } = useDropzone({
        onDrop,
        accept: {
            ...formatAccept
        }
    })

    return (
        <Box>
            <DropZoneStyle {...getRootProps()} sx={{
                ...(isDragActive && { opacity: 0.72 }),
                ...((isDragReject || error) && {
                    color: 'error.main',
                    borderColor: 'error.light',
                    bgcolor: 'error.lighter',
                }),
            }}>
                <input {...getInputProps()} />
                <BlockContent text={text} />
            </DropZoneStyle>
        </Box>
    )
}
