import React from 'react'
// mui
import {
    Alert,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import WarningAmberIcon from '@mui/icons-material/WarningAmber';
import PriorityHighIcon from '@mui/icons-material/PriorityHigh';
import { dateMaxAlert } from 'utils/date'

// ----------------------------------------------------------------------

const StyledAlert = styled(Alert)(({ theme, severity }) => ({
    border: `1px solid ${theme.palette[severity].main}`,
    '& .MuiAlert-message': {
        padding: 0,
        margin: "auto",
    },
    '& .MuiAlert-icon': {
        padding: 0
    },
    '& .MuiSvgIcon-root': {
        margin: 'auto',
    }
}));

// ----------------------------------------------------------------------

export default function AlerteDateMax({ date_max }) {
    const alert = dateMaxAlert(date_max)

    switch (alert) {
        case 'error':
            return (<StyledAlert icon={<WarningAmberIcon fontSize="inherit" />} severity="error">Délai dépassé</StyledAlert>)
        case 'warning':
            return (<StyledAlert icon={<WarningAmberIcon fontSize="inherit" />} severity="warning">Délai très proche </StyledAlert>)
        case 'info':
            return (<StyledAlert severity="info">Délai proche</StyledAlert>)
        case 'urgent':
            return (<StyledAlert icon={<PriorityHighIcon fontSize="inherit" />} severity="error">Dernier délai</StyledAlert>)
        default:
            return null
    }
}
