import React from 'react';
//mui
import { Stack } from '@mui/material';
//components
import Scrollbar from 'components/UI/Scrollbar';
import { b64toBlob } from 'utils/handleFiles';


const DemandeInitial = ({ fileDemandeInitial }) => {

    const createCompteRendu = (file) => {
        if (file.Name.includes(".msg")) {
            return (
                <div key={file.Name} style={{ color: 'blue', textDecoration: 'underline' }}>
                    <a download={file.Name} href={"data:application/vnd.msoutlook;base64," + file.File}>{file.Name}</a>
                </div>
            )
        } else if (file.Name.includes(".eml")) {
            let blob = b64toBlob(file.File, "message/*")
            let href = URL.createObjectURL(blob);
            return (
                <div key={file.Name} style={{ color: 'blue', textDecoration: 'underline' }}>
                    <a href={href} download={file.Name}>{file.Name}</a>
                </div>
            )
        } else if (file.Name.includes(".pdf")) {
            let blob = b64toBlob(file.File, "application/pdf")
            let href = URL.createObjectURL(blob);
            return (
                <div key={file.Name} style={{ color: 'blue', textDecoration: 'underline' }} onClick={(event) => window.open(href)}>
                    {file.Name}
                </div>
            )
        } else {
            let blob = b64toBlob(file.File, "image/*")
            let href = URL.createObjectURL(blob);
            return (
                <div key={file.Name} style={{ color: 'blue', textDecoration: 'underline' }} onClick={(event) => window.open(href)}>
                    {file.Name}
                </div>
            )
        }
    }

    return (
        <Stack sx={{ height: '60px' }}>
            <Scrollbar>
                {fileDemandeInitial.map(file => (
                    createCompteRendu(file)
                ))}
            </Scrollbar>
        </Stack>
    );
}

export default DemandeInitial;