import React, { useEffect, useState, useContext } from 'react'
import { GetTaskByUser, PostTask, DeleteTask } from 'services';
import { useSnackbar } from 'notistack';
import { styled } from '@mui/material/styles';
// mui
import {
    Stack,
    TextField,
    Typography,
    Checkbox,
    Tooltip,
    Card,
} from '@mui/material';
// date
import { dateConfig } from 'config'
import { formatDate } from 'utils/date';
//coponents
import Label from "components/UI/Label";
// icone
import TodoListContext from 'contexts/TodoListContext';


const GridList = styled("div")(
    () => ({
        border: '0px solid rgb(196 196 196)',
        borderRadius: "10px",
        background: "white",
        height: '25px',
        maxWidth: '470px',
        width: "100%",
        display: "flex",
        alignItems: "center",
        padding: '10px',
    })
);

export const TodoList = ({ display }) => {
    const { enqueueSnackbar } = useSnackbar();
    const [TasksList, setTasksList] = useState([])
    const [inputTask, setInputTask] = useState('')
    const ctx = useContext(TodoListContext);

    useEffect(() => {
        Promise.all([
            GetTaskByUser(),
        ]).then(([
            responseGetTaskByUser,
        ]) => {
            setTasksList(responseGetTaskByUser.task)
        }).catch(err => {
            enqueueSnackbar("Erreur lors de la récupération des commentaires", { variant: 'error' });
            console.log(err);
        });
    }, [ctx.isAddedTask, ctx.idDeletedTask, enqueueSnackbar])

    const sendTask = (inputTask) => {
        if (inputTask.length === 0) {
            return
        }
        PostTask(inputTask).then(() => {
            ctx.onAddTask()
        }).catch(err => {
            enqueueSnackbar('error lors de la créeation de votre tache', {
                variant: 'error',
            });
            console.log(err);
        });

    }

    const DeleteTaskHandle = (e, id) => {
        DeleteTask(id).then(() => {
            enqueueSnackbar('Tâche suprimée', {
                variant: 'success',
            });
            ctx.onTaskDelete()
        }).catch((err) => {
            enqueueSnackbar('error lors de la supression de la tache', {
                variant: 'error',
            });
            console.log(err);
        })
    }

    const keyPress = (e) => {
        if (e.keyCode === 13) {
            if (inputTask.trim().length !== 0) {
                sendTask({ task: inputTask, time: new Date() })
            }
            setInputTask("")
        }
    }

    const displayList = (display) => {
        if (display === "Home") {
            return (
                <Card variant="outlined" sx={{ padding: "5px", width: "100%", overflow: "auto", height: 'auto' }}>
                    <Stack direction="column" sx={{ width: '100%' }} spacing={1}>
                        {TasksList.map((item, index) => (
                            <Stack key={index} direction="row" sx={{ width: '100%' }} justifyContent="space-between" alignItems="center">
                                <Stack direction="row" sx={{ width: '100%' }} spacing={1}>
                                    <Checkbox
                                        checked={false}
                                        value={item.ID}
                                        onChange={(e) => DeleteTaskHandle(e, item.ID)}
                                        inputProps={{ 'aria-label': 'controlled' }}
                                    />
                                    <Tooltip title={item.task}>
                                        <Typography> {item.task} </Typography>
                                    </Tooltip>
                                </Stack>
                                <div>
                                    <Label sx={{ mr: 0 }} color={"info"}> {formatDate(item.time, dateConfig.format6)} </Label>
                                </div>
                            </Stack>
                        ))}
                    </Stack>
                </Card>
            )
        }
        else {
            return (
                TasksList.map((item, index) => {
                    return (
                        <GridList key={index}>
                            <div style={{ width: "10%" }}>
                                <Checkbox
                                    value={item.ID}
                                    onChange={(e) => DeleteTaskHandle(e, item.ID)}
                                    inputProps={{ 'aria-label': 'controlled' }}
                                />
                            </div>
                            {item.task.length > 32 ?
                                <Tooltip title={item.task}>
                                    <div style={{ width: "70%", whiteSpace: "nowrap", overflow: "hidden" }}>
                                        <Typography> {item.task.slice(0, 32) + " ..."} </Typography>
                                    </div>
                                </Tooltip> :
                                <div style={{ width: "70%", whiteSpace: "nowrap", overflow: "hidden" }}>
                                    <Typography > {item.task} </Typography>
                                </div>
                            }
                            <div>
                                <Label sx={{ mr: 0 }} color={"info"}> {formatDate(item.time, dateConfig.format6)} </Label>
                            </div>
                        </GridList >
                    )
                })
            )
        }
    }

    return (

        display !== "Home" ?
            <Stack direction="column" width={"100%"} spacing={1} minWidth={"320px"}>
                <TextField value={inputTask} size="small" variant="outlined" placeholder="Nouvelle tâche"
                    onKeyDown={keyPress}
                    onChange={(event) => setInputTask(event.target.value)}
                    sx={{ backgroundColor: "white" }}
                />
                {displayList(display)}
            </Stack> : displayList(display)


    )
}
