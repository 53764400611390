import React from 'react';
import { Button, FormControlLabel, Typography, Stack } from '@mui/material';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import Radio from '@mui/material/Radio';
import RadioGroup from '@mui/material/RadioGroup';
import Header from 'components/UI/Header/Header';

export default function ModalEditInfosFiles(props) {
  const [open] = React.useState(props.openDataFile);
  const [dataInfos] = React.useState(props.dataFiles)
  const [rows, setRows] = React.useState([])
  const [editFile] = React.useState(props.dataFiles[0])

  React.useEffect(() => {
    if (dataInfos) {
      let newRows = []
      newRows = newRows.concat(dataInfos.map(data => Object.assign({
          name: data.name,
          zone: data.zone,
          specialite: data.specialite,
          objet: data.objet,
          periode: data.periode,
          photo: data.photo,
          file: data.file,
        })
      ))

      setRows(newRows)
    }
  }, [dataInfos]);

  const form = (rows.map((data, key) => {
    return (
      <Stack key={data + key} style={{padding: '10px', border: '1px solid #000', borderRadius: '5px', marginBottom: '10px'}}>
        <TextField
          key={"name" + key}
          margin="dense"
          label="Nom du fichier"
          name="name"
          size='small'
          InputLabelProps={{shrink:true}}
          value={rows[key].name}
          fullWidth
          variant="outlined"
          onChange={(event) => handleChange(event, key)}
        />
        <TextField
          key={"zone" + key}
          margin="dense"
          label="Zone"
          name="zone"
          size='small'
          InputLabelProps={{shrink:true}}
          value={rows[key].zone}
          fullWidth
          variant="outlined"
          onChange={(event) => handleChange(event, key)}
        />
        <TextField
          key={"specialite" + key}
          margin="dense"
          label="Domaine d'activité"
          name="specialite"
          size='small'
          InputLabelProps={{shrink:true}}
          value={rows[key].specialite}
          fullWidth
          variant="outlined"
          onChange={(event) => handleChange(event, key)}
        />
        <TextField
          key={"objet" + key}
          margin="dense"
          label="Objet"
          name="objet"
          size='small'
          InputLabelProps={{shrink:true}}
          value={rows[key].objet}
          fullWidth
          variant="outlined"
          onChange={(event) => handleChange(event, key)}
        />
        { props.type === "Photos" ?
          <RadioGroup aria-label="gender" name="gender1"
            value={rows[key].periode}
            onChange={(event) => handleChangeRadio(event, key)} >
            <FormControlLabel value="avant" control={<Radio />} label="Avant" />
            <FormControlLabel value="pendant" control={<Radio />} label="Pendant" />
            <FormControlLabel value="apres" control={<Radio />} label="Après" />
          </RadioGroup>
        : null }
      </Stack>
    )
  }))

  const handleChangeRadio = (event, key) => {
    let newRows = [...rows]
    newRows[key].periode = event.target.value
    setRows(newRows)
  }

  const handleChange = (event, key) => {
    let newRows = [...rows]
    if (event.target.name === "name") {
      newRows[key].name = event.target.value
    } else if (event.target.name === "zone") {
      newRows[key].zone = event.target.value
    } else if (event.target.name === "specialite") {
      newRows[key].specialite = event.target.value
    } else if (event.target.name === "objet") {
      newRows[key].objet = event.target.value
    }
    setRows(newRows)
  }

  return (
    <Stack>
      <Dialog fullWidth open={open} onClose={() => props.saveOrCancel("cancel", [], editFile)} aria-labelledby="form-dialog-title" >
        <Header>Informations photo</Header>
        <DialogContent>
          <Typography variant='h6' style={{ marginBottom: "15px"}}>
            Mettez à jour les informations concernant la photo.
          </Typography>
          {form}
        </DialogContent>
        <DialogActions>
          <Button onClick={() => props.saveOrCancel("cancel", [], editFile)} color="primary">
            Annuler
          </Button>
          <Button onClick={() => props.saveOrCancel("save", rows, editFile)} color="primary">
            Mettre à jour
          </Button>
        </DialogActions>
      </Dialog>
    </Stack>
  );
}