import React from 'react'
import { OutlinedInput } from '@mui/material';

import { styled } from '@mui/material/styles';

// ----------------------------------------------------------------------

const InputStyled = styled(OutlinedInput)(({ theme }) => ({
    width: "100%",
    backgroundColor: "white",
    "input": {
        padding: "5px 10px",
    }
}));

// ----------------------------------------------------------------------

export const TableOutlinedInput = ({ value, onChange, placeholder, name, disabled }) => {
    return (
        <InputStyled
            size="small"
            type='text'
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            disabled={disabled}
        />
    )
}

export const TableOutlinedInputAdornment = ({ value, onChange, placeholder, name, endAdornment }) => {
    return (
        <InputStyled
            size="small"
            value={value}
            onChange={onChange}
            placeholder={placeholder}
            name={name}
            endAdornment={endAdornment}
            disabled={value === "vide"}
        />
    )
}

