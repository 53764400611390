import React from 'react';
import { TableSortLabel, TableRow, TableHead, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import PropTypes from 'prop-types';

import { TableOutlinedInput } from 'components/UI/TableInput/TableOutlinedInput';
import { StyledTableCellHeader, StyledTableCellHeader2 } from './TableUsersStyle';

function EnhancedTableHead(props) {
    const { order, orderBy, onRequestSort } = props;
    const createSortHandler = property => event => {
        onRequestSort(event, property);
    };

    return (
        <React.Fragment>
            <TableHead>
                <TableRow>
                    {props.tableCells.map(headCell => (
                        <StyledTableCellHeader
                            key={headCell.id}
                            sortDirection={orderBy === headCell.id ? order : false}
                            size="small"
                        >
                            <TableSortLabel
                                active={orderBy === headCell.id}
                                direction={orderBy === headCell.id ? order : "asc"}
                                onClick={createSortHandler(headCell.id)}
                            >
                                {headCell.label}
                                {orderBy === headCell.id ? (
                                    <Box sx={{ ...visuallyHidden }}>
                                        {order === "desc" ? "sorted descending" : "sorted ascending"}
                                    </Box>
                                ) : null}
                            </TableSortLabel>
                        </StyledTableCellHeader>
                    )
                    )}
                </TableRow>
                <TableRow>
                    {props.tableCells.map(headCell => (
                        <StyledTableCellHeader2 key={headCell.id} style={{ minWidth: headCell.width, textAlign: "center" }}>
                            <TableOutlinedInput
                                onChange={(event) => { props.changeInputFilter(event) }}
                                placeholder={headCell.label}
                                name={headCell.id}
                            />
                        </StyledTableCellHeader2>
                    ))}
                </TableRow>
            </TableHead>
        </React.Fragment>
    );
}

EnhancedTableHead.propTypes = {
    onRequestSort: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
};

export default EnhancedTableHead;