import React from 'react';
import PropTypes from 'prop-types';
import { alpha } from '@mui/material'

// mui
import Button from '@mui/material/Button';
import { styled } from '@mui/material/styles';

ModalButton.propTypes = {
    clickHandler: PropTypes.func.isRequired,
    icon: PropTypes.object,
    filled: PropTypes.bool.isRequired,
    name: PropTypes.string.isRequired,
};

const ButtonStyled = styled(Button)(({ theme, filled }) => {
    return {
        width: '145px',
        height: '30px',
        lineHeight: "12px",
        ...(filled ? {
            '&:hover': {
                background: alpha(theme.palette.primary.main, 0.8),
            },
        } : {
            background: "#969696",
            '&:hover': {
                background: alpha("#969696", 0.8),
            },
        }),
        [theme.breakpoints.down('xl')]: {
            height: '30px',
            width: '125px',
        },
        [theme.breakpoints.down('lg')]: {
            height: '25px',
            width: '105px',
            lineHeight: "10px",
        },
        [theme.breakpoints.down('md')]: {
            height: '30px',
            width: '125px',
        },
        [theme.breakpoints.down('md2')]: {
            height: '25px',
            width: '100px',
        },
    }
});


// Bouton des modals principales de la DI
// devis / factures / photos...
export default function ModalButton({ clickHandler, icon, filled, name }) {
    return (
        <ButtonStyled
            variant="contained"
            onClick={clickHandler}
            startIcon={icon}
            filled={filled ? 1 : 0}
        >
            {name}
        </ButtonStyled>
    );
}