import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import { Box, AppBar, Toolbar } from '@mui/material';
// utils
import cssStyles from 'utils/cssStyles';
// config
import { HEADER, NAVBAR } from 'config';
// components
import Iconify from 'components/UI/Iconify';
import { IconButtonAnimate } from 'components/UI/animate';
//

// ----------------------------------------------------------------------

const RootStyle = styled(AppBar, {
    shouldForwardProp: (prop) => prop !== 'isCollapse' && prop !== 'isOffset',
})(({ isCollapse, isOffset, theme }) => ({
    ...cssStyles(theme).bgBlur(),
    boxShadow: 'none',
    height: HEADER.MOBILE_HEIGHT,
    zIndex: theme.zIndex.appBar + 1,
    transition: theme.transitions.create(['width', 'height'], {
        duration: theme.transitions.duration.shorter,
    }),
    [theme.breakpoints.up('md')]: {
        height: HEADER.DASHBOARD_DESKTOP_HEIGHT,
        width: `calc(100% - ${NAVBAR.DASHBOARD_WIDTH + 1}px)`,
        ...(isCollapse && {
            width: `calc(100% - ${NAVBAR.DASHBOARD_COLLAPSE_WIDTH}px)`,
        }),
        ...(isOffset && {
            height: HEADER.DASHBOARD_DESKTOP_OFFSET_HEIGHT,
        }),
    },
}));

// ----------------------------------------------------------------------

DashboardHeader.propTypes = {
    onOpenSidebar: PropTypes.func,
    isCollapse: PropTypes.bool,
};

export default function DashboardHeader({ onOpenSidebar, isCollapse = false }) {

    return (
        <RootStyle isCollapse={isCollapse}>
            <Toolbar
                sx={{
                    minHeight: '100% !important',
                    px: { md: 5 },
                }}
            >
                <IconButtonAnimate onClick={onOpenSidebar} sx={{ mr: 1, color: 'text.primary' }}>
                    <Iconify icon="eva:menu-2-fill" />
                </IconButtonAnimate>

                <Box sx={{ flexGrow: 1 }} />
            </Toolbar>
        </RootStyle>
    );
}
