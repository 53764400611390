import React, { useState, useEffect } from "react";
//
import InterventionRequestView from "./InterventionRequestView";
import {
    GetResquestInterventionClient,
    GetPilote,
    GetSpecialite,
    PutStatusDemandeIntervention,
    GetDemandeInterventionsInCorbeille,
} from "services";
import { SkeletonRectangular } from 'components/UI/Skeleton';
import { cloneDeep } from 'lodash'
import { useSnackbar } from 'notistack';
import { connect } from 'react-redux';

function InterventionRequestContainer(props) {
    const [interventionsClient, setInterventionsClient] = useState([])
    const [loading, setLoading] = useState(true)
    const [pilotes, setPilotes] = useState([])
    const [parametres, setParametres] = useState([])
    const [corbeille, setCorbeille] = useState([])
    const { enqueueSnackbar } = useSnackbar();

    useEffect(() => {
        Promise.all([
            GetResquestInterventionClient(props.attached_to),
            GetPilote(),
            GetSpecialite(),
            GetDemandeInterventionsInCorbeille(),
        ]).then(([
            responseGetInterventionClient,
            responseGetPilote,
            responseSpecialite,
            responseCorbeille
        ]) => {
            setParametres(responseSpecialite.parametres)
            setCorbeille(responseCorbeille.interventions)
            PilotesByStorage(responseGetPilote.pilotes)
            setInterventionsClient(responseGetInterventionClient.interventions)
            setLoading(false)
        }).catch(err => {
            console.log(err);
        });
    }, [])

    useEffect(() => {
        Promise.all([
            GetResquestInterventionClient(JSON.parse(localStorage.getItem('pilote'))),
        ]).then(([
            responseGetInterventionClient,
        ]) => {
            setInterventionsClient(responseGetInterventionClient.interventions)
            setLoading(false)
        }).catch(err => {
            console.log(err);
        });
    }, [pilotes])

    const handleStatus = (value, rowId) => {
        PutStatusDemandeIntervention({ id: rowId, status: value }).then((response) => {
            const index = interventionsClient.map(item => item.id).indexOf(rowId)
            let interventionsTmp = cloneDeep(interventionsClient)
            interventionsTmp[index].status = value
            setInterventionsClient(interventionsTmp)
        }).catch(err => {
            enqueueSnackbar('Vos informations ne se sont pas mise à jour', {
                variant: 'error',
            });
            console.log(err);
        });
    }

    const PilotesByStorage = (pilotes) => {
        let newPilote = [...pilotes]
        let piloteStorage = JSON.parse(localStorage.getItem('pilote'))
        if (piloteStorage === null) {
            if (this.props.attached_to !== -1) {
                newPilote.push(this.props.attached_to)
            }
            localStorage.setItem('pilote', JSON.stringify(newPilote))
        } else
            newPilote = JSON.parse(localStorage.getItem('pilote'))
        setPilotes(newPilote)
    }

    const updatePiloteFilter = (piloteFilterUpdate) => {
        localStorage.setItem('pilote', JSON.stringify(piloteFilterUpdate))
        setPilotes(piloteFilterUpdate)
    }

    const putDemandeInterventionInCorbeille = (rowId) => {
        PutStatusDemandeIntervention({ id: rowId.id, corbeille: true, status: rowId.status }).then(() => {
            Promise.all([
                GetResquestInterventionClient(props.attached_to),
                GetDemandeInterventionsInCorbeille(),
            ]).then(([
                responseGetInterventionClient,
                responseCorbeille
            ]) => {
                setCorbeille(responseCorbeille.interventions)
                setInterventionsClient(responseGetInterventionClient.interventions)
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            enqueueSnackbar("Impossible de mettre l'intervention à la corbeille", {
                variant: 'error',
            });
            console.log(err);
        });
    }

    const restoreDeletedDemandeIntervention = (rowId) => {
        PutStatusDemandeIntervention({ id: rowId.id, corbeille: false, status: rowId.status }).then((response) => {
            Promise.all([
                GetResquestInterventionClient(props.attached_to),
                GetDemandeInterventionsInCorbeille(),
            ]).then(([
                responseGetInterventionClient,
                responseCorbeille
            ]) => {
                setCorbeille(responseCorbeille.interventions)
                setInterventionsClient(responseGetInterventionClient.interventions)
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            enqueueSnackbar("Impossible de mettre l'intervention à la corbeille", {
                variant: 'error',
            });
            console.log(err);
        });
    }

    const DeleteTaskHandle = (rowId) => {
        PutStatusDemandeIntervention({ id: rowId.id, corbeille: true, status: "Refusée" }).then(() => {
            Promise.all([
                GetDemandeInterventionsInCorbeille(),
            ]).then(([
                responseCorbeille
            ]) => {
                setCorbeille(responseCorbeille.interventions)
            }).catch(err => {
                console.log(err);
            });
        }).catch(err => {
            enqueueSnackbar("Impossible de suprimer l'intetervention", {
                variant: 'error',
            });
            console.log(err);
        });
    }

    return (
        loading ? <SkeletonRectangular width="100%" height="600px" />
            : <InterventionRequestView
                corbeille={corbeille}
                pilotes={pilotes}
                interventions={interventionsClient}
                setLoading={setLoading}
                parametres={parametres}
                handleStatus={handleStatus}
                updatePiloteFilter={updatePiloteFilter}
                restoreDeletedDemandeIntervention={restoreDeletedDemandeIntervention}
                putDemandeInterventionInCorbeille={putDemandeInterventionInCorbeille}
                DeleteTaskHandle={DeleteTaskHandle}
            />
    );
}

const mapStateToProps = state => {
    return {
        attached_to: state.attached_to,
    };
};

export default connect(mapStateToProps)(InterventionRequestContainer);