import React from 'react';
import { styled } from '@mui/material/styles';
import { Typography, Stack } from '@mui/material';

export const HeaderTitle = styled(Typography)(() => ({
    textDecoration: "underline",
    textAlign: 'center',
    flex: '0 1 auto',
}))

const Header = (props) => {

    return (
        <Stack sx={{ p: 3 }}>
            <HeaderTitle variant="h2" color="primary">{props.children}</HeaderTitle>
        </Stack>
    );
}

export default Header;