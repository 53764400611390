import axios from "axios";
import {
  POST_USER_EVICTUM,
  DELETE_USER_EVICTUM_BY_ID,
  GET_ALL_EVICTUM_USERS,
  PUT_EVICTUM_USER,
  GET_UNCREATED_ACCOUNT,
  GET_GROUPE_CLIENT,
  GET_LOGO,
} from "./CONSTANTS";

export const PostEvictumUser = (toCreateAccount) => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .post(POST_USER_EVICTUM(), toCreateAccount)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(" POST POST_USER_EVICTUMEvictumUser > axios err=", err);
          reject("Error in EvictumUser axios!");
        });
    } catch (error) {
      console.error("in EvictumUserService > EvictumeUser, Err===", error);
    }
  });
};

export const DeleteEvictumUser = (id) => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .delete(DELETE_USER_EVICTUM_BY_ID(id))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("DELETE_EVICTUM_USER > axios err=", err);
          reject("Error in task axios!");
        });
    } catch (error) {
      console.error("in Delete > evictum User, Err===", error);
    }
  });
};

export const GetAllEvictumUsers = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .get(GET_ALL_EVICTUM_USERS())
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(" GetAllEvictumUsers > axios err=", err);
          reject("Error GetAllEvictumUsers axios!");
        });
    } catch (error) {
      console.error(
        "in Get > GetAllEvictumUsers, Err===",
        error
      );
    }
  });
};

export const PutEvictumUser = (updatedUserData) => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .put(PUT_EVICTUM_USER(), updatedUserData)
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log(" PutEvictumUser > axios err=", err);
          reject("Error PutEvictumUser axios!");
        });
    } catch (error) {
      console.error("in Put > PutEvictumUser, Err===", error);
    }
  });
};

export const GetUncreatedAccount = (selectedClient) => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .get(GET_UNCREATED_ACCOUNT(selectedClient))
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("GetUncreatedAccount > axios err=", err);
          reject("Error GetUncreatedAccount axios!");
        });
    } catch (error) {
      console.error("In Get > GetUncreatedAccount, Err===", error);
    }
  });
};

export const GetGroupeClient = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .get(GET_GROUPE_CLIENT())
        .then((res) => {
          resolve(res.data);
        })
        .catch((err) => {
          console.log("GetGroupeClient > axios err=", err);
          reject("Error in GetGroupeClient axios!");
        });
    } catch (error) {
      console.error("In Get > GetGroupeClient, Err===", error);
    }
  });
};

export const GetLogo = () => {
  return new Promise((resolve, reject) => {
    try {
      axios.defaults.withCredentials = true;
      axios
        .get(GET_LOGO())
        .then((res) => {
          resolve(res.data.logo);
        })
        .catch((err) => {
          console.log(" GetLogo > axios err=", err);
          reject("Error GetLogo axios!");
        });
    } catch (error) {
      console.error(
        "in Get > GetLogo, Err===",
        error
      );
    }
  });
};