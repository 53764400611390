import { Stack, Card, TextField, Typography, Button } from '@mui/material';
import React, { useState } from "react";
import { LoadingButton } from '@mui/lab';
import * as Yup from 'yup';
import { useForm } from "react-hook-form";
import * as yup from 'yup';
import { yupResolver } from '@hookform/resolvers/yup';
// import { changePassword } from 'services/accountServices';
import { useSnackbar } from 'notistack';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';

const schema = yup.object({
    newPassword: Yup.string().required('Password is required')
        .matches(
            /^(?=.{8,}$)(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*\W).*$/,
            "Votre mot de pass doit contenir 8 caractères, une majuscule, une minuscule, un chiffre et un caractère spécial"
        ),
    confirmPwd: Yup.string()
        .required('Password is required')
        .oneOf([Yup.ref('newPassword'), null], 'Passwords must match')
}).required();

export default function PasswordForm({ userInformation }) {

    const [openForm, setOpenFor] = useState(false)

    const { enqueueSnackbar } = useSnackbar();
    const { register, handleSubmit, formState: { errors }, reset } = useForm({
        resolver: yupResolver(schema)
    });

    const onSubmitForm = (inputUser) => {
        // changePassword(inputUser).then((response) => {
        //     enqueueSnackbar('Vos informations ont bien été enregistrées', {
        //         variant: 'success',
        //     });
        //     reset();
        // }).catch(err => {
        //     enqueueSnackbar('Votre ancien mot de passe est incorect', {
        //         variant: 'error',
        //     });
        //     console.log(err);
        // });
    }

    return (
        <Card sx={{ p: 3, width: 500, height: "auto" }}>
            <form onSubmit={handleSubmit(onSubmitForm)}>
                <Stack spacing={1} alignItems="center">
                    <AccountCircleIcon color="disabled" sx={{ height: 100, width: 100 }} />
                    <Typography>{userInformation.firstname} {userInformation.lastname}</Typography>
                    <Typography sx={{ fontWeight: "Bold" }} variant='caption'>{userInformation.role}</Typography>
                    {!openForm ?
                        <Button variant="contained" onClick={() => setOpenFor(!openForm)} > Modifier mon mot de passe</Button>
                        :
                        null
                    }
                    {openForm ?
                        <React.Fragment>
                            <TextField fullWidth {...register("oldpassword")} name="oldpassword" type="password" label="Ancien mot de passe" />
                            <TextField fullWidth {...register("newPassword")} name="newPassword" type="password" label="Nouveau mot de passe" helperText={errors.newPassword?.message} />
                            <TextField fullWidth {...register("confirmPwd")} type="password" label="Confirmé nouveau mot de passe" error={errors.confirmPwd?.message} />
                            <LoadingButton type="submit" variant="contained">
                                Envoyer
                            </LoadingButton>
                        </React.Fragment>
                        : null
                    }
                </Stack>
            </form>
        </Card>
    );
}
