import React, { Suspense } from 'react';
import { styled } from '@mui/material/styles';
import MuiModal from '@mui/material/Modal';
import { Box } from '@mui/material';
import PropTypes from 'prop-types';

import Spinner from './Spinner/Spinner';
import CloseButton from './CloseButton';

// ----------------------------------------------------------------------

const StyledBox = styled(Box)(
    ({ theme }) => ({
        display: "flex",
        flexFlow: 'column',
        position: 'absolute',
        maxHeight: "100%",
        backgroundColor: theme.palette.background.paper,
        borderRadius: "5px",
        boxShadow: theme.shadows[5],
        top: "50%",
        left: "50%",
        transform: "translate(-50%, -50%)",
    })
);

// ----------------------------------------------------------------------

const Modal = ({ open, close, children, clickOut = true, sx }) => {
    return (
        <MuiModal open={open} onClose={clickOut ? close : null}>
            <Suspense fallback={<Spinner />}>
                <StyledBox sx={{ ...sx }} >
                    <CloseButton close={close} />
                    {children}
                </StyledBox>
            </Suspense>
        </MuiModal >
    )
};

Modal.propTypes = {
    open: PropTypes.bool,
    close: PropTypes.func,
};


export default Modal;