import * as actionTypes from '../actions/actionTypes';
import { updateObject } from '../utility';

const initialState = {
    isAuthentificated: null,
    id: null,
    userID: null,
    firstname: null,
    lastname: null,
    mail: null,
    telephone: null,
    username: null,
    pilote: false,
    role: null,
    attached_to: -1,
    administrateur: false,
    entreprise: null,
    error: null,
    loading: true,
};

const authStart = (state) => {
    return updateObject(state, { error: null, loading: true });
}

const authSuccess = (state, action) => {
    return updateObject(state, {
        isAuthentificated: true,
        userID: action.userID,
        firstname: action.firstname,
        lastname: action.lastname,
        mail: action.mail,
        telephone: action.telephone,
        username: action.username,
        attached_to: action.attached_to,
        pilote: action.pilote,
        role: action.role,
        administrateur: action.administrateur,
        entreprise: action.entreprise,
        id: action.id,
        error: null,
        loading: false
    });
}

const authFail = (state, action) => {
    return updateObject(state, {
        error: "Error login",
        loading: false,
        isAuthentificated: false,
    });
}

const authLogout = (state, action) => {
    return updateObject(state, initialState)
};

const reducer = (state = initialState, action) => {
    switch (action.type) {
        case actionTypes.AUTH_START: return authStart(state);
        case actionTypes.AUTH_SUCCESS: return authSuccess(state, action);
        case actionTypes.AUTH_FAIL: return authFail(state, action);
        case actionTypes.AUTH_LOGOUT: return authLogout(state, action);
        default:
            return state;
    }
}

export default reducer;