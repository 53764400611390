import React, { useState, useEffect, useRef } from "react";
import axios from 'axios';
import { useLocation } from 'react-router-dom';
// useForm & yup
import { useForm, Controller } from "react-hook-form";
import * as Yup from 'yup';
import { styled } from '@mui/material/styles';
import { yupResolver } from '@hookform/resolvers/yup';
// service
import {
    GetDataForFicheIntervention,
    GetLinkFicheIntervention,
    GetUserEntreprise
} from "services";
// component
import Dropzone from 'components/UI/DropZoneCompress'
import Page from 'components/UI/Page'
import { ResizeImage } from 'components/UI/ResizeImage';
import Iconify from "components/UI/Iconify";
import MultiFilePreview from "components/UI/upload/MultiFilePreview";
import SignaturePad from 'react-signature-canvas'
import PageNotFoundFicheIntervention from "./PageNotFoundFicheIntervention";
//Mui
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LoadingButton } from '@mui/lab';
import InfoIcon from '@mui/icons-material/Info';
import ExpandLess from '@mui/icons-material/ExpandLess';

import SentimentVeryDissatisfiedIcon from '@mui/icons-material/SentimentVeryDissatisfied';
import SentimentSatisfiedIcon from '@mui/icons-material/SentimentSatisfied';
import SentimentVerySatisfiedIcon from '@mui/icons-material/SentimentVerySatisfied';

//
import {
    List,
    ListItemButton,
    ListItemText,
    ListItemIcon,
    Collapse,
    Stack,
    Card,
    TextField,
    Grid,
    Box,
    Button,
    Typography,
    FormControlLabel,
    RadioGroup,
    Radio,
    Rating,
} from '@mui/material';

const schema = Yup.object().shape({
    rapport: Yup.string().required("Veuillez renseigner votre requete"),
}).required();

const StyledRating = styled(Rating)(({ theme }) => ({
    '& .MuiRating-iconEmpty .MuiSvgIcon-root': {
        color: theme.palette.action.disabled,
    },

}));

const customIcons = {
    1: {
        icon: <SentimentVeryDissatisfiedIcon color="error" sx={{ height: "40px", width: "40px" }} />,
        label: 1,
    },
    2: {
        icon: <SentimentSatisfiedIcon color="warning" sx={{ height: "40px", width: "40px" }} />,
        label: 2,
    },
    3: {
        icon: <SentimentVerySatisfiedIcon color="success" sx={{ height: "40px", width: "40px" }} />,
        label: 3,
    },
};

function IconContainer(props) {
    const { value, ...other } = props;
    return <span {...other}>{customIcons[value].icon}</span>;
}

const FicheIntevervention = () => {
    const [loadingButton, setLoadingButton] = useState(false)
    const [loading, setLoading] = useState(false)
    const [findLink, setFindLink] = useState(false)
    const [photos, setPhotos] = useState([]);
    const [open] = React.useState(true);
    const [openDescription] = React.useState(true);
    const [data, setData] = useState()
    const sigCanvas = useRef({});
    const clear = () => sigCanvas.current.clear();
    let location = useLocation();
    let { search } = location;

    const IdMastoreInUrl = (str, firstInterval, lastInterval) => {
        const start = str.indexOf(firstInterval) + 1;
        const end = str.lastIndexOf(lastInterval);
        return str.substring(start, end);
    }
    const IdMastore = IdMastoreInUrl(search, "=", "_")

    const checkedUrl = (linkFromDb, linkFromUrl) => {
        return linkFromDb.some(element => {
            const code = element.substring(element.indexOf("?"))
            return code === linkFromUrl
        })
    }

    useEffect(() => {
        Promise.all([
            GetDataForFicheIntervention(IdMastore),
            GetLinkFicheIntervention(IdMastore)
        ]).then(([
            responseGetDataForFicheIntervention,
            reponseGetLinkFicheIntervention
        ]) => {
            setData(responseGetDataForFicheIntervention.interventions)
            setLoading(true)
            if (checkedUrl(reponseGetLinkFicheIntervention.links, search)) {
                setFindLink(true)
            }
        }).catch(err => {
            console.log(err);
        });
    }, [search, IdMastore])

    const { register, handleSubmit, setValue, formState: { errors }, control } = useForm({
        resolver: yupResolver(schema)
    });

    const formatIntoPng = () => {
        if (sigCanvas.current) {
            const dataURL = sigCanvas.current.toDataURL();
            return dataURL;
        }
    };

    const handlePhoto = async (files) => {
        let base64Files = []
        base64Files = await Promise.all(files.map(async file => {
            const base64 = await ResizeImage(file)
            return ({ file: base64, type: "image", name: file.path })
        }))
        setPhotos(prevState => prevState.concat(base64Files))
    }

    const handleRemove = (file) => {
        const filteredItems = photos?.filter((_file) => _file !== file);
        setPhotos(filteredItems);
    };

    const onSubmitForm = (inputUser) => {
        inputUser.photo = photos
        let allPhotos = photos.map(object => object.file);
        let allNamePhotos = photos.map(object => object.name);
        GetUserEntreprise(data.pilote).then(response => {
            const mail = response.user.entreprise === "Mastore Ouest" ? "intervention.ouest@mastore.fr" : "intervention@mastore.fr"
            sendMail(mail,
                "Rapport intervention - " + data.id_mastore + " - " + data.enseigne + " " + data.ville + " " + data.numero_magasin + " - " + data.objet + " - " + data.id_client,
                `Vous trouverez ci-joint le rapport d'intervention de l'intervention:\n\nEnseigne: ${data.enseigne}\nN° ${data.numero_magasin}\nVille: ${data.ville}\nObjet: ${data.objet}\nDI Client: ${data.id_client}\nID Mastore: ${data.id_mastore}\n\nhttps://mastore.evictum.fr/intervention?IDMastore=${data?.id_mastore}`,
                allPhotos,
                allNamePhotos,
                inputUser.status,
                inputUser.rapport,
                inputUser.signature,
                inputUser.rating,
            )
        }).catch(err => {
            console.log(err);
        })
    }

    const sendMail = (destinataire, objectMail, bodyMail, idFile, nameFile, Status, Rapport, Signature, rating) => {
        setLoadingButton(true)
        const header = {
            IDMastore: data.id_mastore,
            id_attachment: 1,
            Destinataires: [destinataire],
            ObjetMail: objectMail,
            BodyMail: bodyMail,
            Attachment: idFile,
            NameAttachment: nameFile,
            Status: Status,
            Rapport: Rapport,
            date_rapport: new Date(),
            Signature: Signature,
            Rating: rating,
        }
        axios.defaults.withCredentials = true
        axios.post('/legacy/sendMailFicheIntervention', header)
            .then(response => {
                window.location.replace('/legacy/validation_fiche_intervention');
            }).catch(response => {
                setLoadingButton(false)
            })
    }

    return (
        <React.Fragment>
            {
                findLink && loading ?
                    <Page title={"Mastore | Fiche Intervention"} sx={{ p: "10px" }}>
                        <Card sx={{ p: 2 }} variant="outlined" >
                            <form onSubmit={handleSubmit(onSubmitForm)}>
                                <Stack spacing={3} alignItems="center" p={0}>
                                    <Stack sx={{ backgroundColor: "#033353", width: "100%", padding: "3px", borderRadius: "5px" }}>
                                        <Typography variant="h2" color="white" sx={{ textAlign: "center" }}>
                                            Rapport de l'intervention
                                        </Typography>
                                    </Stack>
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <InfoIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText primary="Concerne l'enseigne :" />
                                            <ExpandLess />
                                        </ListItemButton>
                                        <Collapse in={open} timeout="auto" unmountOnExit sx={{ px: 4, pt: 1 }}>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h6">Enseigne : </Typography>
                                                <Typography>{data.client}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h6">Adresse :</Typography>
                                                <Typography>{data.adresse}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h6">Code postal :</Typography>
                                                <Typography>{data.code_postal}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h6">ID Mastore :</Typography>
                                                <Typography>{data.id_mastore}</Typography>
                                            </Stack>
                                            <Stack direction="row" spacing={1}>
                                                <Typography variant="h6">Ville :</Typography>
                                                <Typography>{data.ville}</Typography>
                                            </Stack>
                                        </Collapse>
                                    </List>
                                    <List
                                        sx={{ width: '100%', bgcolor: 'background.paper' }}
                                        component="nav"
                                    >
                                        <ListItemButton>
                                            <ListItemIcon>
                                                <InfoIcon color="primary" />
                                            </ListItemIcon>
                                            <ListItemText primary="Description de l'intervention :" />
                                            <ExpandLess />
                                        </ListItemButton>
                                        <Collapse in={openDescription} timeout="auto" unmountOnExit sx={{ px: 4, pt: 1 }}>
                                            <Typography variant="body2">
                                                {data.initial_request}
                                            </Typography>
                                        </Collapse>
                                    </List>
                                    <Stack direction="row" spacing={1} mt={2} justifyContent="center">
                                        <RadioGroup
                                            row
                                            aria-labelledby="demo-row-radio-buttons-group-label"
                                            name="row-radio-buttons-group"
                                            style={{ justifyContent: "center" }}
                                        >
                                            <FormControlLabel {...register("status")} value="Terminée" control={<Radio size="small" required={true} />} label="Terminée" />
                                            <FormControlLabel {...register("status")} value="Non Terminée" control={<Radio size="small" required={true} />} label="Non Terminée" />
                                            <FormControlLabel {...register("status")} value="Devis à faire" control={<Radio size="small" required={true} />} label="Devis à faire" />
                                        </RadioGroup>
                                    </Stack>
                                    <TextField fullWidth rows={6} multiline={true} {...register("rapport")} name="rapport" type="text" placeholder='Prestation(s) réalisée(s)' helperText={errors.rapport?.message} />
                                    <Grid item xs={12} sm={12} md={6} lg={6}>
                                        <Dropzone returnFunction={handlePhoto} image={true} pdf={false} icon={<Iconify icon={"material-symbols:photo-camera-outline"} />} text={"Sélectionnez vos photos"} />
                                        {photos.length > 0 ? <MultiFilePreview files={photos} showPreview={photos} onRemove={handleRemove} /> : null}
                                    </Grid >

                                    <Stack sx={{ backgroundColor: "#033353", width: "100%", padding: "3px", borderRadius: "5px" }}>
                                        <Typography variant="h2" color="white" sx={{ textAlign: "center" }}>
                                            Validation de l'intervention
                                        </Typography>
                                    </Stack>
                                    <Stack alignItems={"center"} justifyContent={"center"} spacing={3}>
                                        <Controller
                                            control={control}
                                            name="date"
                                            render={({ field: { onChange, value } }) => (
                                                <DatePicker
                                                    disableCloseOnSelect={false}
                                                    disabled
                                                    showToolbar={false}
                                                    label="Date du rapport"
                                                    inputFormat="DD MMMM YYYY"
                                                    mask="__/____/____"
                                                    value={value}
                                                    onChange={onChange}
                                                    renderInput={(params) =>
                                                        <TextField
                                                            {...params}
                                                            size="small"
                                                            style={{ width: "210px" }}
                                                            InputLabelProps={{ shrink: true }}
                                                        />
                                                    }
                                                />
                                            )}
                                        />
                                        <Stack>
                                            <Typography variant={"body1"} textAlign={"center"} m={1}>
                                                Enquête qualité
                                            </Typography>
                                            <StyledRating
                                                name="rating"
                                                onChange={(event, value) => setValue("rating", value)}
                                                IconContainerComponent={IconContainer}
                                                getLabelText={(value) => customIcons[value].label}
                                                highlightSelectedOnly
                                                max={3}
                                            />
                                        </Stack>
                                        <Stack>
                                            <Typography variant={"body1"} textAlign={"center"} m={1}>
                                                Signature du responsable
                                            </Typography>
                                            <Box sx={{ boxShadow: 3, height: "170px", width: "170px" }}>
                                                <Controller
                                                    name="signature"
                                                    control={control}
                                                    render={({ field }) => (
                                                        <SignaturePad
                                                            ref={sigCanvas}
                                                            onEnd={() => field.onChange(formatIntoPng())}
                                                            penColor="black"
                                                            canvasProps={{
                                                                width: 170,
                                                                height: 170,
                                                            }}
                                                        />
                                                    )}
                                                />
                                            </Box>
                                            <Button onClick={clear}>
                                                Recommencer la signature
                                            </Button>
                                        </Stack>
                                    </Stack>
                                    <LoadingButton type="submit" variant="contained" loading={loadingButton}>
                                        Envoyer
                                    </LoadingButton>
                                </Stack>
                            </form>
                        </Card>
                    </Page>
                    :
                    <PageNotFoundFicheIntervention />
            }
        </React.Fragment >
    )
}

export default FicheIntevervention