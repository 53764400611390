import HttpService from './HTTPService';
import { Module, ModuleDevisSites } from 'views/ModuleDevis/types';

export default class ModuleDevisService {
    private httpService: HttpService;

    constructor() {
        this.httpService = new HttpService();
    }

    // get tout les modules devis defaut
    async getModules(page: number, pageSize: number, name: string, description: string, specialite: string[]): Promise<any> {
        const queryParams = new URLSearchParams({
            page: page.toString(),
            pageSize: pageSize.toString(),
            name: name || '',
            description: description || '',
            specialite: specialite.join(',') || ''
        }).toString();
        const path = "/moduleDevis/getAll";
        return await this.httpService.get(`${path}?${queryParams}`);
    }

    // create module devis defaut
    async createModule(moduleDevis: Module): Promise<Module> {
        const requestBody = {
            moduleDevis: moduleDevis,
        };
        const path = "/moduleDevis/create";
        return await this.httpService.post(path, requestBody);
    }

    // delete module devis defaut
    async deleteModule(moduleId: number): Promise<void> {
        const path = `/moduleDevis/delete/${moduleId}`;
        return await this.httpService.delete(path);
    }

    // update module devis defaut
    async updateModule(module: Module): Promise<void> {
        const requestBody = {
            moduleDevis: module,
        };
        const path = "/moduleDevis/update";
        return await this.httpService.patch(path, requestBody);
    }

    // get le module devis avec les modules devis sites pour un client
    async getModuleSitesByClient(moduleDevisID: number, client: string): Promise<any> {
        const queryParams = `moduleDevisID=${moduleDevisID}&client=${client}`;
        const path = "/moduleDevis/moduleSitesByClient";
        return await this.httpService.get(`${path}?${queryParams}`);
    }

    // update des modules devis sites d'un client
    async updateModuleSites(moduleDevisSites: ModuleDevisSites[]): Promise<void> {
        const requestBody = {
            moduleDevisSites: moduleDevisSites,
        };
        const path = "/moduleDevis/updateModuleDevisSites";
        return await this.httpService.patch(path, requestBody);
    }

    // get les modules devis par magasin
    async getModulesDevisByMagasin(page: number, pageSize: number, ville: string, client: string, enseigne: string, numeroMagasin: string, name: string, description: string, specialite: string[]): Promise<any> {
        const queryParams = new URLSearchParams({
            page: page.toString(),
            pageSize: pageSize.toString(),
            ville: ville,
            client: client,
            enseigne: enseigne,
            numeroMagasin: numeroMagasin,
            name: name || '',
            description: description || '',
            specialite: specialite.join(',') || ''
        }).toString();
        const path = "/moduleDevis/getModulesDevisByMagasin";
        return await this.httpService.get(`${path}?${queryParams}`);
    }
}
