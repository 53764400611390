import React from 'react';
import { TableBody, TableCell, TableContainer, TableHead, TableRow, Paper } from '@mui/material';
import { CustomTable, IconEdit, IconDelete, IconImage } from './DocumentStyle';

const DocumentTable = ({ dataFiles, editFile, showDocument, removeFile }) => {
    return (
        <TableContainer component={Paper} style={{ maxHeight: "330px", minHeight: "70px" }}>
            <CustomTable size="small" aria-label="a dense table">
                <TableHead>
                    <TableRow>
                        {editFile ? <TableCell align="center" style={{ padding: "5px 5px" }}></TableCell> : null}
                        <TableCell>Nom du fichier</TableCell>
                        <TableCell style={{ padding: "5px 5px" }} align="center">Objet</TableCell>
                        <TableCell style={{ width: "50px", padding: "5px 5px" }} align="center">Document</TableCell>
                        {removeFile ? <TableCell align="center" style={{ width: "50px", padding: "5px 5px" }}></TableCell> : null}
                    </TableRow>
                </TableHead>
                <TableBody>
                    {dataFiles.map((row) => (
                        <TableRow key={row.name} style={{ background: "white" }}>
                            {editFile ? <TableCell align="center" title="Modifier" style={{ padding: "5px 5px" }}><IconEdit onClick={(event) => editFile(event, row)} /></TableCell> : null}
                            <TableCell component="th" scope="row">
                                {row.name}
                            </TableCell>
                            <TableCell align="center" style={{ padding: "5px 5px" }}>{row.objet}</TableCell>
                            <TableCell align="center" style={{ padding: "5px 5px" }}><IconImage onClick={(event) => showDocument(event, row)} /></TableCell>
                            {removeFile ? <TableCell align="center" title="Supprimer le document" style={{ padding: "5px 5px" }}><IconDelete onClick={(event) => removeFile(event, row)} /></TableCell> : null}
                        </TableRow>
                    ))}
                </TableBody>
            </CustomTable>
        </TableContainer>
    );
}

export default DocumentTable;
