import React, { Component } from 'react';
import Dropzone from 'react-dropzone';

// mui
import Alert from '@mui/material/Alert';
import SaveIcon from '@mui/icons-material/Save';
import { Box, Stack, Typography } from '@mui/material';

// styles
import { ButtonAdd, DivButton, CustomAddIcon, SaveButton } from './PlanStyle';

// components
import Table from '../Table/Table';
import Header from 'components/UI/Header/Header';
import ModalInfosFiles from '../ModalInfosFiles/ModalInfosFiles';
import ModalEditInfosFiles from '../ModalInfosFiles/ModalEditInfosFiles';

class Plan extends Component {
    constructor(props) {
        super(props);
        this.state = {
            files: this.props.filesPlan,
            waitFiles: [],
            dataFiles: this.props.filesPlan,
            openDataFile: false,
            openEditDataFile: false,
            textInfo: "Ajouter des fichiers",
            editFile: [],
            alert: null,
            hasChanges: false,
        };
    }

    onDrop(files) {
        let SizeOk = true;
        files.forEach(file => {
            if (file.size > 10000000) { //10Mo
                SizeOk = false
            }
        });
        if (SizeOk) {
            let waitFiles = this.state.waitFiles.concat(files.map(file => Object.assign(file, {
                preview: URL.createObjectURL(file)
            })));
            this.setState({
                waitFiles: waitFiles,
                openDataFile: true,
                alert: null,
                hasChanges: true, // Mark changes when files are added
            });
        } else {
            this.setState({ alert: <Alert style={{ margin: 'auto', marginBottom: "20px", border: "1px solid", width: 'fit-content' }} severity="warning">Limite de taille de fichier atteinte! Taille maximale 10Mo. Aucun des fichiers n'a été ajouté.</Alert> });
        }
    }

    addPlan = () => {
        this.props.addPlan(this.state.files);
        this.props.modalClosed();
        this.setState({ hasChanges: false }); // Reset changes after saving
    }

    removeFile = (_, file) => {
        let filteredFiles = this.state.files.filter(item => item !== file);
        this.setState({ files: filteredFiles, dataFiles: filteredFiles, hasChanges: true }); // Mark changes when files are removed
    }

    saveOrCancel = (att, data) => {
        if (att === "save") {
            this.setState({
                files: this.state.files.concat(data),
                waitFiles: [],
                dataFiles: this.state.dataFiles.concat(data),
                openDataFile: false,
                hasChanges: true, // Mark changes when files are saved
            });
        } else if (att === "cancel") {
            this.setState({
                waitFiles: [],
                openDataFile: false,
                hasChanges: false, // Reset changes if action is canceled
            });
        }
    }

    editFile = (_, file) => {
        this.setState({ editFile: this.state.editFile.concat(file), openEditDataFile: true });
    }

    saveOrCancelEditFile = (att, newFile, oldFile) => {
        if (att === "save") {
            let index = this.state.files.map(item => item).indexOf(oldFile);
            let filteredFiles = [...this.state.files];
            filteredFiles[index] = newFile[0];
            this.setState({
                files: filteredFiles,
                editFile: [],
                dataFiles: filteredFiles,
                openEditDataFile: false,
                hasChanges: true, // Mark changes when file edits are saved
            });
        } else if (att === "cancel") {
            this.setState({
                editFile: [],
                openEditDataFile: false,
                hasChanges: false, // Reset changes if edit is canceled
            });
        }
    }

    render() {
        return (
            <Box sx={{ width: { md: "70vw", sm: "80vw", xs: "95vw" }, maxHeight: "80vh" }}>
                <Header>Plans du magasin</Header>
                <Stack sx={{ px: 3, maxHeight: "550px", overflow: 'auto' }} spacing={1}>
                    {this.state.openDataFile ? <ModalInfosFiles openDataFile={this.state.openDataFile} dataFiles={this.state.waitFiles} saveOrCancel={this.saveOrCancel} /> : null}
                    {this.state.openEditDataFile ? <ModalEditInfosFiles openDataFile={this.state.openEditDataFile} dataFiles={this.state.editFile} saveOrCancel={this.saveOrCancelEditFile} type="Photos" /> : null}
                    <Dropzone accept="image/*, application/pdf" onDrop={this.onDrop.bind(this)}>
                        {({ getRootProps, getInputProps }) => (
                            <section className="container" style={{ paddingLeft: "0px" }}>
                                <div style={{ width: "fit-content" }} {...getRootProps({ className: 'dropzone' })}>
                                    <input {...getInputProps()} />
                                    <ButtonAdd variant="outlined" size='small' startIcon={<CustomAddIcon />}>
                                        Ajouter plan(s)
                                    </ButtonAdd>
                                </div>
                            </section>
                        )}
                    </Dropzone>
                    <Stack style={{ display: "flex", flexWrap: "wrap", justifyContent: "center" }}>
                        {this.state.dataFiles.length > 0 ? (
                            <Table data={this.state.dataFiles} removeFile={this.removeFile} NumMag={this.props.data.NumeroMagasin} Enseigne={this.props.data.Enseigne} editFile={this.editFile} />
                        ) : (
                            <Typography variant="h1" align="center" textAlign={"center"} color="rgba(99, 115, 129, 0.3)">
                                Aucun plan
                            </Typography>
                        )}
                    </Stack>
                </Stack>
                <Stack sx={{ p: 3 }} direction="row" justifyContent="center" alignItems="center">
                    <DivButton>
                        <SaveButton startIcon={<SaveIcon />} variant="outlined" size='small' onClick={this.addPlan} disabled={!this.state.hasChanges}>
                            Sauvegarder le(s) plan(s)
                        </SaveButton>
                    </DivButton>
                </Stack>
            </Box>
        );
    }
}

export default Plan;
