import axios from 'axios'
import {  LOGOUT } from "./CONSTANTS";

export const logout = () => {
    return new Promise((resolve, reject) => {
        try {
            axios.defaults.withCredentials = true
            axios.get(LOGOUT())
                .then((res) => {
                    resolve(res.data);
                }).catch((err) => {
                    reject("Error in logout !");
                });
        } catch (error) {
            console.error("in userServices > logout, Err===", error);
        }
    });
};