import React from 'react'
import { Stack, Link, Typography } from '@mui/material';
import { useLottie } from "lottie-react";
import SuccesMailSended from "../../assets/mail-success.json"
const LOTTIESTAB = {
    1: {
        loop: true,
        autoplay: true,
        animationData: SuccesMailSended,
        rendererSettings: {
            preserveAspectRatio: "xMidYMid slice"
        },
        height: 400,
        width: 400,
    },
};

export default function ValidationFicheIntervention() {
    const { View } = useLottie(LOTTIESTAB[1], { height: LOTTIESTAB[1].height, width: LOTTIESTAB[1].width });
    return (
        <Stack sx={{ height: "100%" }} p={3} textAlign="center" alignContent="center" alignItems="center" justifyContent="center">
            <Typography variant='h3'>
                Votre demande d'intervention a bien éte validée
            </Typography>
            {View}
            <Typography variant='h3'>
                L'équipe Mastore vous remercie
            </Typography>
            <Stack spacing={0.5} sx={{ mt: 5 }}>
                <Typography variant='h6'>
                    RETROUVEZ-NOUS :
                </Typography>
                <Typography>
                    Mastore
                    Mastore Lyon
                    69500 Bron
                    Tél : 04.78.41.61.49
                </Typography>
                <Typography>
                    OUEST
                    Mastore Ouest
                    17440 Aytré
                    Tél : 06.49.60.97.57
                </Typography>
                <Link href="https://mastore.fr/">www.mastore.fr</Link>
            </Stack>
        </Stack>
    )
}
