import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import PublishIcon from '@mui/icons-material/Publish';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import axios from 'axios';
import Spinner from '../../../UI/Spinner/Spinner';
import Snackbar from '@mui/material/Snackbar';
import MuiAlert from '@mui/material/Alert';
import EditIcon from '@mui/icons-material/Edit';
import { styled } from '@mui/material/styles';

import { b64toBlob } from 'utils/handleFiles';

// Fonction alert pour error request
function Alert(props) {
    return <MuiAlert elevation={6} variant="filled" {...props} />;
}

export const CustomTable = styled(Table)(() => ({
    minWidth: 650,

}))

export const CustomIconEdit = styled(EditIcon)(() => ({
    color: "#316690",
    cursor: "pointer",
    '&:hover': {
        color: "#1e3e56",
    }
}))

export const IconImage = styled(ImageIcon)(() => ({
    color: "#316690",
    cursor: "pointer",
    '&:hover': {
        color: "#316690",
    }
}))

export const IconPublish = styled(PublishIcon)(() => ({
    color: "#349034ed",
    cursor: "pointer",
    '&:hover': {
        color: "#1b5e20",
    }
}))

export const IconDelete = styled(DeleteIcon)(() =>({
    color: "#FF0000",
    cursor: "pointer",
    '&:hover': {
        color: "#DD0000",
    }
}))

export const PreviewStyleDialog = styled('img')(() => ({
    display: 'inline',
        width: '200px',
        height: '200px',
        paddingBottom: 10,
        transition: 'transform 2.2s',
        marginLeft: 'auto',
        marginRight: 'auto',
}))

export default function DenseTable(props) {
    const [rows, setRows] = React.useState([])
    const [openDialog, setOpenDialog] = React.useState(false)
    const [dataInventaire, setDataInventaire] = React.useState("")
    const [srcImageInv, setSrcImageInv] = React.useState("")
    const [spinner, setSpinner] = React.useState(false)
    // Alert Erreur request
    const [openAlertErrorRequest, setOpenAlertErrorRequest] = React.useState(false)
    const [alertErrorRequest, setAlertErrorRequest] = React.useState(null)

    React.useEffect(() => {
        if (props.data) {
            const data = [...props.data]
            setRows(data)
        }
    }, [props.data]);

    // <-- Function Alert error Request -->

    const handleCloseAlertErrorRequest = (event, reason) => {
        setOpenAlertErrorRequest(false)
        setAlertErrorRequest(null)
    }

    // <-- End Function Alert error Request -->

    const showImage = (event, row) => {
        let blob
        if (row.name.includes(".png") || row.name.includes(".jpg") || row.name.includes(".jpeg") ||
            row.name.includes(".PNG") || row.name.includes(".JPG") || row.name.includes(".JPEG")) {
            blob = b64toBlob(row.file, 'image/png;base64')
        } else if (row.name.toLowerCase().includes(".pdf")) {
            blob = b64toBlob(row.file, "application/pdf")
        }
        let url = URL.createObjectURL(blob);
        window.open(url)
    }

    const addToInventaire = (check) => {
        if (check === "cancel") {
            setOpenDialog(false)
            setDataInventaire("")
            setSrcImageInv("")
        } else {
            setSpinner(true)
            let formData = new FormData();
            if (dataInventaire.file.preview)
                formData.append('files', dataInventaire.file)
            else
                formData.append('imgBase64', dataInventaire.photo)
            formData.append('nameFiles', dataInventaire.name)
            formData.append('zone', dataInventaire.zone)
            formData.append('specialite', dataInventaire.specialite)
            formData.append('objet', dataInventaire.objet)
            formData.append('NumeroMagasin', props.NumMag)
            formData.append('Enseigne', props.Enseigne)
            formData.append('Attribut', "Inventaires")
            formData.append('periode', dataInventaire.periode)
            axios.defaults.withCredentials = true
            axios.post('/legacy/addInventaires', formData)
                .then(_ => {
                    setOpenDialog(false)
                    setDataInventaire("")
                    setSrcImageInv("")
                    setSpinner(false)
                })
                .catch(error => {
                    setOpenAlertErrorRequest(true)
                    setAlertErrorRequest(<Alert onClose={this.handleCloseAlertErrorRequest} severity="error">
                        Erreur ajout photo dans l'inventaire!
                    </Alert>)
                    setSpinner(false)
                });
        }
    }

    const openDialogInventaire = (_, row) => {
        setDataInventaire(row)
        setOpenDialog(true)
        let srcImg = "data:image/*;base64, " + row.photo
        if (row.file.preview) {
            setSrcImageInv(<PreviewStyleDialog src={row.photo} alt="Preview" />)
        } else {
            setSrcImageInv(<PreviewStyleDialog src={srcImg} alt="Preview" />)
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <Snackbar open={openAlertErrorRequest} autoHideDuration={6000} onClose={handleCloseAlertErrorRequest}>
                {alertErrorRequest}
            </Snackbar>
            <Dialog open={openDialog} onClose={() => addToInventaire("cancel")} aria-labelledby="form-dialog-title">
                <DialogTitle id="form-dialog-title">Ajouter à l'inventaire</DialogTitle>
                <DialogContent >
                    <DialogContentText>
                        Voulez-vous vraiment ajouter cette photo à l'inventaire du magasin ?
                    </DialogContentText>
                    {spinner ? <Spinner /> : null}
                    {spinner ? null : <div style={{ display: "flex" }}>
                        {srcImageInv}
                    </div>}
                </DialogContent>
                {spinner ? null :
                    <DialogActions>
                        <Button onClick={() => addToInventaire("cancel")} color="primary">
                            Annuler
                        </Button>
                        <Button onClick={() => addToInventaire("save")} color="primary">
                            Ajouter
                        </Button>
                    </DialogActions>
                }
            </Dialog>
            <TableContainer component={Paper} style={{ maxHeight: "330px", minHeight: "70px" }}>
                <CustomTable size="small" aria-label="a dense table">
                    <TableHead >
                        <TableRow>
                            {props.editFile ? <TableCell align="left" style={{ padding: "5px 5px" }}></TableCell> : null}
                            <TableCell >Nom du fichier</TableCell>
                            <TableCell style={{ padding: "5px 5px" }} align="center">Zone</TableCell>
                            <TableCell style={{ padding: "5px 5px" }} align="center">Domaine d'activité</TableCell>
                            <TableCell style={{ padding: "5px 5px" }} align="center">Objet</TableCell>
                            {props.type === "Photos" ? <TableCell style={{ padding: "5px 5px" }} align="center">Periode</TableCell> : null}
                            <TableCell style={{ width: "50px", padding: "5px 5px" }} align="center">Photo</TableCell>
                            {props.removeFile ? <TableCell align="center" style={{ width: "50px", padding: "5px 5px" }}>Inventaire</TableCell> : null}
                            {props.removeFile ? <TableCell align="right" style={{ width: "50px", padding: "5px 5px" }}></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => (
                            <TableRow key={row.name}>
                                {props.editFile ? <TableCell align="center" title="Modifier" style={{ padding: "5px 5px" }}><CustomIconEdit onClick={(event) => props.editFile(event, row)} /></TableCell> : null}
                                <TableCell component="th" scope="row">
                                    {row.name}
                                </TableCell>
                                <TableCell align="center" style={{ padding: "5px 5px" }}>{row.zone}</TableCell>
                                <TableCell align="center" style={{ padding: "5px 5px" }}>{row.specialite}</TableCell>
                                <TableCell align="center" style={{ padding: "5px 5px" }}>{row.objet}</TableCell>
                                {props.type === "Photos" ? <TableCell align="center" style={{ padding: "5px 5px" }}>{row.periode}</TableCell> : null}
                                <TableCell align="center" style={{ padding: "5px 5px" }}><IconImage onClick={(event) => showImage(event, row)} /></TableCell>
                                {props.removeFile ? <TableCell align="center" title="Ajouter à l'inventaire" style={{ padding: "5px 5px" }}><IconPublish onClick={(event) => openDialogInventaire(event, row)} /></TableCell> : null}
                                {props.removeFile ? <TableCell align="center" title="Supprimer la photo" style={{ padding: "5px 5px" }}><IconDelete onClick={(event) => props.removeFile(event, row)} /></TableCell> : null}
                            </TableRow>
                        ))}
                    </TableBody>
                </CustomTable>
            </TableContainer>
        </div>
    );
}