import React from 'react'
import { styled } from '@mui/material/styles';
import {
    IconButton,
    Menu,
    MenuItem,
} from '@mui/material';
import Divider from '@mui/material/Divider';
import DescriptionIcon from '@mui/icons-material/Description';
import DeleteIcon from '@mui/icons-material/Delete';
import MoreVertIcon from '@mui/icons-material/MoreVert';

// ----------------------------------------------------------------------

const StyledMenu = styled(Menu)(({ theme }) => ({
    '& .MuiPaper-root': {
        borderRadius: 6,
        '& .MuiMenuItem-root': {
            '& .MuiSvgIcon-root': {
                marginRight: theme.spacing(1.5),
            },

        },
    },
}));

// ----------------------------------------------------------------------

const MenuAction = ({ action, deleteAction, openActionFacture, openActionDevis }) => {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const open = Boolean(anchorEl);

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const onMenuClick = (func) => {
        handleClose()
        func()
    }

    return (
        <React.Fragment>
            <IconButton
                aria-controls={open ? 'long-menu' : undefined}
                aria-expanded={open ? 'true' : undefined}
                aria-haspopup="true"
                onClick={handleClick}
            >
                <MoreVertIcon color='primary' />
            </IconButton>
            <StyledMenu
                anchorEl={anchorEl}
                open={open}
                onClose={handleClose}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'top',
                    horizontal: 'right',
                }}
            >
                <MenuItem onClick={() => onMenuClick(() => openActionDevis(action))} disableRipple disabled={action.intervenant_id === -1}>
                    <DescriptionIcon color='primary' />
                    Devis
                </MenuItem>
                <MenuItem onClick={() => onMenuClick(() => openActionFacture(action))} disableRipple disabled={action.intervenant_id === -1}>
                    <DescriptionIcon color='primary' />
                    Facture
                </MenuItem>
                <Divider sx={{ my: 0.5 }} />
                <MenuItem onClick={() => onMenuClick(() => deleteAction(action))} disableRipple>
                    <DeleteIcon color='error' />
                    Supprimer
                </MenuItem>
            </StyledMenu>
        </React.Fragment>
    )
}

export default MenuAction