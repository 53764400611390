import React from "react";
// material
import { Grid, Stack } from "@mui/material";
// components
import InterventionInformation from "./components/InterventionInformation";
import InterventionTitle from "./components/InterventionTitle";
import GeneralInformation from "./components/GeneralInformation";

export function InformationView(props) {
    return (
        <Grid container item xs={12} md={6}>
            <Stack
                direction={"column"}
                spacing={{ md: 0.5, xs: 1 }}
                justifyContent="flex-start"
                sx={{ width: "100%" }}
            >
                <InterventionTitle
                    infoIntervention={props.infoIntervention}
                    horairesOuverture={props.horairesOuverture}
                    infosMagasin={props.infosMagasin}
                />
                <GeneralInformation
                    infoIntervention={props.infoIntervention}
                    user={props.user}
                    updateInfoIntervention={props.updateInfoIntervention}
                    contact_client={props.clientInfo.contact}
                    openInventaireModal={props.openInventaireModal}
                    fileInventaireLength={props.fileInventaireLength}
                    openModalHistorique={props.openModalHistorique}
                />
                <InterventionInformation
                    updateInfoIntervention={props.updateInfoIntervention}
                    actions={props.actions}
                    factureClient={props.clientInfo.facture}
                    devisClient={props.clientInfo.devis}
                    infoIntervention={props.infoIntervention}
                    fileDevisClient={props.fileDevisClient}
                    openModalPhoto={props.openModalPhoto}
                    openPlanModal={props.openPlanModal}
                    openDocumentModal={props.openDocumentModal}
                    boolPhotos={props.boolPhotos}
                    filePlanLength={props.filePlanLength}
                    fileDocumentLength={props.fileDocumentLength}
                    user={props.user}
                />
            </Stack>
        </Grid>
    );
}
