// ----------------------------------------------------------------------

const breakpoints = {
    values: {
        xs: 0,
        sm: 600,
        md: 900,
        md2: 1100,
        lg: 1300,
        xl: 1600
    }
};

export default breakpoints;
