import React from 'react';
import { MobileDatePicker } from '@mui/x-date-pickers';
import { styled } from '@mui/material/styles';
import moment from 'moment';
import { TextField } from '@mui/material';

// ----------------------------------------------------------------------

const DatePicker = styled(MobileDatePicker)(() => ({
    '.MuiInputBase-input': {
        padding: "5px 10px !important",
    },
}));

// ----------------------------------------------------------------------

const TableMobileDatePicker = (props) => {
    const value = props.value ? moment(props.value) : null;

    return (
        <DatePicker
            {...props}
            format={props.inputFormat}
            value={value}
            closeOnSelect
            renderInput={(params) => (
                <TextField
                    {...params}
                    value={value}
                />
            )}
        />
    );
};

export default React.memo(TableMobileDatePicker);
