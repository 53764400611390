import React, { useState } from 'react';

const ChatContext = React.createContext({
    isOpenChat: false,
    isNewMessage: false,
    onOpenChat: () => {},
    onCloseChat: () => {},
    onNewMessage: () => {}
});

export const ChatContextProvider = (props) => {
    const [openChat, setOpenChat] = useState(false);
    const [newMessage, setNewMessage] = useState(false);

    const handlerOpenChat = () => {
        setOpenChat(true);
    };

    const handleCloseChat = () => {
        setOpenChat(false);
    };

    const handleNewMessage = () => {
        setNewMessage(!newMessage);
    };

    return (
        <ChatContext.Provider
            value={{
                onNewMessage: handleNewMessage,
                onOpenChat: handlerOpenChat,
                onCloseChat: handleCloseChat,
                isOpenChat: openChat,
                isNewMessage: newMessage,
            }}
        >
            {props.children}
        </ChatContext.Provider>
    );
};

export default ChatContext;
