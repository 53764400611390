import React from 'react';
import { TableHead, TableRow, TableCell, TableSortLabel, Box } from '@mui/material';
import { visuallyHidden } from '@mui/utils';
import { Module } from '../types';

interface HeadCell {
    id: keyof Module;
    label: string;
    width: string;
    type?: string;
}

interface ModuleDevisTableHeadProps {
    orderBy: keyof Module;
    order: 'asc' | 'desc';
    tableCells: HeadCell[];
    onRequestSort: (event: React.MouseEvent<unknown>, property: keyof Module) => void;
}

const ModuleDevisTableHead: React.FC<ModuleDevisTableHeadProps> = ({
    order,
    orderBy,
    tableCells,
    onRequestSort,
}) => {
    const createSortHandler = (property: keyof Module) => (event: React.MouseEvent<unknown>) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow>
                {tableCells.map((headCell) => (
                    <TableCell
                        key={headCell.id}
                        style={{
                            width: headCell.width,
                        }}
                        sortDirection={orderBy === headCell.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === headCell.id}
                            onClick={createSortHandler(headCell.id)}
                            direction={orderBy === headCell.id ? order : 'asc'}
                        >
                            {headCell.label}
                            {orderBy === headCell.id ? (
                                <Box component="span" sx={visuallyHidden}>
                                    {order === 'desc' ? 'sorted descending' : 'sorted ascending'}
                                </Box>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
};

export default ModuleDevisTableHead;
