import React from 'react'
import {
    IconButton,
} from '@mui/material';
import VisibilityIcon from '@mui/icons-material/Visibility';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';

const NavbarButtonView = () => {
    const [clientView, setClientView] = React.useState(JSON.parse(localStorage.getItem('clientView')));

    const handleClick = () => {
        setClientView(!clientView)
        localStorage.setItem('clientView', !clientView)
    }

    return (
        <IconButton onClick={handleClick}  {...(JSON.parse(localStorage.getItem('clientView')) && { color: "success" })}>
            {clientView ? <VisibilityOffIcon /> : <VisibilityIcon />}
        </IconButton>
    )
}

export default NavbarButtonView