import React from 'react';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import DeleteIcon from '@mui/icons-material/Delete';
import ImageIcon from '@mui/icons-material/Image';
import SendIcon from '@mui/icons-material/Send';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import Tooltip from '@mui/material/Tooltip';
import Zoom from '@mui/material/Zoom';
import { styled } from '@mui/material/styles';

const CustomTable = styled(Table)(() => ({
    minWidth: 650,

}))

const IconImage = styled(ImageIcon)(() => ({
    color: "#316690",
    cursor: "pointer",
    '&:hover': {
        color: "#316690",
    }
}))
const IconSend = styled(SendIcon)(() => ({
    color: "#349034ed",
    cursor: "pointer",
    '&:hover': {
        color: "#1b5e20",
    }
}))

const OutlinedImage = styled(InfoOutlinedIcon)(() =>({
    color: "#316690",
    cursor: "pointer",
    '&:hover': {
        color: "#316690",
    }
}))

const IconDelete = styled(DeleteIcon)(() => ({
    color: "#FF0000",
    cursor: "pointer",
    '&:hover': {
        color: "#DD0000",
    }
}))


export default function TableEmailIntervenant(props) {

    const [rows, setRows] = React.useState([])

    React.useEffect(() => {
        if (props.data) {
            const data = props.data
            setRows(data)
        }
    }, [props.data]);

    const b64toBlob = (b64Data, contentType = '', sliceSize = 512) => {
        const byteCharacters = atob(b64Data);
        const byteArrays = [];

        for (let offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            const slice = byteCharacters.slice(offset, offset + sliceSize);

            const byteNumbers = new Array(slice.length);
            for (let i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            const byteArray = new Uint8Array(byteNumbers);
            byteArrays.push(byteArray);
        }

        const blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }

    const showFile = (event, row) => {
        if (row.DemandeDevis) {
            let blob = b64toBlob(row.DemandeDevis, "application/pdf")
            let url = URL.createObjectURL(blob);
            window.open(url)
        } else if (row.FicheIntervention) {
            let blob = b64toBlob(row.FicheIntervention, "application/pdf")
            let url = URL.createObjectURL(blob);
            window.open(url)
        }
    }

    return (
        <div style={{ width: "100%" }}>
            <TableContainer component={Paper} style={{ maxHeight: "330px", minHeight: "70px" }}>
                <CustomTable size="small" aria-label="a dense table">
                    <TableHead style={{ background: "#316690" }}>
                        <TableRow>
                            <TableCell align="left">Nom du fichier</TableCell>
                            <TableCell style={{ width: "65px", padding: "5px 5px" }} align="center">{props.nameFile}</TableCell>
                            {props.modalSendMail ? <TableCell style={{ width: "75px", padding: "5px 5px" }} align="center">Envoyer</TableCell> : null}
                            <TableCell style={{ width: "60px", padding: "5px 5px" }} align="center">Infos</TableCell>
                            {props.removeFile ? <TableCell align="right" style={{ width: "30px", padding: "5px 5px" }}></TableCell> : null}
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {rows.map((row) => {
                            let SendTo = "Aucun envoi enregistré."
                            if (row.SendTo) {
                                SendTo = row.SendTo.map((item, i) => {
                                    if (row.SendTo[i + 1])
                                        return (<div key={item + i} style={{ marginBottom: "10px" }}>{item}</div>)
                                    else
                                        return (item)
                                })
                            }
                            return <TableRow key={row.Name}>
                                <Tooltip title={row.Name} arrow>
                                    <TableCell style={{ textOverflow: "ellipsis", whiteSpace: "nowrap", overflow: "hidden", maxWidth: '300px' }}>{row.Name}</TableCell>
                                </Tooltip>
                                <TableCell style={{ padding: "5px 5px" }} align="center"><IconImage onClick={(event) => showFile(event, row)}/></TableCell>
                                {props.modalSendMail ? <TableCell style={{ padding: "5px 5px" }} align="center" title="Envoye mail"><IconSend onClick={(event) => props.modalSendMail(event, row)} /></TableCell> : null}
                                <TableCell style={{ padding: "5px 5px" }} align="center"><Tooltip TransitionComponent={Zoom} title={SendTo}><OutlinedImage /></Tooltip></TableCell>
                                {props.removeFile ? <TableCell style={{ padding: "5px 5px" }} align="right" title="Supprimer"><IconDelete onClick={(event) => props.removeFile(event, row)}  /></TableCell> : null}
                            </TableRow>
                        }
                        )}
                    </TableBody>
                </CustomTable>
            </TableContainer>
        </div>
    );
}