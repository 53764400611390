import { styled } from '@mui/material/styles';
import CheckBoxIcon from '@mui/icons-material/CheckBox';

export const ImagePreview = styled('div')(() => ({
    width: 'calc((100% / 3) - 15px)',
    height: "auto",
    marginRight: "15px",
    marginBottom: "15px",
    border: "2px solid #545454",
    borderRadius: '3px',
    background: "black",
    display: "flex",
    position: "relative",
    cursor: "pointer",
    '&:hover': {
        border: "3px solid #316690",
    }
}))

export const Photo = styled('img')(() => ({
    height: "auto",
    maxWidth: "100%",
    maxHeight: "100%",
    margin: "auto",
}))

export const CustomIconCheckBox = styled(CheckBoxIcon)(() => ({
    position: "absolute",
    color: "#316690",
    bottom: "-5px",
    right: "-5px",
    padding: "0px"
}))