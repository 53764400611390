import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import Badge from '@mui/material/Badge';
import { CorbeilleList } from './CorbeilleList';
import { Divider } from '@mui/material';

export default function Corbeille({
    filteredTable,
    rowsPerPage,
    page,
    restoreDeletedDemandeIntervention,
    corbeille,
    handleStatus,
    setLoading,
    DeleteTaskHandle
}) {
    const [open, setOpen] = React.useState(false);

    const handleClickOpen = () => {
        setOpen(true);
    };

    const handleClose = () => {
        setOpen(false);
    };

    return (
        <div>
            <Badge badgeContent={corbeille.length} color="primary">
                <Button variant="outlined" size='small' onClick={handleClickOpen}>
                    Corbeille
                </Button>
            </Badge>
            <Dialog
                open={open}
                onClose={handleClose}
                maxWidth="false"
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <div style={{ width: 750, height: "auto" }}>
                    <DialogTitle id="alert-dialog-title">
                        {"Corbeille"}
                    </DialogTitle>
                    <Divider />
                    <DialogContent>
                        <CorbeilleList
                            corbeille={corbeille}
                            handleStatus={handleStatus}
                            filteredTable={filteredTable}
                            rowsPerPage={rowsPerPage}
                            setLoading={setLoading}
                            page={page}
                            DeleteTaskHandle={DeleteTaskHandle}
                            restoreDeletedDemandeIntervention={restoreDeletedDemandeIntervention}
                        />
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={handleClose}>Fermer</Button>
                    </DialogActions>
                </div>
            </Dialog>
        </div>
    );
}
