const templateEmail = (props, state) => {
    const { attribut, infoIntervention } = props;
    const { enseigne, numero_magasin, ville, objet, id_client, id_mastore, specificite_technique, specialite } = infoIntervention;

    let header = "Bonjour,\n\n";
    let footer = "\n\nCordialement,\n\nLe Service Maintenance\nmaintenance@mastore.fr\n04 78 41 61 49";
    let body = "";

    switch (attribut) {
        case "Devis Client":
            body = `Vous trouverez ci-joint le ${state.objectMail} concernant la demande :
Enseigne : ${enseigne} N°${numero_magasin}
Ville : ${ville}
Objet : ${objet}
DI Client : ${id_client} , DI Mastore : ${id_mastore}

Notre Service Maintenance reste à votre disposition en cas de problèmes.

Merci pour votre confiance.`;
            break;

        case "Fiche Maintenance":
            body = `Vous trouverez ci-joint les informations concernant votre prochaine maintenance :
Enseigne : ${enseigne} N°${numero_magasin}
Ville : ${ville}
Objet : ${objet}
Spécificité Technique : ${specificite_technique}

Notre Service reste à votre disposition en cas de problèmes ou pour toutes autres questions.`;
            break;

        case "Rapport":
        case "Pré Rapport":
            body = `Vous trouverez ci-joint le ${attribut} concernant votre demande d'intervention :
Enseigne : ${enseigne} N°${numero_magasin}
Ville : ${ville}
Objet : ${objet}
DI Client : ${id_client}

L'intervention s'est déroulée dans les meilleures conditions.
${attribut === "Pré Rapport" ? "Vous le recevrez dans les plus brefs délais le rapport de fin d'intervention.\n" : ""}
Le Service Maintenance Mastore vous remercie et reste à votre disposition en cas de problème.`;
            break;

        case "Rapport préventive":
            body = `Veuillez trouver ci-joint le rapport de la maintenance ${specialite} du mois de ......

Notre service reste à votre disposition pour toutes autres questions.`;
            break;

        default:
            body = "Nous restons à votre disposition pour toute information complémentaire.";
            break;
    }

    return `${header}${body}${footer}`;
};

export default templateEmail;
