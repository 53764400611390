import React, { useState } from 'react';

// components
import DialogFinish from 'views/Intervention/DialogFinish';

// types
import { ConfirmDialogProps } from '../views/EvictumManagement/types';

// mui
import { Dialog, DialogActions, DialogContent, Button, Slide, SlideProps, DialogContentText } from "@mui/material";

const Transition = React.forwardRef<unknown, SlideProps>(function Transition(props, ref) {
  return <Slide direction="up" ref={ref} {...props} />;
});

interface ConfirmDialogPropsExtended extends ConfirmDialogProps {
  clientInfo?: any;
  infoIntervention?: any;
  saveIntervention?: any;
  disableButton?: boolean;
  isAllDevisValid?: boolean;
  areIconsRed?: () => boolean;
  isAllActionsClosed?: boolean;
  updateInfoIntervention?: (date: string, field: string) => void;
}

const ConfirmDialog: React.FC<ConfirmDialogPropsExtended> = ({
  open,
  onClose,
  onConfirm,
  clientInfo,
  dialogContent,
  disableButton,
  isAllDevisValid,
  saveIntervention,
  infoIntervention,
  isAllActionsClosed,
  updateInfoIntervention,
}) => {
  const [statusUpdated, setStatusUpdated] = useState(false);

  const handleConfirm = () => {
    if (updateInfoIntervention && infoIntervention) {
      updateInfoIntervention('TERMINEE', 'status');
      setStatusUpdated(true);
    } else {
      onConfirm();
    }
  };

  React.useEffect(() => {
    if (statusUpdated) {
      saveIntervention();
      onConfirm();
    }
  }, [statusUpdated]);

  const confirmButtonText = updateInfoIntervention ? "Terminer l'intervention" : "Confirmer";

  return (
    <Dialog sx={{ '& .MuiDialog-paper': { width: "35%" } }} open={open} onClose={onClose} TransitionComponent={Transition}>
      <DialogContent >
        {infoIntervention && updateInfoIntervention && isAllActionsClosed !== undefined && isAllDevisValid !== undefined && clientInfo !== undefined
          ? (
            <DialogFinish
              clientInfo={clientInfo}
              isAllDevisValid={isAllDevisValid}
              infoIntervention={infoIntervention}
              isAllActionsClosed={isAllActionsClosed}
            />
          ) : (
            <DialogContentText>{dialogContent}</DialogContentText>
          )}
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} variant="outlined" color='error' size='small'>Annuler</Button>
        <Button onClick={handleConfirm} variant="outlined" color='primary' size='small' disabled={disableButton}>{confirmButtonText}</Button>
      </DialogActions>
    </Dialog>
  );
};

export default ConfirmDialog;
