import PropTypes from 'prop-types';
// @mui
import { styled } from '@mui/material/styles';
import {
    Box,
    Typography,
    IconButton,
    Stack,
    Popover,
    ListItem,
    ListItemButton,
    List,
    ListItemIcon,
    ListItemText,
    Dialog,
} from '@mui/material';
import React from 'react';
// icone
import LogoutIcon from '@mui/icons-material/Logout';
import MoreVertIcon from '@mui/icons-material/MoreVert';
import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import PasswordForm from 'components/UI/UserForm/PasswordForm';

// ----------------------------------------------------------------------

const RootStyle = styled('div')(({ theme }) => ({
    display: 'flex',
    alignItems: 'center',
    padding: theme.spacing(1.5, 1),
    transition: theme.transitions.create('opacity', {
        duration: theme.transitions.duration.shorter,
    }),
    position: "sticky",
    width: '100%',
    bottom: '0',
    backgroundColor: "#f0f0f0",
    borderTop: '1px dashed rgba(145, 158, 171, 0.24)'
}));

// ----------------------------------------------------------------------

NavBarDisconected.propTypes = {
    isCollapse: PropTypes.bool,
};

export default function NavBarDisconected({ userInformation, isCollapse, onAccountInfortion }) {
    const [anchorEl, setAnchorEl] = React.useState(null);
    const [openDialog, setOpenDialog] = React.useState(false);

    const handleClickOpenDialog = () => {
        setOpenDialog(true);
    };

    const handleCloseDialog = () => {
        setOpenDialog(false);
    };

    const handleClick = (event) => {
        setAnchorEl(event.currentTarget);
    };

    const handleClose = () => {
        setAnchorEl(null);
    };

    const open = Boolean(anchorEl);

    return (
        <RootStyle sx={{
            mt: 'auto',
            ...(isCollapse && {
                opacity: 0,
            }),
        }}
        >
            <Box
                sx={{
                    ml: 2,
                    mr: 1,
                    width: "100%",
                    transition: (theme) =>
                        theme.transitions.create('width', {
                            duration: theme.transitions.duration.shorter,
                        }),
                    ...(isCollapse && {
                        width: 0,
                    }),
                }}
            >
                <Stack direction="row" justifyContent="space-between">
                    <Stack direction="column" >
                        <Typography variant="h6" >
                            {`${onAccountInfortion.firstname} ${onAccountInfortion.lastname}`}
                        </Typography>
                        <Typography variant="body2" noWrap sx={{ color: 'text.secondary' }}>
                            {onAccountInfortion.role}
                        </Typography>
                    </Stack>
                    <Stack justifyContent="flex-end" direction="row" alignItems="center">
                        <React.Fragment>
                            <IconButton variant="contained" onClick={handleClick}>
                                <MoreVertIcon />
                            </IconButton>
                            <Popover
                                open={open}
                                anchorEl={anchorEl}
                                onClose={handleClose}
                                anchorOrigin={{ vertical: 'center', horizontal: 'right' }}
                                transformOrigin={{ vertical: 'center', horizontal: 'left' }}
                                sx={{ width: 600, height: 500 }}
                            >
                                <List>
                                    <ListItem disablePadding>
                                        <ListItemButton disable color="primary" component="label" onClick={handleClickOpenDialog} >
                                            <ListItemIcon>
                                                <AccountCircleIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Mon compte" />
                                        </ListItemButton>
                                    </ListItem>
                                    <ListItem disablePadding>
                                        <ListItemButton color="primary" component="label" onClick={onAccountInfortion.onLogout}>
                                            <ListItemIcon>
                                                <LogoutIcon />
                                            </ListItemIcon>
                                            <ListItemText primary="Se déconnecter" />
                                        </ListItemButton>
                                    </ListItem>
                                </List>
                            </Popover>
                        </React.Fragment>
                    </Stack>
                </Stack>
            </Box>
            <Dialog
                maxWidth="false"
                open={openDialog}
                onClose={handleCloseDialog}
            >
                <PasswordForm userInformation={userInformation} handleClose={handleCloseDialog} />
            </Dialog >
        </RootStyle >
    );
}
