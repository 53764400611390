// ----------------------------------------------------------------------

export default function CssBaseline(theme) {
    return {
        MuiCssBaseline: {
            styleOverrides: {
                '@global': {
                    '*::-webkit-scrollbar': {
                        width: "8px",
                        height: "8px",
                    },
                    '*::-webkit-scrollbar-button': {
                        width: "0px",
                        height: "0px",
                    },
                    '*::-webkit-scrollbar-thumb': {
                        background: "#cbcbcb",
                        border: "0px solid #000000",
                        borderRadius: "3px",
                    },
                    '*::-webkit-scrollbar-thumb:hover': {
                        background: "#a1a1a1",
                    },
                    '*::-webkit-scrollbar-thumb:active': {
                        background: "#a1a1a1",
                    },
                    '*::-webkit-scrollbar-track': {
                        border: "0px solid #ffffff",
                        borderRadius: "0px",
                    },
                },
            },
        },
    };
}
