import PropTypes from "prop-types";
// material
import { styled } from "@mui/material/styles";
import { STATUTS_COLOR } from "config";

const RootStyle = styled("span")(({ theme, ownerState }) => {
  return {
    height: 22,
    minWidth: 22,
    width: "100%",
    whiteSpace: "nowrap",
    borderRadius: "3px",
    cursor: "default",
    alignItems: "center",
    display: "inline-flex",
    justifyContent: "center",
    padding: theme.spacing(0, 2),
    color: "white",
    textTransform: "uppercase",
    fontWeight: "bold",
    backgroundColor: STATUTS_COLOR[ownerState],
  };
});

// ----------------------------------------------------------------------

export default function Status({ ownerState, ...other }) {
  return (
    <RootStyle ownerState={ownerState} {...other}>
      {ownerState}
    </RootStyle>
  );
}

Status.propTypes = {
  children: PropTypes.node,
  color: PropTypes.oneOf([
    //Intervention
    "A SUIVRE",
    //Action
    "A COMPLETER",
    "A PLANIFIER",
    "ATTENTE INTERVENANT",
    "ATTENTE CLIENT",
    "A EXPERTISER",
    "ATTENTE VALIDATION CLIENT",
    "A CHIFFRER",
    "A SUIVRE",
    "A CLORE",
    "CLOTUREE",
    "ARCHIVEE",
    //Facture
    "A PAYER",
    "URGENT",
    "A VALIDER",
    "PAYEE",
    //Demande
    "Demande de devis",
    "Demande intervention",
    "Attente de validation ",
    //Fiche Commande
    "À ENVOYER",
    "ENVOYÉE",
    "COMMANDES LIVRÉES",
  ]),
};
